<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->

    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageVehicle'"
      :ActiveMenu="'Taxi Operator Vehicles'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        :pageheaderTitle="`Taxi Profile - `+ VEHICLEPROFILE.license_number" 
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Taxi Driver"
        breadcrumbitem3="Taxi Vehicle Profile"
      />
      <!-- end page-header -->
      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">License Plate</div>
              <div class="stats-number">{{VEHICLEPROFILE.license_number || 'N/A'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">STATUS</div>
              <div class="stats-number">{{VEHICLEPROFILE.vehicle_status || 'Pending'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">OUTSTANDING BALANCE</div>

              <div
                class="stats-number"
              >{{VEHICLEPROFILE.available_balance | newcurrencywithSymbol }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">ENATIS STATUS</div>
              <div class="stats-number">{{VEHICLEPROFILE.enatis_status || 'N/A'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
      </div>

      <!-- begin #Main Body Template -->

      <div v-if="diskExpired" class="alert alert-danger fade show">Licence Disk Expired</div>

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <div class="panel panel-inverse">
            <!-- begin fieldset -->
            <fieldset>
              <p class="text-center h4 mb-4"></p>
              <div class="row">
                <!-- begin col-6 -->

                <div class="col-lg-6">
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Taxi Owner</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.operator_name"
                      />
                    </div>
                  </div>

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Account Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.accountNumber"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Vin Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.vin_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Tara</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.tara"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Vehicle Registration Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.vehicle_registration_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Licence Disc Expiry Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="VEHICLEPROFILE.license_expiry_date | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">License Plate Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.license_number"
                      />
                    </div>
                  </div>

                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Operating License Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="VEHICLEPROFILE.operatinglicense_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-4 col-form-label text-md-right"
                    >Operating License Issue Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="VEHICLEPROFILE.operatinglicense_issuedate | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-4 col-form-label text-md-right"
                    >Operating License Expiry Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="VEHICLEPROFILE.operatinglicense_expirydate | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Cross Border</label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                        <input
                          type="checkbox"
                          disabled
                          name="switcher_checkbox_hasoverdraft"
                          id="switcher_checkbox_hasoverdraft"
                          data-parsley-group="step-1"
                          data-parsley-required="false"
                          v-model="VEHICLEPROFILE.cross_border"
                        />
                        <label for="switcher_checkbox_hasoverdraft"></label>
                      </div>
                    </div>
                  </div>

                <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Left Rank WithOut Scan</label>
                    <div class="col-md-6">
                      <div class="switcher switcher-success">
                        <input
                          type="checkbox"
                          name="switcher_checkbox_vehicleleftrank_notification"
                          id="switcher_checkbox_vehicleleftrank_notification"
                          data-parsley-group="step-1"
                          data-parsley-required="false"
                          :checked="VEHICLEPROFILE.vehicleleftrank_notification"
                          @change="vehicleleftrank_notificationchecked(context)"
                          v-model="VEHICLEPROFILE.vehicleleftrank_notification"
                        />
                        <label for="switcher_checkbox_vehicleleftrank_notification"></label>
                      </div>
                    </div>
                  </div>
                  
                </div>

                <!-- Begin Second Row -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Engine Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.engine_number"
                      />
                    </div>
                  </div>

                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Engine Type</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.engine_type"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Transmision Type</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.transmission_type"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Color</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.color"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Make/Model</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="VEHICLEPROFILE.make_model"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Vehicle Joined Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="VEHICLEPROFILE.vehicle_joined_date | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Routes</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.routes"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Vehicle Status</label>
                    <div class="col-md-6">
                      <a
                        href="javascript:;"
                        id="vehicleStatus"
                        data-type="select"
                        data-pk="1"
                        data-value
                        data-title="Select vehicleStatus"
                      ></a>
                    </div>
                  </div>

                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Vehicle Finance Provider</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.vehicle_finance"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Insurance Provider</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.vehicle_insurance"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Tracker Provider</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="VEHICLEPROFILE.vehicle_tracker"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>
              </div>
            </fieldset>
            <!-- end fieldset -->
          </div>
        </div>
        <!-- end col-12 -->
      </div>
      <div class="btn-row">
        <div class="text-center">
          <button
                      type="button"
                      class="btn btn-sm btn-primary width-120 m-5"
                      @click="CollectViaDebitOrder(context, VEHICLEPROFILE.operator_vehicle_id)"
                    :disabled="$wait.is('collectviadebitorder-' + VEHICLEPROFILE.operator_vehicle_id)"
                    >
                                  <v-wait :for="'collectviadebitorder-' + VEHICLEPROFILE.operator_vehicle_id">
                                    <template slot="waiting">
                                      <i class="fas fa-spinner fa-spin"></i>
                                    </template>
                                    Collect Via Debit Order
                                  </v-wait>
                    
                    </button>
          <button
            type="button"
            class="btn btn-success width-200 m-5"
            v-show="VEHICLEPROFILE.vehicle_status === 'Active'"
            @click="VerifyOwnershipInformation(context, VEHICLEPROFILE.operator_vehicle_id)"
           :disabled="$wait.is('underBreakDown-' + VEHICLEPROFILE.operator_vehicle_id)"
          >
                        <v-wait :for="'underBreakDown-' + VEHICLEPROFILE.operator_vehicle_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Verify With eNatis 
                        </v-wait>
          
          </button>
          <router-link
            tag="button"
            class="btn btn-primary width-200 m-5"
            :to="{ name: 'taxioperatorprofile', params: {id: VEHICLEPROFILE.taxi_operator_id } }"
          >Owner Profile</router-link>
          <router-link
            tag="button"
            class="btn btn-primary width-100 m-5"
            v-show="!approvalid"
            :to="{ name: 'updatevehicle', params: {id: VEHICLEPROFILE.operator_vehicle_id } }"
          >Edit</router-link>
          <router-link
            tag="button"
            class="btn btn-info width-200 m-5"
            v-show="!approvalid"
            :to="{ name: 'changeofownership', params: {id: VEHICLEPROFILE.operator_vehicle_id } }"
          >Change Ownership</router-link>
          <router-link
            tag="button"
            class="btn btn-danger width-100 m-5"
            v-show="!approvalid"
            :to="{ name: 'vehiclephotos', params: {id: VEHICLEPROFILE.operator_vehicle_id } }"
          >Photos</router-link>

           <button
            type="button"
            class="btn btn-sm btn-yellow width-200 m-5"
            v-show="VEHICLEPROFILE.vehicle_status === 'Active'"
            @click="placeunderBreakDown(context, VEHICLEPROFILE.operator_vehicle_id)"
           :disabled="$wait.is('underBreakDown-' + VEHICLEPROFILE.operator_vehicle_id)"
          >
                        <v-wait :for="'underBreakDown-' + VEHICLEPROFILE.operator_vehicle_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Change To BreakDown
                        </v-wait>
          
          </button>
          
          <button
            type="button"
            class="btn btn-sm btn-success width-200 m-5"
            v-show="VEHICLEPROFILE.vehicle_status === 'Break Down'"
            @click="removeBreakDown(context, VEHICLEPROFILE.operator_vehicle_id)"
            :disabled="$wait.is('removeBreakDown-' + VEHICLEPROFILE.operator_vehicle_id)"
          >
                        <v-wait :for="'removeBreakDown-' + VEHICLEPROFILE.operator_vehicle_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Remove BreakDown
                        </v-wait>
          </button>
        </div>

          <button
            type="button"
            class="btn btn-sm btn-success width-200 m-5"
            @click="OpenapproveModal(context, VEHICLEPROFILE)"
            v-show="approvalid"
          >Approve</button>

          <button
            type="button"
            class="btn btn-sm btn-danger width-200 m-5"
            v-show="approvalid"
            @click="OpenrejectModal(context, VEHICLEPROFILE)"
          >Reject</button>
         
      </div>
      <br />

      <!-- begin panel -->
      <div class="panel panel-inverse panel-with-tabs" data-sortable-id="ui-unlimited-tabs-1">
        <!-- begin panel-heading -->
        <div class="panel-heading p-0">
          <div class="panel-heading-btn m-r-10 m-t-10">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              data-click="panel-expand"
            >
              <i class="fa fa-expand"></i>
            </a>
          </div>
          <!-- begin nav-tabs -->
          <div class="tab-overflow">
            <ul class="nav nav-tabs nav-tabs-inverse">
              <li class="nav-item prev-button">
                <a href="javascript:;" data-click="prev-tab" class="nav-link text-success">
                  <i class="fa fa-arrow-left"></i>
                </a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-1" data-toggle="tab" class="nav-link">Transactions</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-2" data-toggle="tab" class="nav-link">Timeline</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-3" data-toggle="tab" class="nav-link">Supporting Documents</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-4" data-toggle="tab" class="nav-link">Traffic Fines</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-5" data-toggle="tab" class="nav-link">Billings</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-6" data-toggle="tab" class="nav-link">Invoice Disputes</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-7" data-toggle="tab" class="nav-link">Receipt Disputes</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-8" data-toggle="tab" class="nav-link">Taxi Routes</a>
              </li>
              <li class="nav-item">
                <a href="#nav-tab-9" data-toggle="tab" class="nav-link">Trip History</a>
              </li>
               <li class="nav-item">
                <a href="#nav-tab-10" data-toggle="tab" class="nav-link">SMS</a>
              </li>
               <li class="nav-item">
                <a href="#nav-tab-11" data-toggle="tab" class="nav-link">Trip Tracker</a>
              </li>
              <li class="nav-item next-button">
                <a href="javascript:;" data-click="next-tab" class="nav-link text-success">
                  <i class="fa fa-arrow-right"></i>
                </a>
              </li>
            </ul>
          </div>
          <!-- end nav-tabs -->
        </div>
        <!-- end panel-heading -->
        <!-- begin tab-content -->
        <div class="tab-content">
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-1">
            <div class="panel-body">
              <table
                id="data-table-transactions"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th class="text-nowrap"></th>
                    <th class="text-nowrap">DATE</th>
                    <th class="text-nowrap">REFERENCE</th>
                    <th class="text-nowrap">CHARGE</th>
                    <th class="text-nowrap">PAYMENT</th>
                    <th class="text-nowrap">BALANCE</th>
                    <th class="text-nowrap">ACTION</th>
                  </tr>
                </thead>
                <tbody v-if="VEHICLETRANSACTIONS.length > 0">
                  <tr v-for="(item,index) in VEHICLETRANSACTIONS" :key="item._id" :value="item">
                    <td>{{++index}}</td>
                    <td>{{item._transdate }}</td>
                    <td v-if="item._receipts_id && !item._transaction_reference" >Payment Captured By {{item._captured_by_name }}</td>
                    <td v-if="item._receipts_id && item._transaction_reference" >{{item._transaction_reference}} By {{item._captured_by_name }}</td>
                    <td v-if="item._invoice_id" >{{item._transaction_reference }} <b v-if="item._invoice_disputed" class="text-red-darker">(DISPUTED)</b> </td>
                    <td
                      class="text-left text-green-darker"
                    >{{ item._invoice_id ? item._transaction_amount : '' | currency}}</td>
                    <td
                      class="text-left text-red-darker"
                    >{{ item._receipts_id ? item._transaction_amount : '' | currency}}</td>
                    <td class="text-left">R{{item._remaning_balance | currency}}</td>
                    <td>
                      <button
                        type="button"
                        v-if="item._invoice_id"
                        class="btn btn-sm btn-danger width-80"
                        :disabled="$wait.is('dispute-' + item._id)"
                        @click="selectedReceipt(context,item)"
                      >
                        <v-wait :for="'dispute-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Dispute
                        </v-wait>
                      </button>

                      <button
                        type="button"
                       v-if="item._receipts_id"
                        class="btn btn-sm btn-success width-80"
                        :disabled="$wait.is('dispute-' + item._id)"
                        @click="selectedReceipt(context,item)"
                      >
                        <v-wait :for="'dispute-' + item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Refund
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-2">
            <h1 v-if="VEHICLETIMELINE.length == 0">NO History in Timeline</h1>
            <ul class="timeline" v-if="VEHICLETIMELINE.length > 0">
              <li v-for="(item) in VEHICLETIMELINE" v-bind:key="item._id">
                <!-- begin timeline-time -->
                <div class="timeline-time">
                  <span class="date">{{item._date_added | formatToHumanize}}</span>
                  <span class="time">{{item._date_added | formatToTime}}</span>
                </div>
                <!-- end timeline-time -->
                <!-- begin timeline-icon -->
                <div class="timeline-icon">
                  <a href="javascript:;">&nbsp;</a>
                </div>
                <!-- end timeline-icon -->
                <!-- begin timeline-body -->
                <div class="timeline-body">
                  <div class="timeline-header">
                    <span class="fa-stack fa-lg text-primary">
                      <i class="fas fa-address-card fa-2x"></i>
                    </span>
                    <span class="username">
                      <a href="javascript:;">{{item._added_by_names}}</a>
                      <small></small>
                    </span>
                    <span class="pull-right text-muted"></span>
                  </div>
                  <div class="timeline-content">
                    <p>{{item._comment}}</p>
                  </div>
                </div>
                <!-- end timeline-body -->
              </li>
            </ul>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-3">
            <!-- Start panel-body -->
            <div class="panel-body">
              <table
                id="data-table-supportingDocs"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th class="text-nowrap">Filename</th>
                    <th class="text-nowrap">document Type</th>
                    <th class="text-nowrap">Active</th>
                    <th class="text-nowrap">Last Updated By</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody v-if="VEHICLESUPPORTINGDOCS.length > 0">
                  <tr v-for="item in VEHICLESUPPORTINGDOCS" v-bind:key="item.supportingdocs_id">
                    <td>{{item.filename}}</td>
                    <td>{{item.mimetype || 'N/A'}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item.deleted_by }"
                        v-if="item.deleted_by"
                      >Deleted</span>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                        v-if="!item.deleted_by"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>{{item.updated_by_name || item.added_by_name}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-white width-60 m-r-2"
                        data-toggle="modal"
                        data-target="#ViewDocsModal"
                        @click="setViewDocs(context,item)"
                        :disabled="item.deleted_by"
                      >View</button>
                      <router-link
                        tag="button"
                        class="btn btn-primary width-60 m-r-2"
                        :to="{ name: 'updatevehicle', params: {id: VEHICLEPROFILE.operator_vehicle_id } }"
                      >Edit</router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        v-if="item._deleted_by"
                      >Restore</button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        v-if="!item._deleted_by"
                      >Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-4">
            <div class="panel-body">
              <table
                id="data-table-trafficFines"
                class="table table-striped width-full table-bordered"
              >
                <thead>
                  <tr>
                    <th class="text-nowrap">Local Authority Name</th>
                    <th class="text-nowrap">Notice Number</th>
                    <th class="text-nowrap">Offence Date</th>
                    <th class="text-nowrap">Offence Description</th>
                    <th class="text-nowrap">Offence Location</th>
                    <th class="text-nowrap">Amount Due</th>
                    <th class="text-nowrap">Payable</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in TRAFFICFINES" :key="item.id">
                    <td>{{item.local_authority_name}}</td>
                    <td>{{item.notice_number}}</td>
                    <td>{{item.offence_date | formatToShortDate }}</td>
                    <td>{{item.offence_description }}</td>
                    <td>{{item.offence_location}}</td>
                    <td>{{item.amount_due | currencywithSymbol }}</td>
                    <td>{{item.payment_not_allowed_reason}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <!-- Example single danger button -->
                      <div class="btn-group m-r-5 m-b-5">
                        <a
                          data-toggle="modal"
                          data-target="#AddModal"
                          class="btn btn-warning width-80"
                          href="javascript:;"
                        >Pay</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-5">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Billing Date</th>
                    <th>Billing item</th>
                    <th>Amount</th>
                    <th>Collection Type</th>
                    <th>Last Billing Date</th>
                    <th>Next Billing Date</th>
                    <th>End Billing Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="VEHICLEBILLINGS.length > 0">
                  <tr v-for="(item) in VEHICLEBILLINGS" :key="item._id" :value="item">
                    <td>{{item._billing_start_date | formatToShortDate}}</td>
                    <td>{{item._fk_billing_items_name}}</td>
                    <td>R {{item._amount | currency}}</td>
                    <td>{{item._fk_collection_frequency_name}}</td>
                    <td>{{item._last_bill_date | formatToShortDate}}</td>
                    <td>{{item._next_billing_date | formatToShortDate}}</td>
                    <td>{{item._billing_end_date | formatToShortDate}}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger width-80"
                        @click="billingInfo(context,item)"
                      >Change</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-6">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>INVOICE NUMBER</th>
                    <th>ADDED BY</th>
                    <th>COMMENTS</th>
                    <th>DISPUTE STATUS</th>
                    <th>MEDIA</th>
                    <th>VOICENOTES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in VEHICLEINVOICEDISPUTES" v-bind:key="item._id">
                    <td>{{item._date_added | formatToShortDate}}</td>
                    <td>{{item._fk_invoice_number}}</td>
                    <td>{{item._invoicedisputes_feedback_comments}}</td>
                    <td>{{item._added_by_names}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-primary': item._fk_dispute_status_names == 'Active' }"
                        v-if="item._fk_dispute_status_names == 'Active'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-grey': item._fk_dispute_status_names == 'Pending' }"
                        v-if="item._fk_dispute_status_names == 'Pending'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._fk_dispute_status_names == 'Declined' }"
                        v-if="item._fk_dispute_status_names == 'Declined'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-success': item._fk_dispute_status_names == 'Resolved' }"
                        v-if="item._fk_dispute_status_names == 'Resolved'"
                      >{{item._fk_dispute_status_names}}</span>
                    </td>
                    <td v-if="item._invoicedisputes_feedback_media == null">
                      <span>
                        <i style="cursor: pointer;" class="fas fa-unlink fa-fw fa-2x"></i>
                      </span>
                    </td>
                    <td
                      v-for=" media in item._invoicedisputes_feedback_media"
                      v-bind:key="media.fileid"
                    >
                      <span
                        style="color: Dodgerblue; cursor: pointer;"
                        data-toggle="tooltip-invoice-media"
                        :title="media.filename"
                        @click="downloadInvoiceMedia(context, item._id, media.fileid)"
                      >
                        <i style="cursor: pointer;" class="fas fa-file-pdf fa-fw fa-2x"></i>
                      </span>
                    </td>

                    <td v-if="item._invoicedisputes_feedback_voicenotes == null">
                      <span>
                        <i style="cursor: pointer;" class="fas fa-microphone-slash fa-fw fa-2x"></i>
                      </span>
                    </td>
                    <td
                      v-for="(item) in item._invoicedisputes_feedback_voicenotes"
                      v-bind:key=" 'disputedinvoice-' +item.fileid"
                    >
                      <span
                        style="color: red; cursor: pointer;"
                        data-toggle="tooltip-invoice-voicenote"
                        :title="item.filename"
                      >
                        <i style="cursor: pointer;" class="fas fa-microphone fa-fw fa-2x"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end tab-pane -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-7">
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Date Actioned</th>
                    <th>Added By</th>
                    <th>Comments</th>
                    <th>Dispute Status</th>
                    <th>Media</th>
                    <th>Voicenotes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in VEHICLERECEIPTSDISPUTES" v-bind:key="item._id">
                    <td>{{item._actioned_date | formatToShortDate}}</td>
                    <td>{{item._added_by_name}}</td>
                    <td>{{item._receiptdisputes_feedback_comments}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-primary': item._fk_dispute_status_names == 'Active' }"
                        v-if="item._fk_dispute_status_names == 'Active'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-grey': item._fk_dispute_status_names == 'Pending' }"
                        v-if="item._fk_dispute_status_names == 'Pending'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-danger': item._fk_dispute_status_names == 'Declined' }"
                        v-if="item._fk_dispute_status_names == 'Declined'"
                      >{{item._fk_dispute_status_names}}</span>
                      <span
                        class="badge f-s-12"
                        :class="{ 'badge-success': item._fk_dispute_status_names == 'Resolved' }"
                        v-if="item._fk_dispute_status_names == 'Resolved'"
                      >{{item._fk_dispute_status_names}}</span>
                    </td>
                    <td v-if="item._receiptdisputes_feedback_media == null">
                      <span>
                        <i style="cursor: pointer;" class="fas fa-unlink fa-fw fa-2x"></i>
                      </span>
                    </td>
                    <td
                      v-for=" media in item._receiptdisputes_feedback_media"
                      v-bind:key="media.fileid"
                    >
                      <span
                        style="color: Dodgerblue; cursor: pointer;"
                        data-toggle="tooltip-invoice-media"
                        :title="media.filename"
                        @click="downloadInvoiceMedia(context, item._id, media.fileid)"
                      >
                        <i style="cursor: pointer;" class="fas fa-file-pdf fa-fw fa-2x"></i>
                      </span>
                    </td>

                    <td v-if="item._receiptdisputes_feedback_voicenote == null">
                      <span>
                        <i style="cursor: pointer;" class="fas fa-microphone-slash fa-fw fa-2x"></i>
                      </span>
                    </td>
                    <td
                      v-for="(item) in item._receiptdisputes_feedback_voicenote"
                      v-bind:key=" 'disputedinvoice-' +item.fileid"
                    >
                      <span
                        style="color: red; cursor: pointer;"
                        data-toggle="tooltip-invoice-voicenote"
                        :title="item.filename"
                      >
                        <i style="cursor: pointer;" class="fas fa-microphone fa-fw fa-2x"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end panel-body -->
          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-8">
            <div class="panel-body">
              <table
                id="data-table-taxiRoutes"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>National Route Code</th>
                    <th>Board Route Code</th>
                    <th>Taxi Fare</th>
                    <th>Origin</th>
                    <th>Destination</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in VEHICLETAXIROUTES" v-bind:key="item._id">
                    <td>{{item.taxi_association_routes_name}}</td>
                    <td>{{item.taxi_association_routes_description}}</td>
                    <td>{{item.taxi_association_routes_national_route_code}}</td>
                    <td>{{item.taxi_association_routes_board_route_code}}</td>
                    <td>{{item.taxi_association_routes_taxifare | currencywithSymbol}}</td>
                    <td>{{item.taxi_association_routes_origin}}</td>
                    <td>{{item.taxi_association_routes_destination}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end panel-body -->

          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-9">
            <div class="panel-body">
              <table
                id="data-table-tripHistory"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th>Rank Name</th>
                    <th>Route Name</th>
                    <th>Number of Passengers</th>
                    <th>Taxi Fare</th>
                    <th>Total Fare</th>
                    <th>Recorded Time</th>
                    <th>Scanned By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in VEHICLETRIPHISTORY" v-bind:key="item.id">
                    <td>{{item.taxi_association_rank_name}}</td>
                    <td>{{item.taxi_association_route_name}}</td>
                    <td>{{item.numberofpassagers}}</td>
                    <td>{{item.taxifare | currencywithSymbol}}</td>
                    <td>{{item.totaltaxifare | currencywithSymbol}}</td>
                    <td>{{item.recordedtime | formatToLongDateTime}}</td>
                    <td>{{item.added_by_name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end panel-body -->

          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-10">
            <div class="panel-body">
              <table
                id="data-table-vehiclesmses"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th>Date</th>
                    <th>Mobile</th>
                    <th>Message</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in GETALLSMS" v-bind:key="item.id">
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.date_added | formatToShortDateTime}}</td>
                    <td>{{item.sms_number}}</td>
                    <td>{{item.message}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        :class="[item.status === 'Failed' ? 'badge-danger' :  item.status === 'Delivered' ? 'badge-success' : 'badge-info' ]"
                      >{{item.status}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end panel-body -->

           <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-tab-11">
            <div class="panel-body">
              <table
                id="data-table-vehicletriptracker"
                class="table table-striped table-bordered width-full"
              >
                <thead>
                  <tr>
                    <th>TripTrack Start</th>
                    <th>TripTrack End</th>
                    <th>Start Location</th>
                    <th>End Location</th>
                    <th>Rank In</th>
                    <th>Rank Out</th>
                    <th>Rank</th>
                    <th>Route</th>
                    <th>SMS Sent</th>
                    <th>Excluded</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in GETALLTRIPTRACKER" v-bind:key="item.id">
                    <td>{{item.trip_startdate | formatToLongDateTime}}</td>
                    <td>{{item.trip_completedate | formatToLongDateTime}}</td>
                    <td>{{item.tripstart_location}}</td>
                    <td>{{item.tripcomplete_location}}</td>
                    <td>{{item.rank_timein}}</td>
                     <td>{{item.rank_timeout}}</td>
                     <td>{{item.rankname}}</td>
                     <td>{{item.routename}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        :class="[item.status ? 'badge-success' : 'badge-danger' ]"
                      >{{item.sentsms}}</span>
                    </td>
                    <td>{{item.excluded}}</td>
                    <td class="with-btn valign-middle" nowrap>
                          <button
                            class="btn btn-sm btn-primary width-120 m-r-2"
                              @click="viewLocation({
                                latitude: parseFloat(item.lat),
                                longitude: parseFloat(item.lon)
                              })"
                            >View Location</button>
                            <button
                            class="btn btn-sm btn-success width-120 m-r-2"
                            :disabled="$wait.is('ExcludeRoute'-item.id)"
                              @click="ExcludeRoute(context,item)"
                            >Exclude</button>
                        </td>
                        
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end panel-body -->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end panel -->

      <div class="modal fade" id="approveModal" tabindex="-1" role="dialog">
        <div class="modal-dialog width-full" role="document">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="text-center">Approve</h4>
              </div>
              <div class="modal-body width-full">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Comment</label>
                    <div class="col-md-9">
                      <textarea
                        class="textarea form-control"
                        v-model="comment"
                        placeholder="Enter comment..."
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-success width-80"
                  @click="approve(context,GETSELECTED,USERID,comment)"
                  :disabled="$wait.is('executeapproval')"
                >
                  <v-wait v-bind:for="'executeapproval'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Approve
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Edit Modal HTML START-->

      <!-- end #content -->
      <div class="modal fade" id="rejectModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="text-center">Reject</h4>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin form-group row -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Comment</label>
                    <div class="col-md-9">
                      <textarea
                        class="textarea form-control"
                        v-model="comment"
                        placeholder="Enter comment..."
                        rows="12"
                      ></textarea>
                    </div>
                  </div>
                  <!-- end form-group row -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
                <button
                  type="button"
                  class="btn btn-sm btn-danger width-80"
                  @click="reject(context,GETSELECTED,USERID,comment)"
                  :disabled="$wait.is('executereject')"
                >
                  <v-wait v-bind:for="'executereject'">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Reject
                  </v-wait>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Add Modal HTML START-->
      <div id="AddModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <form>
              <div class="modal-header">
                <h4 class="modal-title">Pay Traffic fine</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <!-- begin form-group row -->

                  <!-- begin card -->
                  <div class="card text-center">
                    <div class="card-header">
                      <ul class="nav nav-tabs card-header-tabs">
                        <li class="nav-item" v-show="TAMS">
                          <a class="nav-link active" data-toggle="tab" href="#card-tab-1">TAMS</a>
                        </li>
                        <li class="nav-item" v-show="EXTERNAL">
                          <a class="nav-link" data-toggle="tab" href="#card-tab-2">External Payment</a>
                        </li>
                        <li class="nav-item" v-show="REDIRECT">
                          <a class="nav-link" data-toggle="tab" href="#card-tab-3">Redirect Payment</a>
                        </li>
                      </ul>
                    </div>
                    <div class="card-block">
                      <div class="tab-content p-0 m-0">
                        <div class="tab-pane fade active show" id="card-tab-1" v-show="TAMS">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              BANK NAME:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="FIRST NATIONAL BANK"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              ACCOUNT TYPE:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="BUSINESS ACCOUNT"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              ACCOUNT NUMBER:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="62840241848"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              BRANCH CODE:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="255355"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              BRANCH NAME:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="MY BRANCH"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              SWIFT CODE:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="FIRNZAJJ"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              DATE OPENED:
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="2020-01-23"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <div class="tab-pane fade" id="card-tab-2" v-show="EXTERNAL"></div>
                        <div class="tab-pane fade" id="card-tab-3" v-show="REDIRECT"></div>
                      </div>
                    </div>
                  </div>
                  <!-- end card -->
                </div>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Add Modal HTML START-->

      <!-- #modal-message start -->
      <div class="modal fade" id="disputesModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 v-if="isInvoice" class="modal-title">Dispute Invoice</h4>
              <h4 v-if="isReceipt" class="modal-title">Dispute Receipt</h4>
            </div>
            <div class="modal-body">
              <div v-if="isInvoice" class="form-group row">
                <label class="col-md-2 col-form-label">Comment:</label>
                <div class="col-md-10">
                  <textarea
                    placeholder="Type your comments"
                    class="form-control m-b-10"
                    id="clip-textarea"
                    v-model="INVOICEDISPUTEADD.invoiceComments"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div v-if="isReceipt" class="form-group row">
                <label class="col-md-2 col-form-label">Comment:</label>
                <div class="col-md-10">
                  <textarea
                    placeholder="Type your comments"
                    class="form-control m-b-10"
                    id="clipboard-textarea"
                    v-model="RECEIPTDISPUTEADD.receiptComments"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <br />
              <div class="height-450" v-if="isInvoice">
                <!-- begin nav-tabs -->
                <ul class="nav nav-tabs">
                  <li class="nav-items">
                    <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
                      <span class="d-sm-none">Tab 1</span>
                      <span class="d-sm-block d-none">Media</span>
                    </a>
                  </li>
                  <li class="nav-items">
                    <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                      <span class="d-sm-none">Tab 2</span>
                      <span class="d-sm-block d-none">Voicenotes</span>
                    </a>
                  </li>
                </ul>
                <!-- end nav-tabs -->
                <!-- begin tab-content -->
                <div class="tab-content">
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade active show" id="default-tab-1">
                    <h3 class="m-t-10">Upload Documents</h3>
                    <DropZoneControl
                      :SessionID="invoiceDropZoneSessionID"
                      :acceptedFiles="acceptedFiles"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    ></DropZoneControl>
                  </div>
                  <!-- end tab-pane -->
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade" id="default-tab-2">
                    <AudioVideoControl
                      :SessionID="invoiceVoiceNoteSessionID"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    />
                  </div>
                  <!-- end tab-pane -->
                </div>
                <!-- end tab-content -->
              </div>
              <div class="height-450" v-if="isReceipt">
                <!-- begin nav-pills -->
                <ul class="nav nav-pills">
                  <li class="nav-items">
                    <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                      <span class="d-sm-none">Pills 1</span>
                      <span class="d-sm-block d-none">Media</span>
                    </a>
                  </li>
                  <li class="nav-items">
                    <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                      <span class="d-sm-none">Pills 2</span>
                      <span class="d-sm-block d-none">Voicenotes</span>
                    </a>
                  </li>
                </ul>
                <!-- end nav-pills -->
                <!-- begin tab-content -->
                <div class="tab-content">
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade active show" id="nav-pills-tab-1">
                    <h3 class="m-t-10">Attach files</h3>
                    <DropZoneControl
                      :SessionID="receiptDropZoneSessionID"
                      :acceptedFiles="acceptedFiles"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    ></DropZoneControl>
                  </div>
                  <!-- end tab-pane -->
                  <!-- begin tab-pane -->
                  <div class="tab-pane fade" id="nav-pills-tab-2">
                    <AudioVideoControl
                      :SessionID="invoiceVoiceNoteSessionID"
                      :ClearData="true"
                      v-on:fileadded="fileadded"
                    />
                  </div>
                  <!-- end tab-pane -->
                </div>
                <!-- end tab-content -->
              </div>
            </div>
            <div class="text-center m-b-5">
              <a href="javascript:;" class="btn btn-danger m-5 btn-lg" data-dismiss="modal">Close</a>
              <a
                href="javascript:;"
                class="btn btn-success m-5 btn-lg"
                data-dismiss="modal"
                v-if="isReceipt"
                @click="disputeReceipt(context,RECEIPTDISPUTEADD)"
              >Refund</a>
              <a
                href="javascript:;"
                class="btn btn-primary m-5 btn-lg"
                data-dismiss="modal"
                v-if="isInvoice"
                @click="disputeInvoice(context, INVOICEDISPUTEADD)"
              >Dispute</a>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-message end -->

      <!-- #modal-dialog -->
      <div class="modal fade" id="changeBilling">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title">Change Vehicle billing</h4>
            </div>
            <div class="modal-body">
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 col-form-label text-md-right">Amount</label>
                <div class="col-md-6">
                  <input
                    type="Number"
                    id="amount"
                    class="form-control"
                    v-model="CHANGEBILLING.amount"
                    placeholder="R0.00"
                  />
                </div>
              </div>
              <!-- End form-group -->
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-nowrap col-form-label text-md-right">Billing End Date:</label>
                <div class="date text-inverse col-md-7 pull-center">
                  <div
                    class="input-group date"
                    id="datepicker-billingEnd"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Billing End Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end form group -->
              <!-- begin form-group -->
              <div class="form-group row m-b-10">
                <label class="col-md-3 text-nowrap col-form-label text-md-right">Next Billing Date:</label>
                <div class="date text-inverse col-md-7 pull-center">
                  <div
                    class="input-group date"
                    id="datepicker-nextBilling"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Next Billing  Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
              <!-- end form group -->
            </div>
            <div class="modal-footer">
              <div class="text-center">
                <button href="javascript:;" class="btn btn-white m-5" data-dismiss="modal">Close</button>
                <button
                  @click="changeVehicleBillingType(context,CHANGEBILLING)"
                  class="btn btn-primary m-5"
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-dialog end -->

        <!-- #modal-message start -->
      <div class="modal fade" id="ViewLocationModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <div class="height-450">
                    <div style="height: 100%;width: 100%;" id="mapidprofile"></div>
              </div>
            </div>
            <div class="text-center m-b-5">
              <a href="javascript:;" class="btn btn-danger m-5 btn-lg" data-dismiss="modal">Close</a>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-message end -->

    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapActions } = createNamespacedHelpers("OperatorVehicle");

export default {
  name: "TAXIVEHICLEPROFILE",
  data: function () {
    return {
      AccountNumber:'',
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      id: this.$route.params.id,
      comment: "",
      approvalid: this.$route.params.approvalid,
      VEHICLETIMELINE: [],
      VEHICLESUPPORTINGDOCS: [],
      TRAFFICFINES: [],
      VEHICLEBILLINGS: [],
      VEHICLEINVOICEDISPUTES: [],
      VEHICLERECEIPTSDISPUTES: [],
      VEHICLETAXIROUTES: [],
      GETADDVEHICLE: [],
      VEHICLEPROFILE: {
        taxi_operator_id:0,
        vehicleleftrank_notification:false,
        accountNumber: "",
        vehicle_status: "",
        available_balance: 0,
        operator_name: "",
        vin_number: "",
        tara: "",
        vehicle_registration_number: "",
        license_expiry_date: "",
        license_number: "",
        operatinglicense_issuedate: "",
        operatinglicense_expirydate: "",
        operatinglicense_number: "",
        cross_border: "",
        engine_number: "",
        engine_type: "",
        transmission_type: "",
        color: "",
        make_model: "",
        vehicle_joined_date: "",
        routes: "",
        vehicle_finance: "",
        vehicle_tracker: "",
        vehicle_insurance: "",
        operator_vehicle_id: 0,
      },
      VEHICLETRANSACTIONS: [],
      INVOICEDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        invoiceid: "",
        invoiceComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      RECEIPTDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        receiptid: "",
        receiptComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      invoiceDropZoneSessionID: this.$uuid.v4(),
      invoiceVoiceNoteSessionID: this.$uuid.v4(),
      receiptDropZoneSessionID: this.$uuid.v4(),
      receiptVoiceNoteSessionID: this.$uuid.v4(),
      //id: this.$route.query._taxi_operator_vehicles_id,
      GETSELECTED: this.$store.getters["OperatorVehicle/GETADDVEHICLE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      GETUPLOADEDFILES: [],
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isReceipt: false,
      isInvoice: false,
      acceptedFiles: "application/pdf",

      diskExpired: false,
      hasInvoiceVoiceNote: false,
      hasInvoiceMedia: true,
      DropZoneSessionID: this.$uuid.v4(),

      CHANGEBILLING: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        vehiclebillingid: 0,
        vehiclebillingtypeid: 0,
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        amount: null,
        billingstartdate: "",
        billingenddate: "",
        nextbillingdate: "",
        lastbilldate: "",
        collectionfrequencynumofdays: "",
        isactive: true,
      },
      // ADDSTATUSCHANGEREQUEST: {
      //   taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
      //     .id,
      //   taxi_vehicle_id: 0,
      //   taxi_operator_id: GETSELECTED._taxi_operator_id,
      //   taxi_vehicle_status_id: this.$route.query.id,
      //   updated_by: this.$store.getters["auth/USERPROFILE"].id
      // },
      skip: 0,
      len: 1000,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      VEHICLETRIPHISTORY: [],
      REDIRECT: true,
      EXTERNAL: true,
      TAMS: true,
      GETALLSMS: [],
      GETALLTRIPTRACKER: [],
      maploaded: false,
      mymap:null,
      marker:null,
      tileLayer: null,
      LOADED: true,
      // GETADDTAXIDRIVER: this.$store.getters["TaxiDrivers/GETADDTAXIDRIVER"],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl,
  },

  computed: {
    ...mapGetters(["GETVEHICLEROUTES"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getbyid",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
      "getVehicleTimelineById",
      "getAllTransactionsByAccNo",
      "getVehicleDocsById",
      "getallStatus",
      "addChangeRequest",
      "getVehicleBillingsById",
      "changeVehicleStatusRequest",
      "changeVehicleBilling",
      "executeapproval",
      "executereject",
      "getTaxiRoutesByTaxiId",
      "getVehiclePlaceUnderBreakdown",
      "getVehicleRemoveBreakdown",
      "getVehicleLeftRank",
      "excludetriptrack"
    ]),
    ExcludeRoute: function(context,item) {

      
      context.$wait.start('ExcludeRoute'-item.id)

      context.excludetriptrack({
                router: context.$router,
                addModel: {
                  taxi_association_id: context.taxiassociationid,
                  taxi_vehicle_id: context.id,
                  tripstartlocation: item.tripstart_location,
                  tripcompletelocation: item.tripcomplete_location,
                  regnumber: item.v_reg_number,
                  added_by: context.USERID,
                }
              })
              .then(() => {

                context.$Notify.Success("Successfull!", "");

                
               
              })
              .catch((error) => {
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                context.$wait.end('ExcludeRoute'-item.id)
              });

      
    },
    viewLocation: function(geolocation) {
      
      $("#ViewLocationModal").modal("show");

       
      
      if(!this.maploaded)
      {

        this.maploaded = true;

        

            setTimeout(function(context){

            context.mymap = L.map('mapidprofile').setView([geolocation.latitude,geolocation.longitude], 16);

              context.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
                maxZoom: 18,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                  'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
              }).addTo(context.mymap);

              context.tileLayer.on("load",function() { 
                context.marker = L.marker([geolocation.latitude, geolocation.longitude]).addTo(context.mymap);
                });


              }, 600,this);
        
      }
      else
      {

        this.marker.setLatLng(L.latLng(geolocation.latitude, geolocation.longitude))
        this.mymap.panTo(L.latLng(geolocation.latitude, geolocation.longitude));

      }


      
    },
    vehicleleftrank_notificationchecked: (context) => {
      context.getVehicleLeftRank({
                router: context.$router,
                vehicleid: context.VEHICLEPROFILE.operator_vehicle_id,
                vehicleleftrank: context.VEHICLEPROFILE.vehicleleftrank_notification,
              })
              .then(() => {

                context.$Notify.Success("Successfull!", "");
               
              })
              .catch((error) => {
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
              });
    },
    setViewDocs: (context, item) => {
      window.open(item.documents, '_blank');
    },
    reject: (context, item, rejected_by, comment) => {
      
      item._id = context.approvalid;
      context.$Notify
        .YesNo(false, {
          context,
          item,
          rejected_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: item._id,
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("executereject");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("approvals/executereject", {
                _dataObj: value,
              })
              .then(() => {
                context.comment = "";
                $("#rejectModal").modal("hide");
                value.context.$wait.end("executereject");
                value.context.$Notify.Success("Rejected!", "");
                value.context.approvalid = undefined;
              })
              .catch((error) => {
                value.context.$wait.end("executereject");
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.$wait.end("executereject");
              });
          }
        });
    },
    approve: (context, item, approved_by, comment) => {
      

      context.$Notify
        .YesNo(false, {
          context,
          item,
          approved_by,
          comment,
          taxiassociation_id: context.taxiassociationid,
          approval_id: context.approvalid,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("executeapproval");
            value.router = value.context.$router;
            value.item._id = value.context.approvalid;
            value.context.$store
              .dispatch("approvals/executeapproval", {
                _dataObj: value,
              })
              .then(() => {
                
                context.comment = "";
                value.context.$wait.end("executeapproval");
                value.context.$Notify.Success("Approved Successfully!", "");
                value.context.approvalid = undefined;
              })
              .catch((error) => {
                
                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {
                $("#approveModal").modal("hide");
                value.context.$wait.end("executeapproval");
              });
          }
        });
    },
    billingInfo: (context, item) => {
      console.log(item);
      $("#changeBilling").modal("show");
      context.CHANGEBILLING.vehiclebillingid = item._id;
      context.CHANGEBILLING.vehiclebillingtypeid = item._fk_billingtype_id;
      context.CHANGEBILLING.billingstartdate = item._billing_start_date;
      context.CHANGEBILLING.lastbilldate = item._last_bill_date;
      context.CHANGEBILLING.collectionfrequencynumofdays =
        item._collection_frequency_num_of_days;
    },
    OpenapproveModal: (context, item) => {
      context.GETSELECTED = item;
      $("#approveModal").modal();
    },
    OpenrejectModal: (context, item) => {
      context.GETSELECTED = item;
      $("#rejectModal").modal("show");
    },
    selectedReceipt: (context, payload) => {
      console.log("this is the selected receipt: ", payload);
      context.transid = payload._id;
      if (
        payload._receipts_id != null &&
        payload._receipts_id != undefined &&
        payload._receipts_id != ""
      ) {
        let receipt = payload._receipts_id;
        context.RECEIPTDISPUTEADD.receiptid = receipt;
        console.log("selectedreceipt ", context.RECEIPTDISPUTEADD.receiptid);
        context.isReceipt = true;
        context.isInvoice = false;
        $("#disputesModal").modal("show");
      } else if (
        payload._invoice_id != null &&
        payload._invoice_id != undefined &&
        payload._invoice_id != ""
      ) {
        let invoice = payload._invoice_id;
        context.INVOICEDISPUTEADD.invoiceid = invoice;
        console.log("selectedinvoice ", context.INVOICEDISPUTEADD.invoiceid);
        context.isInvoice = true;
        context.isReceipt = false;
        $("#disputesModal").modal("show");
      } else {
        alert("no receipt or invoice found");
      }
    },
    VerifyOwnershipInformation: (context, vehicleid) => {

      context.$Notify.Info("Verify Ownership Against eNatis Databse Is Coming Soon","").then((value) => {
        if (value) {
          value.context.$wait.start("underBreakDown-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehiclePlaceUnderBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            }).then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'Break Down'
              value.context.$wait.end("underBreakDown-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Breakdown!", "");
            })
            .catch((error) => {
              value.context.$wait.end("underBreakDown-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    CollectViaDebitOrder: (context, vehicleid) => {

    context.$Notify.Info("Collect Easy Via Debit Order","").then((value) => {
      if (value) {
        value.context.$wait.start("collectviadebitorder-" + value.vehicleid);
        value.router = value.context.$router;
        value.context.getVehiclePlaceUnderBreakdown({
            router: value.router,
            taxiassociationid:context.taxiassociationid,
            taxivehicleid:value.vehicleid,
            updatedby: context.USERID
          }).then(() => {
            value.context.VEHICLEPROFILE.vehicle_status = 'Break Down'
            value.context.$wait.end("collectviadebitorder-" + value.vehicleid);
            value.context.$Notify.Success("Taxi Status Is Breakdown!", "");
          })
          .catch((error) => {
            value.context.$wait.end("collectviadebitorder-" + value.vehicleid);

            value.context.$Notify.Error(error.data[0].message, "");
          })
          .finally(() => {});
      }
});

},
    placeunderBreakDown: (context, vehicleid) => {
      

      context.$Notify.YesNo(false, { context, vehicleid }).then((value) => {
        if (value) {
          value.context.$wait.start("underBreakDown-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehiclePlaceUnderBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            }).then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'Break Down'
              value.context.$wait.end("underBreakDown-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Breakdown!", "");
            })
            .catch((error) => {
              value.context.$wait.end("underBreakDown-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    removeBreakDown: (context, vehicleid) => {
      

      context.$Notify.YesNo(false, { context, vehicleid }).then((value) => {
        if (value) {
          value.context.$wait.start("removeBreakDown-" + value.vehicleid);
          value.router = value.context.$router;
          value.context.getVehicleRemoveBreakdown({
              router: value.router,
              taxiassociationid:context.taxiassociationid,
              taxivehicleid:value.vehicleid,
              updatedby: context.USERID
            })
            .then(() => {
              value.context.VEHICLEPROFILE.vehicle_status = 'Active'
              value.context.$wait.end("removeBreakDown-" + value.vehicleid);
              value.context.$Notify.Success("Taxi Status Is Active!", "");
            })
            .catch((error) => {
              value.context.$wait.end("removeBreakDown-" + value.vehicleid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });

    },
    disputeInvoice: (context, payload) => {
      
      context.INVOICEDISPUTEADD.dropzoneid = context.invoiceDropZoneSessionID;
      context.INVOICEDISPUTEADD.voicenotesessionid =
        context.invoiceVoiceNoteSessionID;

      context.INVOICEDISPUTEADD.invoiceComments = document.getElementById(
        "clip-textarea"
      ).value;

      context.$Notify.YesNo(false, { context, payload }).then((value) => {
        if (value) {
          value.context.$wait.start("dispute-" + context.transid);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("invoices/createInvoiceDisputeRequest", {
              router: value.router,
              _addModel: {
                taxi_association_id:
                  context.INVOICEDISPUTEADD.taxiassociationid,
                invoice_id: context.INVOICEDISPUTEADD.invoiceid,
                comments: context.INVOICEDISPUTEADD.invoiceComments,
                voicenotesessionid:
                  context.INVOICEDISPUTEADD.voicenotesessionid,
                dropzoneid: context.INVOICEDISPUTEADD.dropzoneid,
                addedby: context.INVOICEDISPUTEADD.addedby,
              },
            })
            .then(() => {
              value.context.INVOICEDISPUTEADD.invoiceComments = "";
              //context.invoiceDropZoneSessionID = "";
              value.context.$wait.end("dispute-" + context.transid);
              value.context.$Notify.Success("Dispute Sucessfully!", "");
              value.context.getTransactionsByAcc(value.context, value.context.AccountNumber);
            })
            .catch((error) => {
              value.context.$wait.end("dispute-" + context.transid);

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    disputeReceipt: (context, payload) => {
      context.RECEIPTDISPUTEADD.dropzoneid = context.receiptDropZoneSessionID;
      context.RECEIPTDISPUTEADD.voicenotesessionid =
        context.receiptVoiceNoteSessionID;

      context.RECEIPTDISPUTEADD.receiptComments = document.getElementById(
        "clipboard-textarea"
      ).value;

      context.$Notify.YesNo(false, { context, payload }).then((value) => {
        if (value) {
          
          value.context.$wait.start("dispute-" + context.transid);
          value.router = value.context.$router;
          value.context.$store
            .dispatch("invoices/createReceiptDisputeRequest", {
              router: value.router,
              _addModel: {
                taxi_association_id:
                  value.context.RECEIPTDISPUTEADD.taxiassociationid,
                receipt_id: value.context.RECEIPTDISPUTEADD.receiptid,
                comments: value.context.RECEIPTDISPUTEADD.receiptComments,
                voicenotesessionid:
                  value.context.RECEIPTDISPUTEADD.voicenotesessionid,
                dropzoneid: value.context.receiptDropZoneSessionID,
                addedby: value.context.RECEIPTDISPUTEADD.addedby,
              },
            })
            .then(() => {
              value.context.RECEIPTDISPUTEADD.receiptComments = "";
              value.context.RECEIPTDISPUTEADD.voicenotesessionid = "";
              value.context.$wait.end("dispute-" + context.transid);
              value.context.$Notify.Success("Refund Sucessfully!", "");
              value.context.getTransactionsByAcc(value.context, value.context.AccountNumber);
            })
            .catch((error) => {
              value.context.$wait.end("dispute-" + context.transid);

              value.context.$Notify.Error(error.message, "");
            })
            .finally(() => {});
        }
      });
    },
    changeVehicleBillingType: (context, _addModel) => {
      
      context.CHANGEBILLING.billingstartdate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.billingstartdate
      );

      context.CHANGEBILLING.lastbilldate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.lastbilldate
      );

      const billingEndDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-billingEnd").find("input").val()
      );

      const nextBillingDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-nextBilling").find("input").val()
      );

      context.CHANGEBILLING.billingenddate = billingEndDate;
      context.CHANGEBILLING.nextbillingdate = nextBillingDate;

      context.$Notify.YesNo(false, { context, _addModel }).then((value) => {
        
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("OperatorVehicle/changeVehicleBilling", {
              addModel: value._addModel,
              router: value.router,
            })
            .then((data) => {
              value.context.$Notify.Success("Billing change requested", data);
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    downloadReceiptMedia: (context, receiptsdisputesid, fileid) => {
      context.$store
        .dispatch("TaxiDrivers/downloadMedia", {
          receiptsdisputesid,
          fileid,
          router: context.$router,
        })
        .then((_rb) => {
          console.log(_rb[0].fn_receipts_disputes_get_attached[0].filename);
          context.downloadItem(
            _rb[0].fn_receipts_disputes_get_attached[0].filename,
            _rb[0].fn_receipts_disputes_get_attached[0].data,
            _rb[0].fn_receipts_disputes_get_attached[0].mimetype
          );
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },
    downloadInvoiceMedia: (context, invoicedisputesid, fileid) => {
      context.$store
        .dispatch("invoices/downloadMedia", {
          invoicedisputesid,
          fileid,
          router: context.$router,
        })
        .then((_rb) => {
          console.log(_rb[0].fn_invoice_disputes_get_attached[0].filename);
          context.downloadItem(
            _rb[0].fn_invoice_disputes_get_attached[0].filename,
            _rb[0].fn_invoice_disputes_get_attached[0].data,
            _rb[0].fn_invoice_disputes_get_attached[0].mimetype
          );
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },
    addChange: (context, item) => {
      item = Object.assign({}, item);

      const drivernum = $(".default-select").select2("data").val();

      alert(drivernum);

      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("OperatorVehicle/addChangeRequest", {
              _dataObj: value,
            })
            .then((_data) => {
              value.context.ADDEDVEHICLEID = _data.taxi_operator_vehicles_id;
              $("#wizard").data("taxiadded", true);
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    // show: (context, increment) => {
    //   if (increment) context.skip += 0;
    //   else {
    //     if (context.skip > 0) context.skip -= 30;
    //   }

    //   context
    //     .getVehicleTransactions({
    //       status: context.id,
    //       skip: context.skip,
    //       len: context.len,
    //       router: context.$router
    //     })
    //     .then(data => {
    //       console.log("These are the transactions: ", data);
    //     });
    // },

    getbyid: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getbyid", {
          _dataObj: {
            id,
          },
        })
        .then((data) => {
          
          console.log("this is the vehicle: ", data);

          context.VEHICLEPROFILE.taxi_operator_id = data[0]._taxi_operator_id;
          context.VEHICLEPROFILE.vehicle_status = data[0]._vehiclestatus_name;
          context.VEHICLEPROFILE.accountNumber = data[0]._account_number;
          context.VEHICLEPROFILE.available_balance = data[0]._available_balance;
          context.VEHICLEPROFILE.operator_name = data[0]._taxi_operator_name;
          context.VEHICLEPROFILE.vin_number = data[0]._vin_number;
          context.VEHICLEPROFILE.tara = data[0]._tara;
          context.VEHICLEPROFILE.vehicle_registration_number =
            data[0]._vehicle_register_number;
          context.VEHICLEPROFILE.license_expiry_date =
            data[0]._licence_expiry_date;
          context.VEHICLEPROFILE.license_number = data[0]._licence_number;
          context.VEHICLEPROFILE.operatinglicense_issuedate =
            data[0]._operatinglicense_issuedate;
          context.VEHICLEPROFILE.operatinglicense_expirydate =
            data[0]._operatinglicense_expirydate;
          context.VEHICLEPROFILE.operatinglicense_number =
            data[0]._operatinglicense_number;
          context.VEHICLEPROFILE.cross_border = data[0]._cross_border;
          context.VEHICLEPROFILE.vehicleleftrank_notification = data[0]._vehicleleftrank_notification;
          context.VEHICLEPROFILE.engine_number = data[0]._engine_number;
          context.VEHICLEPROFILE.engine_type = data[0]._vehicle_engine;
          context.VEHICLEPROFILE.transmission_type =
            data[0]._vehicle_transmission_type;
          context.VEHICLEPROFILE.color = data[0]._vehicle_color;
          context.VEHICLEPROFILE.make_model =
            data[0]._vehicle_make + " " + data[0]._vehicle_model;
          context.VEHICLEPROFILE.vehicle_joined_date =
            data[0]._taxi_operator_vehicle_joined_date;
          context.VEHICLEPROFILE.routes = data[0]._taxi_route;
          context.VEHICLEPROFILE.vehicle_finance =
            data[0]._vehicle_finance_institution_name;
          context.VEHICLEPROFILE.vehicle_tracker =
            data[0]._vehicle_tracker_institution_name;
          context.VEHICLEPROFILE.vehicle_insurance =
            data[0]._vehicle_insurance_institution_name;
          context.VEHICLEPROFILE.operator_vehicle_id =
            data[0]._taxi_operator_vehicles_id;
            context.VEHICLEPROFILE.enatis_status =
            data[0]._enatis_status;

            if(data[0]._account_number != null){
              context.getTransactionsByAcc(context, data[0]._account_number);
              context.AccountNumber = data[0]._account_number;
            }
        })
        .catch((err) => {
          console.log("vehicle details error: ", err);
        });
    },

    getTransactionsByAcc: (context, accountNumber) => {
      
      context.$store
        .dispatch("OperatorVehicle/getAllTransactionsByAccNo", {
          associationsid: context.taxiassociationid,
          accountnumber: accountNumber,
          skip: context.skip,
          length: context.len,
        })
        .then((data) => {
          context.VEHICLETRANSACTIONS = data;
          console.log("these are the vehicle transactions: ", data);
        })
        .catch((err) => {
          
          console.log("vehicle transactions error: ", err);
        })
        .finally(() => {
            if (context.LOADED) {
              context.LOADED = false;
              $("#data-table-transactions").DataTable({
              responsive: false,
              order: [[0, 'asc']]
              });
            }
        });
    },

    vehicleTimeline: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleTimelineById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.VEHICLETIMELINE = data;
          console.log("This is the vehicle timeline: ", data);
        })
        .catch((err) => {
          console.log("vehicle timeline error: ", err);
        });
      //this.$emit('init')
    },

    vehicleDocs: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleDocsById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("These are the supporting docs..", data);
          context.VEHICLESUPPORTINGDOCS = data;
        })
        .catch((err) => {
          console.log("vehicle docs error: ", err);
        })
        .finally(() => {
          $("#data-table-supportingDocs").DataTable({
            responsive: true,
          });
        });
    },

    vehicleFinesById: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getVehicleFinesById", {
          router: context.$router,
          id,
          skip: context.skip,
          len: context.len,
        })
        .then((data) => {
          console.log("operator Vehicle fines: ", data);

          context.TRAFFICFINES = data;
        })
        .catch((err) => {
          console.log("operator Vehicle fines errors -> ", err);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
          $("#data-table-trafficFines").DataTable(options);
        });
      //this.$emit('init')
    },

    getVehilcleBillings: (context, id) => {
      
      context
        .getVehicleBillingsById({
          status: context.id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          context.VEHICLEBILLINGS = data;
          console.log("These are the billings: ", data);
        });
    },

    getInvoiceDisputes: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleInvoiceDisputesByid", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.VEHICLEINVOICEDISPUTES = data;
          console.log("These are the InvoiceDisputes.. ", data);
        })
        .catch((err) => {
          console.log("vehicle InvoiceDisputes error: ", err);
        });
      //this.$emit('init')
    },

    getReceiptDisputes: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getVehicleReceiptDisputesByid", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.VEHICLERECEIPTSDISPUTES = data;
          console.log("These are the ReceiptDisputes.. ", data);
        })
        .catch((err) => {
          console.log("vehicle ReceiptDisputes error: ", err);
        });
      //this.$emit('init')
    },
    getTaxiRoutesByTaxiId: (context, id) => {
      context.$store
        .dispatch("OperatorVehicle/getTaxiRoutesByTaxiId", {
          id,
          router: context.$router,
        })
        .then((data) => {
          context.VEHICLETAXIROUTES = data;
          console.log("These are the Routes.. ", data);
           
        })
        .catch((err) => {
          console.log("vehicle routes errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
         $("#data-table-taxiRoutes").DataTable(options);
        });
      //this.$emit('init')
    },
    getTripTracker: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getTripTracker", {
          router: context.$router,
          vehicleid: id,
          taxiassociationid: context.taxiassociationid,
        })
        .then((returnblock) => {
          context.GETALLTRIPTRACKER = returnblock.data[0].data;
           
        })
        .catch((err) => {
          console.log("trip History errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
         $("#data-table-vehicletriptracker").DataTable(options);
        });
    },
   getSMSes: (context, id) => {
      
      context.$store
        .dispatch("sms/getSMSes", {
          router: context.$router,
          vehicleid: id,
          taxiassociationid: context.taxiassociationid,
        })
        .then((returnblock) => {
          context.GETALLSMS = returnblock.data[0].details;
           
        })
        .catch((err) => {
          console.log("trip History errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
         $("#data-table-vehiclesmses").DataTable(options);
        });
    },
    getTripHistoryById: (context, id) => {
      
      context.$store
        .dispatch("OperatorVehicle/getVehicleTripHistoryById", {
          router: context.$router,
          id,
        })
        .then((data) => {
          context.VEHICLETRIPHISTORY = data;
          console.log("trip History: ", data);
           
        })
        .catch((err) => {
          console.log("trip History errors: ", err);
        })
        .finally(() => {
           var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: true,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: true
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }
         //$("#data-table-tripHistory").DataTable(options);
        });
    },

    // vehicleStatusByid: (context) => {
    //   context.$store
    //     .dispatch("OperatorVehicle/getStatusByAssociation", {
    //       id: context.taxiassociationid,
    //       router: context.$router,
    //     })
    //     .then((data) => {
    //       console.log("vehicle status: ", data);

    //       const vehiclestatussource = data.map((element) => {
    //         return {
    //           value: element._vehiclestatus_id,
    //           text: element._name,
    //         };
    //       });
    //       console.log(
    //         "vehiclestatussource: " + JSON.stringify(vehiclestatussource)
    //       );
    //       initiateVehicleStatus(vehiclestatussource);
    //     })
    //     .catch((err) => {
    //       console.log("vehicle status:", err);
    //     });
    //   //this.$emit('init')
    // },

    // checkDiskExpiryById: (context) => {
    //   context.$store
    //     .dispatch("OperatorVehicle/checkDiskById", {
    //       id: context.id,
    //       router: context.$router,
    //     })
    //     .then((data) => {
    //       console.log("vehicle disk expired: ", data[0].status);

    //       context.diskExpired = data[0].status;
    //       if (data[0].status) {
    //         context.$Notify.Error("License Disk Has Expired!");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("vehicle disk expiry errors:", err);
    //     });
    //   //this.$emit('init')
    // },

    onclick: function () {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(handleSuccess)
        .catch(handleError);
    },
    videoonclick: function () {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      // Other browsers will fall back to image/png
      img.src = canvas.toDataURL("image/webp");
    },
    handleSuccess(stream) {
      screenshotButton.disabled = false;
      video.srcObject = stream;
    },
  },
  beforeMount() {
    // this.show(this.context, true);
    // this.getbyid(this.context, this.id);
  },
  mounted: function () {
    //this.get(this.context
    App.init();


    this.getSMSes(this.context, this.id);
    this.getTripTracker(this.context, this.id);


    this.getbyid(this.context, this.id);
    this.getTripHistoryById(this.context, this.id);
    this.vehicleTimeline(this.context, this.id);
    this.vehicleDocs(this.context, this.id);
    this.vehicleFinesById(this.context, this.id);
    this.getVehilcleBillings(this.context, this.id);
    this.getInvoiceDisputes(this.context, this.id);
    this.getReceiptDisputes(this.context, this.id);
    this.getTaxiRoutesByTaxiId(this.context, this.id);
    
    

    // this.vehicleStatusByid(this.context);
    // this.checkDiskExpiryById(this.context);

    $("#vehicleStatus").data("context", this.context);

    $("#datepicker-billingEnd").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#datepicker-nextBilling").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip-invoice-media"]').tooltip();
    $('[data-toggle="tooltip-invoice-voicenote"]').tooltip();
    $('[data-toggle="tooltip-receipt-media"]').tooltip();
    $('[data-toggle="tooltip-receipt-voicenote"]').tooltip();

    $(".default-select").data("context", this.context);

    //$('.selectpicker1').selectpicker('render');
    // FormWizardValidation.init();
    // $("#masked-input-phone").mask("(082) 686-9833");
    // $("#masked-input-tid").mask("99999999999999");

    $(".default-select2")
      .select2()
      .on("change", (e) => {
        const context = $(".default-select").data("context");
      });

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });

    // function update(stream) {
    //   document.querySelector("video").src = stream.url;
    // }
  },
};
var initiateVehicleStatus = function (vehiclestatussource) {
  const context = $("#vehicleStatus").data("context");
  $("#vehicleStatus").editable({
    prepend: "Active",
    source: vehiclestatussource,
    url: null,
    mode: "inline",
    success: function (response, newValue) {
      console.log(this.prepend);
      const context = $("#vehicleStatus").data("context");
      context.$Notify.YesNo(false, { context, newValue }).then((value) => {
        
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context
            .changeVehicleStatusRequest({
              router: value.router,
              addModel: {
                taxiassociationid: context.taxiassociationid,
                taxivehicleid: context.id,
                taxivehiclestatusid: newValue,
                updatedby: context.updatedby,
              },
            })
            .then((_rb) => {
              console.log(_rb); //update backbone model
              this.prepend = _rb[0]._vehiclestatus_name;
              console.log(this.prepend);
              $("#AddModal").modal("hide");
              $("#vehicleStatus").editable("setValue", this.prepend);
              value.context.$wait.end("add");
              value.context.$Notify.Success("Status Change Requested!", "");
            })
            .catch((error) => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data.message, "");
            })
            .finally(() => {});
        }
      });
    },
  });
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>