/* eslint-disable camelcase */
import Vue from 'vue';
import Router from 'vue-router';
// SysAdmin
import {
  chairman_dashboard, chairman_vehicledash,
  Collector_dashboard,
  debtcollectorsmanager_dashboard, grievancemanager_dashboard,
  grievanceofficers_dashboard,
  manage_route,
  managecity, managecountry, managedistrict, managegender, manageotherusers,
  manageprovince,
  managesuburb, managetitles,
  manageusergroups, manageusers,
  nationalusers_dashboard, officeclerks_dashboard,
  provincialusers_dashboard,
  rankmanager,
  rankmanagerdashboard,
  rankmanagerhistory, rankmanagerhistory_region, rankmanagerlist, rankmanagerlist_ass_region, rankmanagerlist_region, rankmanagermetadata, rankmanagermetadata_region, regionalchairman_dashboard,
  regionaltaxiassociation_dashboard, regionalusers_dashboard, secretary_dashboard, sysadmin_dashboard,
  sysadmin_finance,
  sysadmin_operational,
  Treasurer_dashboard
} from './views';
// Approvals
import {
  authorizeapprovals, manageapprovals
} from './views/approvals';
// Emails
import {
  compose,
  emaildetails, inbox,
  reply, sent,
  trash
} from './views/emails';
import Login from './views/Login.vue';
// Money
import {
  cashup,
  cashupdetails, collectionfuneralscheme, collectionreport,
  collectionReportDetails,
  collectionReportPreviousWeekDetails, createinvoice,
  manualBulkPayment, printinvoice,
  printpayment,
  printstatement,
  refundviewPayment,
  takeBulkPayment, takepayment
} from './views/money';
// Office
import {
  calendar,
  livemonitoroffieldagents, sendbulksms,
  smsdetails,
  systemuserprofile,
  updateuserphotos
} from './views/office';
// prepaid services
import {
  addmerchant,
  merchantprofile, merchants,
  prepaidservicescashupdetails
} from './views/prepaidservices';
// Quick Setup
import {
  creditrepair, prepaidetag, prepaidpetrolcard, renewlicensedisk,
  renewoperatinglicense, shorttermloan
} from './views/onlinesservices';
// Quick Setup
import {
  addothersystemusers, addsystemusers,
  addtaxidriver,
  addtaxivehicle, checkinguests, fullsystemsearch, livetrack
} from './views/quicksetup';
// Full Search
import {
  fullsearch
} from './views/search';
// Quick Setup
import {
  addtaxiaffiliation, addtaxiassociation,
  addtaxiassociationaccount, edittaxiassociation, managetaxiassociation,
  managetaxiassociationletterofgoodstanding, managetaxiassociationrank, taxiassociationaddonbillings,
  taxiassociationbillingreceiptstock,
  taxiassociationfraudulentreport, taxiassociationletterofgoodstanding, taxiassociationprofile,
  taxiassociationranksummary,
  taxiassociationroutes,
  taxiassociationspotfines, updateassociationbilling
} from './views/taxiassociation';
// Taxi Drivers
import {
  assigndriver,
  bulkdocumentsuploaddriver, capturephotos,
  disputes, driverfullsearch, editdriver, taxidriverprofile,
  taxidrivers,
  taxidriversverification
} from './views/taxidrivers';
// Taxi Operators
import {
  addtaxioperator,
  bulkdocumentsuploadoperator, editoperator, searchtaxioperator, taxioperatorprofile,
  taxioperators,
  taxioperatorsverification
} from './views/taxioperators';
// import { sysadmin_dashboard } from "@/views/taxidrivers/import.js"
// import { sysadmin_dashboard } from "@/views/taxioperators/import.js"
// Taxi Vehicles
// import { sysadmin_dashboard } from "@/views/taxivehicles/import.js"
import {
  bulkdocumentsuploadtaxis, changeofownership,
  collectorvehicles, datacollection, listvehicles, listvehiclesexpiredlicenses, managemakes,
  managemodels, managevehiclestatus, taxivehicleprofile, updatevehicle,
  vehiclefullsearch,
  vehicleimpound, vehiclephotos
} from './views/taxivehicles';
// Taxi Commuters VIP
import {
  commuterprofile,
  managecommuters, managevipcard,
  manageviproutes, vipdashboard
} from './views/taxivip';
// TPAY 
import {
  AddBooking,
  adddepaturetimes, addrank, addroute,
  agentprofile, bookaseatdashboard, bookingsbysession, bookingsprofileInternal, cardprofile, cardsearch, cardvolt, commuterslist, driverlist, driverprofile, financedashboard, migrateagents, migratetaxiassociation, migratetaxidrivers, migratetaxioperators, migratevehicles, moneytransferdashBoard, moneytransferdeposits, moneytransferfullsearch, moneytransferwithdrawals, newagent, newcommuters, newdriver, newmerchants,
  newtaxioperator,
  operatorlist, operatorsprofile,
  payoutdashboard,
  printpassangerlist,
  publicholidays, requestcallback, tpayallbookings, tpaybookingsprofile, tpaycommuterprofile, tpaydashboard, tpaymerchantprofile, tpaynewvehicle, tpayvehicleprofile, userfullsystemsearch, vehiclefullsystemsearch, vehiclelist,
  viewbookings,
  voucherfullsearch, voucherprofile
} from './views/tpay';
// Sanral eTag
import {
  sanralcommuterprofile,
  sanraldashboard, sanralmanangecustomers,
  sanralmanangevehicles, sanraltopupaccount
} from './views/sanraleTag';

// Fuel Manager 
import {
  addfueltypes, fuelmanagerdash, opsmanager, uploadtransactions
} from './views/fuelmanager';

// Fleet Management 
import {
  addclient,
  arrearscenter,
  bookings, clientwitharrears, clientwitharrears_trans, fleetinventory, fleetmanagementdash,
  fleetmandates,
  listofclients
} from './views/fleetmanagement';

// Loan Application 
import {
  loandash, loans, newloans
} from './views/loans';


Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /* TPay */
    {
      path: '/BookASeat/Bookings/Details/Internal',
      name: 'bookingsprofileInternal',
      component: bookingsprofileInternal,
      meta: {
        title: 'TPAY - Booking',
        metaTags: [{
            name: 'description',
            content: 'TPAY Booking',
          },
          {
            property: 'og:description',
            content: 'TPAY Booking',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Bookings/Details/',
      name: 'tpaybookingsprofile',
      component: tpaybookingsprofile,
      meta: {
        title: 'TPAY - Booking',
        metaTags: [{
            name: 'description',
            content: 'TPAY Booking',
          },
          {
            property: 'og:description',
            content: 'TPAY Booking',
          },
        ],
      },
    },
     /* TPay All Bookings*/
     {
      path: '/TPAY/Bookings/All',
      name: 'tpayallbookings',
      component: tpayallbookings,
      meta: {
        title: 'TPAY - All Bookings',
        metaTags: [{
            name: 'description',
            content: 'TPAY All Bookings',
          },
          {
            property: 'og:description',
            content: 'TPAY All Bookings',
          },
        ],
      },
    },
    /* TPay All Bookings*/
    {
      path: '/BookASeat/Bookings/Session/:id/:desc',
      name: 'bookingsbysession',
      component: bookingsbysession,
      meta: {
        title: 'Book A Seat - Bookings',
        metaTags: [{
            name: 'description',
            content: 'Book A Seat - Bookings',
          },
          {
            property: 'og:description',
            content: 'Book A Seat - Bookings',
          },
        ],
      },
    },
     /* TPay */
     {
      path: '/TPAY/Voucher/Profile/:id',
      name: 'tpayvoucherprofile',
      component: voucherprofile,
      meta: {
        title: 'TPAY - Commuters Profile',
        metaTags: [{
            name: 'description',
            content: 'TPAY Commuters Profile',
          },
          {
            property: 'og:description',
            content: 'TPAY Commuters Profile',
          },
        ],
      },
    },
    /* TPay */
    {
      path: '/TPAY/Vehicle/Profile/:id',
      name: 'tpayvehicleprofile',
      component: tpayvehicleprofile,
      meta: {
        title: 'TPAY - Commuters Profile',
        metaTags: [{
            name: 'description',
            content: 'TPAY Commuters Profile',
          },
          {
            property: 'og:description',
            content: 'TPAY Commuters Profile',
          },
        ],
      },
    },
    {
      path: '/TPAY/Vehicle/FullSearch',
      name: 'tpavehiclefullsystemsearch',
      component: vehiclefullsystemsearch,
      meta: {
        title: 'TPAY - Migrate Vehicle',
        metaTags: [{
            name: 'description',
            content: 'TPAY - Migrate Vehicle',
          },
          {
            property: 'og:description',
            content: 'TPAY - Migrate Vehicle',
          },
        ],
      },
    },
     /* TPay */
     {
      path: '/TPAY/Vehicles/Migrate',
      name: 'tpaymigratevehicles',
      component: migratevehicles,
      meta: {
        title: 'TPAY - Migrate Vehicle',
        metaTags: [{
            name: 'description',
            content: 'TPAY - Migrate Vehicle',
          },
          {
            property: 'og:description',
            content: 'TPAY - Migrate Vehicle',
          },
        ],
      },
    },
     {
      path: '/TPAY/Organisation/Migrate',
      name: 'tpaymigratetaxiassociation',
      component: migratetaxiassociation,
      meta: {
        title: 'TPAY - Migrate Organisation',
        metaTags: [{
            name: 'description',
            content: 'TPAY - Migrate Organisation',
          },
          {
            property: 'og:description',
            content: 'TPAY - Migrate Organisation',
          },
        ],
      },
    },
     {
      path: '/TPAY/Operators/Migrate',
      name: 'tpaymigratetaxioperators',
      component: migratetaxioperators,
      meta: {
        title: 'TPAY - Migrate Operator',
        metaTags: [{
            name: 'description',
            content: 'TPAY - Migrate Operator',
          },
          {
            property: 'og:description',
            content: 'TPAY - Migrate Operator',
          },
        ],
      },
    },
     {
      path: '/TPAY/Driver/Migrate',
      name: 'tpaymigratetaxidrivers',
      component: migratetaxidrivers,
      meta: {
        title: 'TPAY - Migrate Driver',
        metaTags: [{
            name: 'description',
            content: 'TPAY - Migrate Driver',
          },
          {
            property: 'og:description',
            content: 'TPAY - Migrate Driver',
          },
        ],
      },
    },
     {
      path: '/TPAY/Agent/Migrate',
      name: 'tpaymigrateagents',
      component: migrateagents,
      meta: {
        title: 'TPAY - Agent Migrate',
        metaTags: [{
            name: 'description',
            content: 'TPAY Agent Migrate',
          },
          {
            property: 'og:description',
            content: 'TPAY Agent Migrate',
          },
        ],
      },
    },
     {
      path: '/TPAY/Dashboard',
      name: 'tpaydashboard',
      component: tpaydashboard,
      meta: {
        title: 'TPAY - Main Dashboard',
        metaTags: [{
            name: 'description',
            content: 'TPAY Dashboard',
          },
          {
            property: 'og:description',
            content: 'TPAY Main Dashboard',
          },
        ],
      },
    },
    {
      path: '/TPAY/Dashboard/BookASeat/',
      name: 'tpaybookaseatdashboard',
      component: bookaseatdashboard,
      meta: {
        title: 'TPAY - Main Dashboard',
        metaTags: [{
            name: 'description',
            content: 'TPAY Dashboard',
          },
          {
            property: 'og:description',
            content: 'TPAY Main Dashboard',
          },
        ],
      },
    },
    {
      path: '/FuelManager/Dashboard/',
      name: 'fuelmanagerdash',
      component: fuelmanagerdash,
      meta: {
        title: 'Fuel Manager - Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Fuel Manager Dashboard',
          },
          {
            property: 'og:description',
            content: 'Fuel Manager Dashboard',
          },
        ],
      },
    },
    {
      path: '/FuelManager/Operations/',
      name: 'opsmanager',
      component: opsmanager,
      meta: {
        title: 'Fuel Manager - Operations',
        metaTags: [{
            name: 'description',
            content: 'Fuel Manager Operations',
          },
          {
            property: 'og:description',
            content: 'Fuel Manager Operations',
          },
        ],
      },
    },
    {
      path: '/FuelManager/UploadTrans/',
      name: 'uploadtransactions',
      component: uploadtransactions,
      meta: {
        title: 'Fuel Manager - Upload Transactions',
        metaTags: [{
            name: 'description',
            content: 'Fuel Manager Upload Transactions',
          },
          {
            property: 'og:description',
            content: 'Fuel Manager Upload Transactions',
          },
        ],
      },
    },
    {
      path: '/TPAY/FinanceDashboard',
      name: 'tpayfinancedashboard',
      component: financedashboard,
      meta: {
        title: 'TPAY - Finance Dashboard',
        metaTags: [{
            name: 'description',
            content: 'TPAY Finance Dashboard',
          },
          {
            property: 'og:description',
            content: 'TPAY Finance  Dashboard',
          },
        ],
      },
    },
    {
      path: '/TPAY/MoneyTransferDashBoard',
      name: 'tpayMoneyTransferDashBoard',
      component: moneytransferdashBoard,
      meta: {
        title: 'TPAY - Finance Dashboard',
        metaTags: [{
            name: 'description',
            content: 'TPAY Finance Dashboard',
          },
          {
            property: 'og:description',
            content: 'TPAY Finance  Dashboard',
          },
        ],
      },
    },
    {
      path: '/TPAY/Cards/Profile/:id',
      name: 'tpaycardprofile',
      component: cardprofile,
      meta: {
        title: 'TPAY - Card Profile',
        metaTags: [{
            name: 'description',
            content: 'TPAY Card Profile',
          },
          {
            property: 'og:description',
            content: 'TPAY Card Profile',
          },
        ],
      },
    },
    {
      path: '/TPAY/Cards/Search',
      name: 'tpaycardsearch',
      component: cardsearch,
      meta: {
        title: 'TPAY - Card Search',
        metaTags: [{
            name: 'description',
            content: 'TPAY Card Search',
          },
          {
            property: 'og:description',
            content: 'TPAY Card Search',
          },
        ],
      },
    },
    {
      path: '/TPAY/Cards/Volt',
      name: 'tpaycardcardvolt',
      component: cardvolt,
      meta: {
        title: 'TPAY - Card Search',
        metaTags: [{
            name: 'description',
            content: 'TPAY Card Search',
          },
          {
            property: 'og:description',
            content: 'TPAY Card Search',
          },
        ],
      },
    },
    {
      path: '/TPAY/MoneyTransfer/Search',
      name: 'tpaymoneytransferfullsearch',
      component: moneytransferfullsearch,
      meta: {
        title: 'TPAY - Money Transfer Search',
        metaTags: [{
            name: 'description',
            content: 'TPAY Money Transfer Search',
          },
          {
            property: 'og:description',
            content: 'TPAY Money Transfer Search',
          },
        ],
      },
    },
    {
      path: '/TPAY/MoneyTransfer/Deposits',
      name: 'tpaymoneytransferdeposits',
      component: moneytransferdeposits,
      meta: {
        title: 'TPAY - Money Transfer Deposits',
        metaTags: [{
            name: 'description',
            content: 'TPAY Money Transfer Deposits',
          },
          {
            property: 'og:description',
            content: 'TPAY Money Transfer Deposits',
          },
        ],
      },
    },
    {
      path: '/TPAY/MoneyTransfer/Withdrawals',
      name: 'tpaymoneytransferwithdrawals',
      component: moneytransferwithdrawals,
      meta: {
        title: 'TPAY - Money Transfer Withdrawals',
        metaTags: [{
            name: 'description',
            content: 'TPAY Money Transfer Withdrawals',
          },
          {
            property: 'og:description',
            content: 'TPAY Money Transfer Withdrawals',
          },
        ],
      },
    },
    {
      path: '/TPAY/Commuters/Profile/:id',
      name: 'tpaytpaycommuterprofile',
      component: tpaycommuterprofile,
      meta: {
        title: 'TPAY - Commuters Profile',
        metaTags: [{
            name: 'description',
            content: 'TPAY Commuters Profile',
          },
          {
            property: 'og:description',
            content: 'TPAY Commuters Profile',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Commuters/New/:id',
      name: 'tpaynewcommuters',
      component: newcommuters,
      meta: {
        title: 'TPAY - New Commuters',
        metaTags: [{
            name: 'description',
            content: 'TPAY New Commuters',
          },
          {
            property: 'og:description',
            content: 'TPAY New Commuters',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Driver/New/:id',
      name: 'tpaynewdriver',
      component: newdriver,
      meta: {
        title: 'BookASeat - New Driver',
        metaTags: [{
            name: 'description',
            content: 'BookASeat New Driver',
          },
          {
            property: 'og:description',
            content: 'BookASeat New Driver',
          },
        ],
      },
    },
    {
      path: '/TPAY/Driver/Profile/:id',
      name: 'tpaydriverprofile',
      component: driverprofile,
      meta: {
        title: 'TPAY - Driver Profile',
        metaTags: [{
            name: 'description',
            content: 'TPAY Driver Profile',
          },
          {
            property: 'og:description',
            content: 'TPAY Driver Profile',
          },
        ],
      },
    },
    {
      path: '/TPAY/Merchant/Profile/:id',
      name: 'tpaymerchantprofile',
      component: tpaymerchantprofile,
      meta: {
        title: 'BookASeat - Merchant Profile',
        metaTags: [{
            name: 'description',
            content: 'BookASeat Merchant Profile',
          },
          {
            property: 'og:description',
            content: 'BookASeat Merchant Profile',
          },
        ],
      },
    },
    {
      path: '/TPAY/Merchant/New/:id',
      name: 'tpaynewmerchants',
      component: newmerchants,
      meta: {
        title: 'BookASeat - New Merchant',
        metaTags: [{
            name: 'description',
            content: 'BookASeat New Merchant',
          },
          {
            property: 'og:description',
            content: 'BookASeat New Merchant',
          },
        ],
      },
    },
    {
      path: '/TPAY/Operator/Profile/:id',
      name: 'tpayoperatorsprofile',
      component: operatorsprofile,
      meta: {
        title: 'BookASeat - Operator Profile',
        metaTags: [{
            name: 'description',
            content: 'BookASeat Operator Profile',
          },
          {
            property: 'og:description',
            content: 'BookASeat Operator Profile',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Operator/New/:id',
      name: 'tpaynewtaxioperator',
      component: newtaxioperator,
      meta: {
        title: 'BookASeat - New Operator',
        metaTags: [{
            name: 'description',
            content: 'BookASeat New Operator',
          },
          {
            property: 'og:description',
            content: 'BookASeat New Operator',
          },
        ],
      },
    },
    {
      path: '/BookASeat/PrintPassangerList/:id',
      name: 'printpassangerlist',
      component: printpassangerlist,
      meta: {
        title: 'BookASeat - Print Passanger List',
        metaTags: [{
            name: 'description',
            content: 'Print Passanger List',
          },
          {
            property: 'og:description',
            content: 'Print Passanger List',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Rank',
      name: 'addrank',
      component: addrank,
      meta: {
        title: 'BookASeat - Rank',
        metaTags: [{
            name: 'description',
            content: 'BookASeat Rank',
          },
          {
            property: 'og:description',
            content: 'BookASeat Rank',
          },
        ],
      },
    },
    {
      path: '/BookASeat/ViewBookings',
      name: 'viewbookings',
      component: viewbookings,
      meta: {
        title: 'BookASeat - View Bookings',
        metaTags: [{
            name: 'description',
            content: 'BookASeat View Bookings',
          },
          {
            property: 'og:description',
            content: 'BookASeat View Bookingse',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Departure',
      name: 'adddepaturetimes',
      component: adddepaturetimes,
      meta: {
        title: 'BookASeat - Departure Time',
        metaTags: [{
            name: 'description',
            content: 'BookASeat  Departure Time',
          },
          {
            property: 'og:description',
            content: 'BookASeat  Departure Time',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Route',
      name: 'addroute',
      component: addroute,
      meta: {
        title: 'BookASeat - New Operator',
        metaTags: [{
            name: 'description',
            content: 'BookASeat New Operator',
          },
          {
            property: 'og:description',
            content: 'BookASeat New Operator',
          },
        ],
      },
    },
    {
      path: '/BookASeat/AddBooking',
      name: 'AddBooking',
      component: AddBooking,
      meta: {
        title: 'BookASeat - New Booking',
        metaTags: [{
            name: 'description',
            content: 'BookASeat New Booking',
          },
          {
            property: 'og:description',
            content: 'BookASeat New Booking',
          },
        ],
      },
    },
    {
      path: '/FuelManager/AddFuelTypes/',
      name: 'addfueltypes',
      component: addfueltypes,
      meta: {
        title: 'Fuel Manager - Fuel Types',
        metaTags: [{
            name: 'description',
            content: 'Fuel Manager - Fuel Types',
          },
          {
            property: 'og:description',
            content: 'Fuel Manager - Fuel Types',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Vehicle/NewVehicle/:id',
      name: 'tpaynewvehicle',
      component: tpaynewvehicle,
      meta: {
        title: 'Book A Seat - New Vehicle',
        metaTags: [{
            name: 'description',
            content: 'Book A Seat New Vehicle',
          },
          {
            property: 'og:description',
            content: 'Book A Seat New Vehicle',
          },
        ],
      },
    },
    
    {
      path: '/TPAY/Agent/Profile/:id',
      name: 'tpayagentprofile',
      component: agentprofile,
      meta: {
        title: 'TPAY - Agent Profile',
        metaTags: [{
            name: 'description',
            content: 'TPAY Agent Profile',
          },
          {
            property: 'og:description',
            content: 'TPAY Agent Profile',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Agent/New/:id',
      name: 'tpaynewagent',
      component: newagent,
      meta: {
        title: 'Book A Seat - New Agent',
        metaTags: [{
            name: 'description',
            content: 'Book A Seat New Agent',
          },
          {
            property: 'og:description',
            content: 'Book A Seat New Agent',
          },
        ],
      },
    },
    {
      path: '/BookASeat/PublicHolidays',
      name: 'publicholidays',
      component: publicholidays,
      meta: {
        title: 'BookASeat - PublicHolidays',
        metaTags: [{
            name: 'description',
            content: 'BookASeat PublicHolidays',
          },
          {
            property: 'og:description',
            content: 'BookASeat PublicHolidays',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Users/FullSearch',
      name: 'tpayuserfullsystemsearch',
      component: userfullsystemsearch,
      meta: {
        title: 'TPAY - Users Search',
        metaTags: [{
            name: 'description',
            content: 'TPAY Users Search',
          },
          {
            property: 'og:description',
            content: 'TPAY Users Search',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Commuters/List',
      name: 'commuterslist',
      component: commuterslist,
      meta: {
        title: 'BookASeat - All Commuter',
        metaTags: [{
            name: 'description',
            content: 'BookASeat - All Commuter',
          },
          {
            property: 'og:description',
            content: 'BookASeat - All Commuter',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Vehicles/List',
      name: 'vehiclelist',
      component: vehiclelist,
      meta: {
        title: 'BookASeat - All Vehicles',
        metaTags: [{
            name: 'description',
            content: 'BookASeat - All Vehicles',
          },
          {
            property: 'og:description',
            content: 'BookASeat - All Vehicles',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Drivers/List',
      name: 'driverlist',
      component: driverlist,
      meta: {
        title: 'BookASeat - All Drivers',
        metaTags: [{
            name: 'description',
            content: 'BookASeat - All Drivers',
          },
          {
            property: 'og:description',
            content: 'BookASeat - All Drivers',
          },
        ],
      },
    },
    {
      path: '/BookASeat/Operators/List',
      name: 'operatorlist',
      component: operatorlist,
      meta: {
        title: 'BookASeat - All Operators',
        metaTags: [{
            name: 'description',
            content: 'BookASeat - All Operators',
          },
          {
            property: 'og:description',
            content: 'BookASeat - All Operators',
          },
        ],
      },
    },
    {
      path: '/TPAY/Voucher/Search',
      name: 'tpayvoucherfullsearch',
      component: voucherfullsearch,
      meta: {
        title: 'TPAY - Voucher Search',
        metaTags: [{
            name: 'description',
            content: 'TPAY Voucher Search',
          },
          {
            property: 'og:description',
            content: 'TPAY Voucher Search',
          },
        ],
      },
    },
    // {
    //   path: '/TPAY/Voucher/:id',
    //   name: 'tpayvoucherprofile',
    //   component: voucherprofile,
    //   meta: {
    //     title: 'TPAY - New Voucher',
    //     metaTags: [{
    //         name: 'description',
    //         content: 'TPAY New Voucher',
    //       },
    //       {
    //         property: 'og:description',
    //         content: 'TPAY New Voucher',
    //       },
    //     ],
    //   },
    // },
    {
      path: '/TPAY/RequestCallBack',
      name: 'tpayrequestcallback',
      component: requestcallback,
      meta: {
        title: 'TPAY - Request Call Back',
        metaTags: [{
            name: 'description',
            content: 'TPAY Request Call Back',
          },
          {
            property: 'og:description',
            content: 'TPAY Request Call Back',
          },
        ],
      },
    },
    /* Rank Manager */
    {
      path: '/RankManager/Dashboard/:id/:desc',
      name: 'rankmanagerdashboard',
      component: rankmanagerdashboard,
      meta: {
        title: 'TAMS - Rank Manager Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager Dashboard',
          },
          {
            property: 'og:description',
            content: 'Rank Manager Dashboard',
          },
        ],
      },
    },

    {
      path: '/RankManager/History/Region',
      name: 'rankmanagerhistory_region',
      component: rankmanagerhistory_region,
      meta: {
        title: 'TAMS - Rank Manager Stats',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager Stats',
          },
          {
            property: 'og:description',
            content: 'Rank Manager Stats',
          },
        ],
      },
    },
    {
      path: '/RankManager/Meta/Region',
      name: 'rankmanagermetadata_region',
      component: rankmanagermetadata_region,
      meta: {
        title: 'TAMS - Rank Manager Meta',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager Meta',
          },
          {
            property: 'og:description',
            content: 'Rank Manager Meta',
          },
        ],
      },
    },
    {
      path: '/RankManager/History',
      name: 'rankmanagerhistory',
      component: rankmanagerhistory,
      meta: {
        title: 'TAMS - Rank Manager Stats',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager Stats',
          },
          {
            property: 'og:description',
            content: 'Rank Manager Stats',
          },
        ],
      },
    },
    {
      path: '/RankManager/Meta',
      name: 'rankmanagermetadata',
      component: rankmanagermetadata,
      meta: {
        title: 'TAMS - Rank Manager Meta',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager Meta',
          },
          {
            property: 'og:description',
            content: 'Rank Manager Meta',
          },
        ],
      },
    },
    {
      path: '/RankManager/Rank/:id/:desc',
      name: 'rankmanager',
      component: rankmanager,
      meta: {
        title: 'TAMS - Rank Manager',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager',
          },
          {
            property: 'og:description',
            content: 'Rank Manager',
          },
        ],
      },
    },
    {
      path: '/RankManager/Region/Ass/:id',
      name: 'rankmanagerlist_ass_region',
      component: rankmanagerlist_ass_region,
      meta: {
        title: 'TAMS - Rank Manager ',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager',
          },
          {
            property: 'og:description',
            content: 'Rank Manager',
          },
        ],
      },
    },
    {
      path: '/RankManager/Region/:id',
      name: 'rankmanagerlist_region',
      component: rankmanagerlist_region,
      meta: {
        title: 'TAMS - Rank Manager ',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager',
          },
          {
            property: 'og:description',
            content: 'Rank Manager',
          },
        ],
      },
    },
    {
      path: '/RankManager/',
      name: 'rankmanagerlist',
      component: rankmanagerlist,
      meta: {
        title: 'TAMS - Rank Manager ',
        metaTags: [{
            name: 'description',
            content: 'Rank Manager',
          },
          {
            property: 'og:description',
            content: 'Rank Manager',
          },
        ],
      },
    },
    /* Start Taxi driver */
    {
      path: '/Driver/All/:id',
      name: 'taxidrivers1',
      component: taxidrivers,
    },
    {
      path: '/Driver/All',
      name: 'taxidrivers',
      component: taxidrivers,
    },
    {
      path: '/Driver/Search',
      name: 'driverfullsearch',
      component: driverfullsearch,
    },
    {
      path: '/Driver/Profile/:id',
      name: 'taxidriverprofile',
      component: taxidriverprofile,
    },
    {
      path: '/Driver/Photo/:id',
      name: 'capturephotos',
      component: capturephotos,
    },
    {
      path: '/Driver/Edit/:id',
      name: 'editdriver',
      component: editdriver,
    },
    {
      path: '/disputes/:id',
      name: 'disputes',
      component: disputes,
    },
    {
      path: '/QuickSetup/Driver/:id',
      name: 'addtaxidriver',
      component: addtaxidriver,
    },  
    {
      path: '/Driver/Profile/AssignDriver/:id',
      name: 'assigndriver',
      component: assigndriver,
    },

    {
      path: '/Driver/Verification',
      name: 'taxidriversverification',
      component: taxidriversverification,
    },

    {
      path: '/Office/RealTimeMonitor',
      name: 'RealtimeMonitor',
      component: livemonitoroffieldagents,
    }, 
    
    {
      path: '/Office/updateuserphotos/:id',
      name: 'updateuserphotos',
      component: updateuserphotos,
    },
    
    {
      path: '/Office/Calendar',
      name: 'calendar',
      component: calendar,
    },
    {
      path: '/Office/SendBulkSMS',
      name: 'sendbulksms',
      component: sendbulksms,
    },
    {
      path: '/Office/SMSDetails/:batchname',
      name: 'smsdetails',
      component: smsdetails,
    },
    {
      path: '/office/systemuserprofile/:id',
      name: 'systemuserprofile',
      component: systemuserprofile,
    },
    {
      path: '/Chairman/VehicleDashboard',
      name: 'chairman_vehicledash',
      component: chairman_vehicledash,
      meta: {
        title: 'TAMS - Vehicle Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Vehicle Dashboard',
          },
          {
            property: 'og:description',
            content: 'Vehicle Dashboard',
          },
        ],
      },
    },
    {
      path: '/Chairman/Dashboard',
      name: 'chairmandashboard',
      component: chairman_dashboard,
      meta: {
        title: 'TAMS - Chairman Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Chairman Dashboard',
          },
          {
            property: 'og:description',
            content: 'Chairman Dashboard',
          },
        ],
      },
    },
    {
      path: '/Secretary/Dashboard',
      name: 'secretarydashboard',
      component: secretary_dashboard,
      meta: {
        title: 'TAMS - Secretary Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Secretary Dashboard',
          },
          {
            property: 'og:description',
            content: 'Secretary Dashboard',
          },
        ],
      },
    },
    {
      path: '/Secretary/ManageRoute',
      name: 'manage_route',
      component: manage_route,
      meta: {
        title: 'TAMS - Secretary Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Secretary Dashboard',
          },
          {
            property: 'og:description',
            content: 'Secretary Dashboard',
          },
        ],
      },
    },
    {
      path: '/Treasurer/Dashboard',
      name: 'treasurerdashboard',
      component: Treasurer_dashboard,
      meta: {
        title: 'TAMS - Treasurer Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Treasurer Dashboard',
          },
          {
            property: 'og:description',
            content: 'Treasurer Dashboard',
          },
        ],
      },
    },
    {
      path: '/Collector/Dashboard',
      name: 'collectordashboard',
      component: Collector_dashboard,
      meta: {
        title: 'TAMS - Collector Dashboard',
        metaTags: [{
            name: 'description',
            content: 'Collector Dashboard',
          },
          {
            property: 'og:description',
            content: 'Collector Dashboard',
          },
        ],
      },
    },
    {
      path: '/GrievanceManager/Dashboard',
      name: 'grievancemanagerdashboard',
      component: grievancemanager_dashboard,
      meta: {
        title: 'TAMS - GrievanceManager Dashboard',
        metaTags: [{
            name: 'description',
            content: 'GrievanceManager Dashboard',
          },
          {
            property: 'og:description',
            content: 'GrievanceManager Dashboard',
          },
        ],
      },
    },
    {
      path: '/GrievanceOffices/Dashboard',
      name: 'grievanceofficersdashboard',
      component: grievanceofficers_dashboard,
      meta: {
        title: 'TAMS - GrievanceOffices Dashboard',
        metaTags: [{
            name: 'description',
            content: 'GrievanceOffices Dashboard',
          },
          {
            property: 'og:description',
            content: 'GrievanceOffices Dashboard',
          },
        ],
      },
    },
    {
      path: '/OfficeClerk/Dashboard',
      name: 'officeclerksdashboard',
      component: officeclerks_dashboard,
      meta: {
        title: 'TAMS - OfficeClerk Dashboard',
        metaTags: [{
            name: 'description',
            content: 'OfficeClerk Dashboard',
          },
          {
            property: 'og:description',
            content: 'OfficeClerk Dashboard',
          },
        ],
      },
    },
    {
      path: '/Provincial/Dashboard',
      name: 'provincialusersdashboard',
      component: provincialusers_dashboard,
      meta: {
        title: 'TAMS - OfficeClerk Dashboard',
        metaTags: [{
            name: 'description',
            content: 'OfficeClerk Dashboard',
          },
          {
            property: 'og:description',
            content: 'OfficeClerk Dashboard',
          },
        ],
      },
    },
    {
      path: '/Regional/Dashboard',
      name: 'regionalusersdashboard',
      component: regionalusers_dashboard,
      meta: {
        title: 'TAMS - OfficeClerk Dashboard',
        metaTags: [{
            name: 'description',
            content: 'OfficeClerk Dashboard',
          },
          {
            property: 'og:description',
            content: 'OfficeClerk Dashboard',
          },
        ],
      },
    },
    {
      path: '/National/Dashboard',
      name: 'nationalusersdashboard',
      component: nationalusers_dashboard,
      meta: {
        title: 'TAMS - National Dashboard',
        metaTags: [{
            name: 'description',
            content: 'National Dashboard',
          },
          {
            property: 'og:description',
            content: 'National Dashboard',
          },
        ],
      },
    },
    {
      path: '/money/refundpayment',
      name: 'refundviewPayment',
      component: refundviewPayment,
    },
    {
      path: '/money/statement',
      name: 'printstatement',
      component: printstatement,
    },
    {
      path: '/money/payment/:id/:preview',
      name: 'printpayment',
      component: printpayment,
    },
    {
      path: '/money/invoice/:id/:preview',
      name: 'printinvoice',
      component: printinvoice,
    },
    {
      path: '/money/cashup',
      name: 'cashup',
      component: cashup,
    },
    {
      path: '/money/cashupdetails/:collectorid/:startdate/:enddate/:activesession',
      name: 'cashupdetails',
      component: cashupdetails,
    },
    {
      path: '/money/collectionreport',
      name: 'collectionreport',
      component: collectionreport,
    },
    {
      path: '/money/FuneralSchemeCollection',
      name: 'collectionfuneralscheme',
      component: collectionfuneralscheme,
    },

    {
      path: '/money/takeBulkPayment',
      name: 'takeBulkPayment',
      component: takeBulkPayment,
    },

    {
      path: '/money/manualBulkPayment',
      name: 'manualBulkPayment',
      component: manualBulkPayment,
    },

    {
      path: '/money/collectorWeeklyArrears/:id',
      name: 'collectionReportDetails',
      component: collectionReportDetails,
    },

    {
      path: '/money/collectionReportPreviousWeekDetails/:id',
      name: 'collectionReportPreviousWeekDetails',
      component: collectionReportPreviousWeekDetails,
    },
    

    // {
    //   path: '/taxioperatorprofile',
    //   name: 'taxioperatorprofile',
    //   component: taxioperatorprofile,
    // },
    // {
    //   path: '/money/createinvoice',
    //   name: 'createinvoice',
    //   component: createinvoice,
    // },
    {
      path: '/money/Takepayment/:PrePayment',
      name: 'prepayment',
      component: takepayment,
    },
    {
      path: '/money/Takepayment',
      name: 'takepayment',
      component: takepayment,
    },
    {
      path: '/Operator/Profile/:id',
      name: 'taxioperatorprofile',
      component: taxioperatorprofile,
    },
    {
      path: '/Operator/Profile/:id/:approvalid',
      name: 'taxioperatorprofileapproval',
      component: taxioperatorprofile,
    },
    {
      path: '/money/createinvoice',
      name: 'createinvoice',
      component: createinvoice,
    },
    // {
    //   path: '/money/Takepayment',
    //   name: 'takepayment',
    //   component: takepayment,
    // },
    {
      path: '/Vehicle/Add/:id',
      name: 'addTaxiVehicle',
      component: addtaxivehicle,
    },
    
    {
      path: '/addTaxiDriver',
      name: 'addTaxiDriver',
      component: addtaxidriver,
    },
    {
      path: '/SysAdmin/suburb',
      name: 'managesuburb',
      component: managesuburb,
      meta: {
        title: 'TAMS - Manage Suburb',
        metaTags: [{
            name: 'description',
            content: 'Manage Suburb',
          },
          {
            property: 'og:description',
            content: 'Manage Suburb',
          },
        ],
      },
    },
    {
      path: '/SysAdmin/city',
      name: 'managecity',
      component: managecity,
      meta: {
        title: 'TAMS - Manage City',
        metaTags: [{
            name: 'description',
            content: 'Manage City',
          },
          {
            property: 'og:description',
            content: 'Manage City',
          },
        ],
      },
    },
    {
      path: '/SysAdmin/province',
      name: 'manageprovince',
      component: manageprovince,
      meta: {
        title: 'TAMS - Add Province',
        metaTags: [{
            name: 'description',
            content: 'Add Province',
          },
          {
            property: 'og:description',
            content: 'Add Province',
          },
        ],
      },
    },
    {
      path: '/SysAdmin/country',
      name: 'Add Country',
      component: managecountry,
      meta: {
        title: 'TAMS - Add Country',
        metaTags: [{
            name: 'description',
            content: 'Add Country',
          },
          {
            property: 'og:description',
            content: 'Add Country',
          },
        ],
      },
    },
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    /* Begin System Administrator */
    /* Begin Dashboard */
    {
      path: '/RegionalChairman/Dashboard',
      name: 'regionalchairman_dashboard',
      component: regionalchairman_dashboard,
    },
    {
      path: '/RegionalChairman/TaxiAssociationDashboard',
      name: 'regionaltaxiassociation_dashboard',
      component: regionaltaxiassociation_dashboard,
    },
    {
      path: '/SysAdmin/Dashboard',
      name: 'sysadmin_dashboard',
      component: sysadmin_dashboard,
    },
    {
      path: '/SysAdmin/Finance',
      name: 'sysadmin_finance',
      component: sysadmin_finance,
    },
    {
      path: '/SysAdmin/SystemUsers',
      name: 'managesystemusers',
      component: manageusers,
    },
    {
      path: '/SysAdmin/OtherSystemUsers',
      name: 'manageothersystemusers',
      component: manageotherusers,
    },
    {
      path: '/Search/FullTextSearch',
      name: 'fullsearch',
      component: fullsearch,
    },
    {
      path: '/SysAdmin/District',
      name: 'managedistrict',
      component: managedistrict,
      meta: {
        title: 'TAMS - Manage District',
        metaTags: [{
            name: 'description',
            content: 'Manage District',
          },
          {
            property: 'og:description',
            content: 'Manage District',
          },
        ],
      },
    },
    {
      path: '/SysAdmin/Titles',
      name: 'managetitles',
      component: managetitles,
      meta: {
        title: 'TAMS - Manage Title',
        metaTags: [{
            name: 'description',
            content: 'Manage Titles',
          },
          {
            property: 'og:description',
            content: 'Manage Titles',
          },
        ],
      },
    },
    {
      path: '/SysAdmin/Gender',
      name: 'managegender',
      component: managegender,
      meta: {
        title: 'TAMS - Manage Gender',
        metaTags: [{
            name: 'description',
            content: 'Manage Gender',
          },
          {
            property: 'og:description',
            content: 'Manage Gender',
          },
        ],
      },
    },
    {
      path: '/SysAdmin/UserGroups',
      name: 'manageusergroups',
      component: manageusergroups,
      meta: {
        title: 'TAMS - User Groups',
        metaTags: [{
            name: 'description',
            content: 'Manage User Groups',
          },
          {
            property: 'og:description',
            content: 'Manage User Groups',
          },
        ],
      },
    },
    {
      path: '/QuickSetup/checkinguests',
      name: 'CheckInGuests',
      component: checkinguests,
      meta: {
        title: 'TAMS - Check In Guests',
        metaTags: [{
            name: 'description',
            content: 'Check In Guests',
          },
          {
            property: 'og:description',
            content: 'Check In Guests',
          },
        ],
      },
    },
    {
      path: '/QuickSetup/AddSystemUser',
      name: 'Add SystemUser',
      component: addsystemusers,
      meta: {
        title: 'TAMS - Add System User',
        metaTags: [{
            name: 'description',
            content: 'Add Taxi System User',
          },
          {
            property: 'og:description',
            content: 'Add Taxi System User',
          },
        ],
      },
    },
    {
      path: '/QuickSetup/AddOtherSystemUser',
      name: 'Add Other SystemUser',
      component: addothersystemusers,
      meta: {
        title: 'TAMS - Add System User',
        metaTags: [{
            name: 'description',
            content: 'Add Taxi System User',
          },
          {
            property: 'og:description',
            content: 'Add Taxi System User',
          },
        ],
      },
    },
    {
      path: '/QuickSetup/LiveTrack',
      name: 'Live Track',
      component: livetrack,
      meta: {
        title: 'TAMS - Live Track',
        metaTags: [{
            name: 'description',
            content: 'Live Track',
          },
          {
            property: 'og:description',
            content: 'Live Track',
          },
        ],
      },
    },
    {
      path: '/QuickSetup/fullsystemsearch/',
      name: 'fullsystem_search' ,
      component: fullsystemsearch,
    },
    {
      path: '/TaxiAssociation/AddTaxiAffiliation',
      name: 'Add AddTaxi Affiliation',
      component: addtaxiaffiliation,
      meta: {
        title: 'TAMS - Add Taxi Affiliation',
        metaTags: [{
            name: 'description',
            content: 'Add Taxi Affiliation',
          },
          {
            property: 'og:description',
            content: 'Add Taxi Affiliation',
          },
        ],
      },
    },
    {
      path: '/sysadmin_operational',
      name: 'sysadmin_operational',
      component: sysadmin_operational,
    },
    /* End Dashboard */
    /* Begin Quick Setup */
    {
      path: '/TaxiAssociation/Add',
      name: 'addTaxiAssociation',
      component: addtaxiassociation,
    },
    {
      path: '/TaxiAssociation/Manage',
      name: 'manageTaxiAssociation',
      component: managetaxiassociation,
    },
    {
      path: '/TaxiAssociation/Profile/:id',
      name: 'TaxiAssociationProfile',
      component: taxiassociationprofile,
    },
    {
      path: '/TaxiAssociation/Routes/:id',
      name: 'taxiassociationRoutes',
      component: taxiassociationroutes,
    },
    {
      path: '/TaxiAssociation/UpdateBilling',
      name: 'TaxiAssociationUpdateBilling',
      component: updateassociationbilling,
    },
    {
      path: '/TaxiAssociation/AddBilling',
      name: 'addonbilling',
      component: taxiassociationaddonbillings,
    },
    {
      path: '/TaxiAssociation/ReceiptStock',
      name: 'billingreceiptstock',
      component: taxiassociationbillingreceiptstock,
    },
    {
      path: '/TaxiAssociation/Ranks',
      name: 'taxiassociationranks',
      component: managetaxiassociationrank,
    },

    {
      path: '/TaxiAssociation/Rank/:id/:desc',
      name: 'ranksummary',
      component: taxiassociationranksummary,
    },
    {
      path: '/TaxiAssociation/SpotFines/:id',
      name: 'taxiassociationspotfines',
      component: taxiassociationspotfines,
    },

    {
      path: '/TaxiAssociation/GoodStanding',
      name: 'taxiassociationletterofgoodstanding',
      component: taxiassociationletterofgoodstanding,
    },

    {
      path: '/TaxiAssociation/ManageGoodStanding',
      name: 'managetaxiassociationletterofgoodstanding',
      component: managetaxiassociationletterofgoodstanding,
    },

    {
      path: '/TaxiAssociation/FraudulentReport/:id',
      name: 'taxiassociationfraudulentreport',
      component: taxiassociationfraudulentreport,
    },

    {
      path: '/TaxiAssociation/Account/Add',
      name: 'addtaxiassociationaccount',
      component: addtaxiassociationaccount,
    },

    {
      path: '/TaxiAssociation/Edit/:id',
      name: 'taxiassociationupdate',
      component: edittaxiassociation,
    },


    {
      path: '/Operators/New/:id',
      name: 'addTaxiOperators',
      component: addtaxioperator,
    },
    {
      path: '/Operators/Search',
      name: 'searchtaxioperator',
      component: searchtaxioperator,
    },
    {
    path: '/Operators/List/:id',
      name: 'taxioperatorslist1',
      component: taxioperators,
    },
    {
      path: '/Operators/List',
        name: 'taxioperatorslist',
        component: taxioperators,
      },
    {
      path: '/Operators/Edit/:id',
      name: 'editoperator',
      component: editoperator,
    },
    {
      path: '/Operators/Verify',
      name: 'taxioperatorsverification',
      component: taxioperatorsverification,
    },

    
    
    // {
    //   path: '/addTaxiVehicle',
    //   name: 'addTaxiVehicle',
    //   component: sysadmin_operational,
    // },
    // {
    //   path: '/addTaxiDriver',
    //   name: 'addTaxiDriver',
    //   component: sysadmin_operational,
    // },
    /*End taxioperator*/
    /*Begin Taxi Vehicle*/
    {
      path: '/Vehicles/Profile/:id/:approvalid',
      name: 'taxivehicleprofileapproval',
      component: taxivehicleprofile,
    },
    {
      path: '/Vehicles/Profile/:id',
      name: 'taxivehicleprofile',
      component: taxivehicleprofile,
    },
    {
      path: '/Vehicles/List',
      name: 'managevehicle',
      component: listvehicles,
    },
    {
      path: '/Vehicles/List/:id',
      name: 'managevehicle1',
      component: listvehicles,
    },
    {
      path: '/Vehicles/ExpiredOperatingLicense',
      name: 'managevehicleExpiredOperatingLicense',
      component: listvehiclesexpiredlicenses,
    },
    {
      path: '/Vehicles/Impound',
      name: 'vehicleimpound',
      component: vehicleimpound,
    },
    {
      path: '/Vehicles/DataCollection',
      name: 'datacollection',
      component: datacollection,
    },

    {
      path: '/Vehicles/Edit/:id',
      name: 'updatevehicle',
      component: updatevehicle,
    },
    {
      path: '/managevehiclestatus',
      name: 'managevehiclestatus',
      component: managevehiclestatus,
    },
    {
      path: '/Vehicles/Search',
      name: 'searchVehicle',
      component: vehiclefullsearch,
    },
    {
      path: '/Vehicles/changeofownership/:id',
      name: 'changeofownership',
      component: changeofownership,
    },
    {
      path: '/Vehicles/photos/:id',
      name: 'vehiclephotos',
      component: vehiclephotos,
    },
    {
      path: '/Vehicles/Makes',
      name: 'managemakes',
      component: managemakes,
    },
    {
      path: '/Vehicles/Models/:id',
      name: 'managemodels',
      component: managemodels,
    },
    
    /* End System Administrator */

    /* End Taxi driver */
    /* Start Approvals */
    {
      path: '/Approvals/Authorise/:id',
      name: 'approvals',
      component: authorizeapprovals,
    },
    {
      path: '/Vehicles/BulkUpload',
      name: 'bulkdocumentsuploadtaxis',
      component: bulkdocumentsuploadtaxis,
    },
    {
      path: '/Vehicles/collectorvehicles',
      name: 'collectorvehicles',
      component: collectorvehicles,
    },
    {
      path: '/Operators/BulkUpload',
      name: 'bulkdocumentsuploadoperator',
      component: bulkdocumentsuploadoperator,
    },
    {
      path: '/Driver/BulkUpload',
      name: 'bulkdocumentsuploaddriver',
      component: bulkdocumentsuploaddriver,
    },
    {
      path: '/Approvals/DataCollected/:id',
      name: 'manageapprovals',
      component: manageapprovals,
    },
    
    /* Start emails */
    {
      path: '/Home/Compose',
      name: 'compose',
      component: compose,
    },
	
    {
      path: '/Home/Inbox/:id',
      name: 'inbox',
      component: inbox,
    },
    {
      path: '/Inbox/emaildetails/:id',
      name: 'emaildetails',
      component: emaildetails,
    },
    {
      path: '/sent',
      name: 'sent',
      component: sent,
    },
    {
      path: '/trash',
      name: 'trash',
      component: trash,
    },
    {
      path: '/Emaildetails/Reply/:id',
      name: 'reply',
      component: reply,
    },
    /* Taxi Commuter VIP */
    {
      path: '/TaxiVIP/Dashboard',
      name: 'vipdashboard',
      component: vipdashboard,
    },
    {
      path: '/TaxiVIP/Commuters',
      name: 'managecommuters',
      component: managecommuters,
    },
    {
      path: '/TaxiVIP/Commuter/:id',
      name: 'commuterprofile',
      component: commuterprofile,
    },
    {
      path: '/TaxiVIP/VIPCards',
      name: 'ManageVIPCards',
      component: managevipcard,
    },
    {
      path: '/TaxiVIP/ManageVIPRoutes',
      name: 'manageviproutes',
      component: manageviproutes,
    },
    {
      path: '/SanraleTag/TopUpAccount',
      name: 'sanraltopupaccount',
      component: sanraltopupaccount,
    },
    {
      path: '/SanraleTag/Vehicles',
      name: 'sanralmanangevehicles',
      component: sanralmanangevehicles,
    },
    {
      path: '/SanraleTag/Commuters',
      name: 'sanralmanangecustomers',
      component: sanralmanangecustomers,
    },
    {
      path: '/BookASeat/PayOutDashboard',
      name: 'payoutdashboard',
      component: payoutdashboard,
    },
    {
      path: '/SanraleTag/Dashboard',
      name: 'sanraldashboard',
      component: sanraldashboard,
    },
    {
      path: '/SanraleTag/CommutersProfile/:id',
      name: 'sanralcommuterprofile',
      component: sanralcommuterprofile,
    },
    {
      path: '/CollectionManager/Dashboard',
      name: 'collectionmgr_dashboard',
      component: debtcollectorsmanager_dashboard,
    },
    {
      path: '/prepaidServices/Add',
      name: 'addmerchant',
      component: addmerchant,
    },

    {
      path: '/prepaidServices/merchants',
      name: 'merchants',
      component: merchants,
    },

    {
      path: '/prepaidServices/merchant/:id',
      name: 'merchantprofile',
      component: merchantprofile,
    },
    {
      path: '/prepaidServices/prepaidservicescashupdetails/:collectorid/:startdate/:enddate/:activesession',
      name: 'prepaidservicescashupdetails',
      component: prepaidservicescashupdetails,
    },
    {
      path: '/OnlineServices/RenewLicenseDisk',
      name: 'renewlicensedisk',
      component: renewlicensedisk,
    },
    {
      path: '/OnlineServices/RenewOperatorLicense',
      name: 'RenewOperatorLicense',
      component: renewoperatinglicense,
    },
    {
      path: '/OnlineServices/prepaidpetrolcard',
      name: 'prepaidpetrolcard',
      component: prepaidpetrolcard,
    },
    {
      path: '/OnlineServices/PrepaideTag',
      name: 'prepaidetag',
      component: prepaidetag,
    },
    {
      path: '/OnlineServices/ShortTermLoan',
      name: 'shorttermloan',
      component: shorttermloan,
    },
    {
      path: '/OnlineServices/CreditRepair',
      name: 'creditrepair',
      component: creditrepair,
    },
    {
      path: '/FleetManagment/AddClient/:id',
      name: 'addclient',
      component: addclient,
    },
    {
      path: '/FleetManagment/Arrears',
      name: 'clientwitharrears',
      component: clientwitharrears,
    },
    {
      path: '/FleetManagment/Bookings',
      name: 'bookings',
      component: bookings,
    },
    {
      path: '/FleetManagment/Dashboard',
      name: 'fleetmanagementdash',
      component: fleetmanagementdash,
    },
    {
      path: '/FleetManagment/ListOfClients',
      name: 'listofclients',
      component: listofclients,
    },
    {
      path: '/FleetManagment/Mandates',
      name: 'fleetmandates',
      component: fleetmandates,
    },
    {
      path: '/FleetManagment/ArrearsCentre',
      name: 'arrearscenter',
      component: arrearscenter,
    },
    {
      path: '/FleetManagment/Arrears/Transactions/:id',
      name: 'clientwitharrears_trans',
      component: clientwitharrears_trans,
    },
    {
      path: '/FleetManagment/Inventory',
      name: 'fleetinventory',
      component: fleetinventory,
    },
    {
      path: '/LoanApplication/New/:id',
      name: 'newloans',
      component: newloans,
    },
    {
      path: '/LoanApplication/Loans',
      name: 'loans',
      component: loans,
    },
    {
      path: '/LoanApplication/DashBoard',
      name: 'loandash',
      component: loandash,
    },
  ],
});

