<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'SearchUsers'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Full Search"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="BookASeat"
        breadcrumbitem3="Search Users"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-md-12">
          <!-- begin result-container -->
          <div class="result-container">
            <!-- begin input-group -->
            <div class="input-group input-group-lg m-b-20">
              <input
                type="text"
                v-on:keydown.enter.prevent="onSeachclick(context)"
                v-model="searchText"
                class="form-control input-white"
                placeholder="Enter keywords here..."
              />
              <div class="input-group-append">
                <button
                  type="button"
                  @click="onSeachclick(context)"
                  class="btn btn-primary"
                  :disabled="$wait.is('search')"
                >
                  <i class="fa fa-search fa-fw" v-show="!$wait.is('search')"></i>
                  <i
                    class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                    v-show="$wait.is('search')"
                  ></i>
                  Search
                </button>
              </div>
            </div>
            <!-- end input-group -->
          </div>
          <!-- end result-container -->

          <!-- begin nav-pills -->
          <ul class="nav nav-pills">
             <li class="nav-items">
              <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                <span class="d-sm-none">Commuters</span>
                <span class="d-sm-block d-none">Commuters <span v-if="COMMUTERSRESULTS != null" class="badge badge-success">{{COMMUTERSRESULTS.length}}</span></span>
              </a>
            </li>
            <li class="nav-items">
              <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">Operators</span>
                <span class="d-sm-block d-none">Operators <span v-if="OPERATORRESULTS != null" class="badge badge-success">{{OPERATORRESULTS.length}}</span></span>
              </a>
            </li>
            <li class="nav-items">
              <a href="#nav-pills-tab-3" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">Agents</span>
                <span class="d-sm-block d-none">Agents <span v-if="AGENTSRESULTS != null" class="badge badge-success">{{AGENTSRESULTS.length}}</span></span>
              </a>
            </li>
            <li class="nav-items">
              <a href="#nav-pills-tab-4" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">Drivers</span>
                <span class="d-sm-block d-none">Drivers <span v-if="DRIVERRESULTS != null" class="badge badge-success">{{DRIVERRESULTS.length}}</span></span>
              </a>
            </li>
            <li class="nav-items">
              <a href="#nav-pills-tab-4" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">Vehicles</span>
                <span class="d-sm-block d-none">Vehicles <span v-if="VEHICLERESULTS != null" class="badge badge-success">{{DRIVERRESULTS.length}}</span></span>
              </a>
            </li>
             <li class="nav-items">
              <a href="#nav-pills-tab-5" data-toggle="tab" class="nav-link">
                <span class="d-sm-none">Organisations</span>
                <span class="d-sm-block d-none">Organisations <span v-if="ORGANISATIONSRESULTS != null" class="badge badge-success">{{ORGANISATIONSRESULTS.length}}</span></span>
              </a>
            </li>
           
          </ul>
          <!-- end nav-pills -->
          <!-- begin tab-content -->
          <div class="tab-content">
             <!-- begin tab-pane -->
            <div class="tab-pane fade active show" id="nav-pills-tab-1">
              <table id="data-table-commuters" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">First</th>
                    <th class="text-nowrap">Surname</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Can Pay Later</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in COMMUTERSRESULTS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname}}</td>
                    <td>{{item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobile_number | HumanizeMobile}}</td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.canpaylater, 'label-secondary':!item.canpaylater  }"
                      >{{item.canpaylater == true ? 'Yes' : 'No' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="item.is_active" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Disable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.is_active" type="button" @click="EnableDisableAccount(context,item,false)" class="btn btn-success"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Enable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.canpaylater" type="button" @click="ShowNextOfKinDetails(context,item)" class="btn btn-warning">
                        Edit Kin
                    </button> 
                  </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
            <!-- begin tab-pane -->
            <div class="tab-pane fade" id="nav-pills-tab-2">
              <table id="data-table-operators" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">First</th>
                    <th class="text-nowrap">Surname</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Auto Payout</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in OPERATORRESULTS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname}}</td>
                    <td>{{item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobile_number | HumanizeMobile}}</td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.automaticpayout, 'label-secondary':!item.automaticpayout  }"
                        >{{item.automaticpayout == true ? 'YES' : 'No' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="item.is_active" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Disable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.is_active" type="button" @click="EnableDisableAccount(context,item,false)" class="btn btn-success"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Enable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.canpaylater" type="button" @click="ShowNextOfKinDetails(context,item)" class="btn btn-warning">
                        Edit Kin
                    </button> 
                  </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
            <!-- begin tab-pane -->
            <div class="tab-pane fade" id="nav-pills-tab-3">
              <table id="data-table-agents" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">First</th>
                    <th class="text-nowrap">Surname</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Auto Payout</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in AGENTSRESULTS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname}}</td>
                    <td>{{item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobile_number | HumanizeMobile}}</td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.automaticpayout, 'label-secondary':!item.automaticpayout  }"
                        >{{item.automaticpayout == true ? 'YES' : 'No' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="item.is_active" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Disable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.is_active" type="button" @click="EnableDisableAccount(context,item,false)" class="btn btn-success"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Enable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.canpaylater" type="button" @click="ShowNextOfKinDetails(context,item)" class="btn btn-warning">
                        Edit Kin
                    </button> 
                  </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
             <!-- begin tab-pane -->
            <div class="tab-pane fade" id="nav-pills-tab-4">
              <table id="data-table-drivers" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">First</th>
                    <th class="text-nowrap">Surname</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Auto Payout</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in DRIVERRESULTS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                  <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname}}</td>
                    <td>{{item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobile_number | HumanizeMobile}}</td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td width="10%">
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.automaticpayout, 'label-secondary':!item.automaticpayout  }"
                      >{{item.automaticpayout == true ? 'YES' : 'No' }}</span>
                    </td>
                    <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="item.is_active" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Disable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.is_active" type="button" @click="EnableDisableAccount(context,item,false)" class="btn btn-success"
                      :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)"
                    >
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Enable Account
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.canpaylater" type="button" @click="ShowNextOfKinDetails(context,item)" class="btn btn-warning">
                        Edit Kin
                    </button> 
                  </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
           
            <!-- begin tab-pane -->
            <div class="tab-pane fade" id="nav-pills-tab-5">
              <table id="data-table-organisations" class="table table-striped table-bordered width-full">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">First</th>
                    <th class="text-nowrap">Surname</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Available Balance</th>
                   
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in ORGANISATIONSRESULTS"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.firstname}}</td>
                    <td>{{item.surname}}</td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobile_number | HumanizeMobile}}</td>
                   <td>{{item.available_balance}}</td>
                   
                    <td  class="with-btn valign-middle" nowrap>
                      
                      <router-link
                        :to="{ name: 'tpaytpaycommuterprofile', params: { id: item.id }}"
                        class="btn btn-sm btn-white width-60 m-r-2"
                      >View</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end tab-pane -->
          </div>
          <!-- end tab-content -->
        </div>
        <!-- end col-12 -->
      </div>
      <!-- end row -->

      <!-- begin #Main Body Template -->

    

      <div class="modal fade" id="NextOfKinDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        
        <div class="modal-content">
          <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Emergency Details(e.g Wife, Brother)</h5>
        
      </div>
          <div class="modal-body p-4">


             <div class="row gx-2">
                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupFirstName" class="form-label fs-sm fw-bold">First Name</label>
                    <input type="text" required v-model="NEXTOFKIN.FirstName" placeholder="Enter First Name" class="form-control shadow-none" id="userSignupFirstName" />
                  </div>
                </div>
                <!-- /.col-12 -->

                <div class="col-12 col-sm-6">
                  <div class="mb-3">
                    <label for="userSignupLastName" class="form-label fs-sm fw-bold">Last Name</label>
                    <input type="text" required v-model="NEXTOFKIN.LastName" placeholder="Enter Last Name" class="form-control shadow-none" id="userSignupLastName" />
                  </div>
                </div>
                <!-- /.col-12 -->
              </div>
              <!-- /.row -->

              <div class="mb-3">
                <label for="userSignupEmail" class="form-label fs-sm fw-bold">Mobile No</label>
                <input type="number"  minlength="10" maxlength="10" required v-model="NEXTOFKIN.Mobile" placeholder="Enter Mobile No" class="form-control shadow-none" id="userSignupEmail" />
              </div>

               <div class="mb-3">
                <button type="submit" @click="UpdateNextOfKinAction(context)" class="btn btn-primary w-100 text-uppercase fw-bold"
                :disabled="this.$wait.is('UpdateNextOfKinAction')"
                >
                 <v-wait for="UpdateNextOfKinAction">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                     Submit
                  </v-wait>
                </button>
               
              </div>
                 <p class="text-muted">Note: Please provide us with details of your next of keen to be used in case of emergency </p>
                  

            <!-- /.text-center -->
          </div>
          <!-- /.modal-body -->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog modal-dialog-centered modal-sm -->
    </div>
    <!-- /.modal -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpay_systemuser");

export default {
  name: "FullSystemSearch",
  data: function () {
    return {
      SEARCHRESULTS: [],
      OPERATORRESULTS: [],
      DRIVERRESULTS: [],
      VEHICLERESULTS: [],
      TAXIRESULTS: [],
      AGENTSRESULTS: [],
      COMMUTERSRESULTS: [],
      ORGANISATIONSRESULTS: [],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,

      searchText: this.$router.app._route.query.text,
      dataloaded: false,
      NEXTOFKINMODEL:null,
      NEXTOFKIN:{
        FirstName:"",
        LastName:"",
        Mobile:"",
      },
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
  },
  beforeMount() {},
  methods: {
    ...mapActions([
      "searchOperator",
      "searchFullSystem",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted",
      "getLOGOURL",
      "UpdateNextOfKindAction",
      "EnableDisableAccountAction",
    ]),
    EnableDisableAccount: (context,item,_disable) => {

      item._disable = _disable;

      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
        
          value.context.$wait.start('EnableDisableAccount-'+ item.id)

          value.context.EnableDisableAccountAction({
            SystemUserId: value.item.id,
            SystemUserMobileNumber: value.item.mobile_number,
            Disable: value.item._disable,
            UpdateBy: value.context.TPAYPROFILE.SystemUsersId,
          }).then((rb) => {
            value.item.is_active = !_disable
            value.context.$wait.end('EnableDisableAccount-'+ item.id)
            if(rb.Status){
              value.context.$Notify.Success("Successfull","");
            $('#NextOfKinDetails').modal('hide');
            } else {
              value.context.$Notify.Error("Data Not Found", "");
            }
          
          })
        }
      });



},

    UpdateNextOfKinAction: (context) => {

      var item = context.NEXTOFKINMODEL

      context.$wait.start('UpdateNextOfKinAction')

      context
        .UpdateNextOfKindAction({
          SystemUserId: item.id,
          SystemUserMobileNumber: item.mobile_number,
          FirstName: context.NEXTOFKIN.FirstName,
          Surname: context.NEXTOFKIN.LastName,
          MobileNumber: context.NEXTOFKIN.Mobile,
        })
        .then((rb) => {
          context.$wait.end('UpdateNextOfKinAction')
          if(rb.Status){
            context.$Notify.Success("Successfull","");
          $('#NextOfKinDetails').modal('hide');
          } else {
            context.$Notify.Error("Data Not Found", "");
          }

         
        })
     


    },
    
    ShowNextOfKinDetails: (context,item) => {
      context.NEXTOFKINMODEL = item
      context.NEXTOFKIN.FirstName = item.kin_firstname
      context.NEXTOFKIN.LastName = item.kind_surname
      context.NEXTOFKIN.Mobile = item.kind_mobileno
      $('#NextOfKinDetails').modal('show');
    },
    
    onSeachclick: (context) => {
      if (context.searchText.length === 0) return;

      context.$wait.start("search");
      

      context
        .searchFullSystem({
          SearchText: context.searchText,
        })
        .then((rb) => {

          if(rb.Data == null){
            context.$Notify.Error("Data Not Found", "");
            return
          }

          
          
          
          console.log("System search results", rb.Data);

          context.OPERATORRESULTS = [];
          context.DRIVERRESULTS = [];
          context.AGENTSRESULTS = [];
          context.COMMUTERSRESULTS = [];
          context.ORGANISATIONSRESULTS = [];

          var commuters = [];
          var operators = [];
          var agents = [];
          var drivers = [];
          var organisations = [];

          rb.Data.forEach(item => {

        

            //Commuters
            if(item.code  === 'CMR'){
             

              commuters.push(item);
            }

            //Agent
            if(item.code  === 'AGT'){
              agents.push(item);
            }

            //Driver
            if(item.code  === 'DVR'){
              drivers.push(item);
            }

            //Operator
            if(item.code  === 'OPR'){
              operators.push(item);
            }

            //ORG
            if(item.code  === ''){
              organisations.push(item);
            }
            
          });

          


          context.OPERATORRESULTS = operators;
          context.DRIVERRESULTS = drivers;
          context.AGENTSRESULTS = agents;
          context.COMMUTERSRESULTS = commuters;
          context.ORGANISATIONSRESULTS = organisations;

          context.$wait.end("search");
        })
        .catch((error) => {
          console.log("System search errors: ", error);
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {

          //if(!context.dataloaded) {

          //   $("#data-table-operators").DataTable({
          //   responsive: true,
          // });

          // $("#data-table-taxis").DataTable({
          //   responsive: true,
          // });

          // $("#data-table-drivers").DataTable({
          //   responsive: true,
          // });

          context.dataloaded = true;
          
          //}

          

          context.$wait.end("search");
        });
    },
  },
  mounted: function () {
    //this.get(this.context);

    // if(this.$router.app._route.query.text.length > 0){
    //   this.onSeachclick(this.context);
    // }

    App.init();

    console.log(this.$router.app._route.query.text);

    //$('.selectpicker1').selectpicker('render');
  },
  beforeDestroy: function () {},
};
</script>

<style scoped>
</style>
