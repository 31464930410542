/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import configbase from '../../../configurations/configbasetpay';

export default {
  namespaced: true,
  state: {
    operator_vehicle: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    // GETUPLOADEDFILES: (state, getters, rootState, rootGetters) => {
    //   return rootGetters['fileupload/GETALL']
    // },

    // GETOPERATORS: (state, getters, rootState, rootGetters) => {
    //   return rootGetters['TO/GETOPERATORSNOPIC']
    // },
    // // GETTAXIVEHICLEEDIT: state => state.taxi_vehicles,
    // GETADDVEHICLESTATUS: state => state.vehicle_status,
    // GETADDVEHICLETRANSACTIONS: state => state.vehicle_transactions,
    // GETVEHICLEINVOICEDISPUTES: state => state.vehicle_invoiceDisputes,
    // GETVEHICLERECEIPTSDISPUTES: state => state.vehicle_receiptDisputes,
    // GETADDVEHICLETIMELINE: state => state.vehicle_timeline,
    GETADDVEHICLE: state => state.operator_vehicle,
    // GETVEHICLEBILLINGS: state => state.vehicle_billings,
    // GETFINANCEPROVIDERS: state => state.vehicle_financeprovider,
    // GETFINSURANCEPROVIDERS: state => state.vehicle_insuranceprovider,
    // GETTRACKERPROVIDERS: state => state.vehicle_trackerprovider,
    // GETVEHICLEROUTES: state => state.taxi_vehicle_routes,
    // GETVEHICLEBYASSID: state => state.operator_vehicles,
    // GETVEHICLESUPPORTINGDOCS: state => state.vehicle_docs,
    // GETVEHICLEMAKES: state => state.vehiclemakes,
    // GETVEHICLEMODELSBYID: state => state.vehiclemodels,
    // GETALLMODEL: (state, getters, rootState, rootGetters) => {
    //   return rootGetters['vehiclemodel/GETALLMODEL']
    // },
    // GETMODELBYMAKEID: (state, getters, rootState, rootGetters, id) => {

    //   return rootGetters['vehiclemodel/GETALLMODEL'];
    // },
    // GETVEHICLEBYAFFILLIATION: state => state.operator_vehiclesbyAff,
    // GETVEHICLEPICTURES: state => state.vehicle_pictures,
    // GETDATACOLLECTED: state => state.datacollectionbyAssId,
    // GETDATACOLLECTEDOPERATORIMAGE: state => state.datacollectionOperatorImage,

  },
  mutations: {
    LOADED: (state, _data) => {
      
      if (_data !== undefined) {
        state.operator_vehicle = _data;
      }
    },
    CLEARALL: (state) => {
      state.operator_vehicle = [];
    },
  },
  actions: {
    GetAllVehicles({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = configbase.ADMIN_URL("GetAllVehiclesById");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify({
              "taxiassociationid": payload.taxiassociationid,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllOperators({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = configbase.ADMIN_URL("GetAllOperators");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify({
              "taxiassociationid": payload.taxiassociationid,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    AddOperators({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = configbase.ADMIN_URL("GetAllOperators");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify({
              "taxiassociationid": payload.taxiassociationid,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllCommuters({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = configbase.ADMIN_URL("GetAllCommuters");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetAllDrivers({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = configbase.ADMIN_URL("GetAllDrivers");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("bookings");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    /* Async */
    IsMobileExists({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
      router
    }) {
      return new Promise((resolve, reject) => {


        let requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.OPERATORPROFILE_URL,
        };

        this._vm.$TPAY_API.add(requestheader,
          {
            SystemUserId:id,
          }
          ).then((parsedResultOP) => {
          if (parsedResultOP.Status) {
            resolve(parsedResultOP.Data[0]);
          }
        }).catch((error) => {
          reject(error);
        });
      })
    },
    /* Async */
    GetCommuterProfile({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
    }) {
      const url = configbase.ADMIN_URL("commuterprofile");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "SystemUserId": id,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetCommuterProfileByMobile({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      MobileNo,
    }) {
      const url = configbase.COMMUTER_URL("GetProfile");
        return new Promise((resolve, reject) => {
            fetch(`${url}?MobileNumber=${MobileNo}`, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetRanksByTaxiAss({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetAllRanksByTaxiAssId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              "taxiassociationid": id,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    GetRoutesByRankId({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      id,
    }) {
      const url = configbase.FIN_COMMUTER_URL("GetRoutesByRankId");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
             id:id.toString(),
            }),
          }).then(response => response.json()).then((parsedResult) => {
            
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
      },
      GetAvailableSeats({
        state,
        rootState,
        rootGetters,
        commit,
        dispatch,
        getters,
      }, {
        RankId,
        RouteId,
        BookingDate
      }) {
        const url = configbase.FIN_COMMUTER_URL("GetAvailableSeats");
          return new Promise((resolve, reject) => {
            fetch(url, {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                RankId: RankId.toString(),
                RouteId: RouteId.toString(),
                BookingDate: BookingDate.toString(),
              }),
            }).then(response => response.json()).then((parsedResult) => {
              resolve(parsedResult);
            }).catch((error) => {
              reject(error);
            }).finally(() => {
  
            });
          });
        },
        RegisterCommuterInternal({
          state,
          rootState,
          rootGetters,
          commit,
          dispatch,
          getters,
        }, {
          firstname,
          surname,
          mobilenumber,
          uid
        }) {
          const url = configbase.AUTH_URL("RegisterCommuterInternal");
            return new Promise((resolve, reject) => {
              fetch(url, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                  firstname: firstname.toString(),
                  surname: surname.toString(),
                  mobilenumber: mobilenumber.toString(),
                  uid: uid.toString(),
                }),
              }).then(response => response.json()).then((parsedResult) => {
                resolve(parsedResult);
              }).catch((error) => {
                reject(error);
              }).finally(() => {
    
              });
            });
          },
          TopUpAccount({
            state,
            rootState,
            rootGetters,
            commit,
            dispatch,
            getters,
          }, {
            SystemUserId,
            FullName,
            MobileNumber,
            Amount,
            AddedBy,
            TransactionSourceCode,
          }) {
            const url = configbase.FIN_TOPUPTRANS_URL("TopUp");
              return new Promise((resolve, reject) => {
                fetch(url, {
                  method: 'POST',
                  headers: new Headers({
                    'Content-Type': 'application/json',
                  }),
                  body: JSON.stringify({
                    SystemUserId,
                    FullName,
                    MobileNumber,
                    Amount,
                    AddedBy,
                    TransReference:"Submit Booking TAMS WEB",
                    TransactionSourceCode,
                    TransactionTypeCode:"TUP"
                  }),
                }).then(response => response.json()).then((parsedResult) => {
                  resolve(parsedResult);
                }).catch((error) => {
                  reject(error);
                }).finally(() => {
      
                });
              });
            },
        BookASeat({
          state,
          rootState,
          rootGetters,
          commit,
          dispatch,
          getters,
        }, {
          SystemUserId,
          MobileNumber,
          RankRoutesId,
          DepartureTimeId,
          BookSeatsSessionId,
          NoOfSeats,
          BookingDate,
          FareAmount,
          AddedBy,
          BookingStatusCode,
          SendNotification,
          GeoLocation,
          FrequencyCode,
          TransSourceCode,
        }) {
          const url = configbase.FIN_COMMUTER_URL("BookASeat");
            return new Promise((resolve, reject) => {
              fetch(url, {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                  "SystemUserId":SystemUserId,
                  "MobileNumber":MobileNumber,
                  "rank_routes_id":RankRoutesId,
                  "departuretime_id":DepartureTimeId,
                  "bookseats_sessionid":BookSeatsSessionId,
                  "noofseats":NoOfSeats,
                  "bookingdate":BookingDate,
                  "fare_amount":FareAmount,
                  "addedby":AddedBy,
                  "bookingstatus_code":BookingStatusCode,
                  "sendnotification":SendNotification,
                  "Geolocation":GeoLocation,
                  "frequencycode":FrequencyCode,
                  "transactionsourcesode":TransSourceCode,
                }),
              }).then(response => response.json()).then((parsedResult) => {
                resolve(parsedResult);
              }).catch((error) => {
                reject(error);
              }).finally(() => {
    
              });
            });
          },
  },
};


// ===
// Private helpers
// ===

function navigateTo(router, _userProfile) {
  if (_userProfile.is_system_admin) {
    router.push('sysadmin_dashboard');
  }
}