<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'New Booking'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="New Booking"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book-A-Seat"
        breadcrumbitem3="New Bookinge"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

    
        <!-- begin tab-content -->
        <div class="tab-content">

      <div class="tab-pane fade active show" id="nav-pills-tab-1">
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Booking Info
                      <small class="text-ellipsis"></small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Booking Complete</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->
                    <div class="row">
                      <div
          v-if="isMobileNoValid == undefined"
          class="alert alert-primary fade show"
        >WAITING TO VERIFY MOBILE NO</div>

                          <div class="card text-center">
        <div v-if="isMobileNoValid" class="alert alert-success fade show">COMMUTER FOUND</div>
      </div>

      <div class="card text-center">
        <div v-if="isMobileNoValid != undefined && !isMobileNoValid" class="alert alert-danger fade show">NEW COMMUTER</div>
      </div>

                      <div class="col-md-12">
                        

                        <div class="row">

                        <div class="col-lg-6">

                         

                          <legend
                          class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                        >Enter Customer Details</legend>
                        
                            <!-- end form-group -->
                            <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Mobile No
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input type="text" @keypress="isNumber($event)" required
                             minlength="10"
                            placeholder="Enter Mobile No" id="MobileNo" 
                            data-toggle="tooltip"
                             data-parsley-required="true"
                            :class="['form-control',{'is-invalid': isMobileNoExists , 'is-valid': !isMobileNoExists  }]"
                             v-model.lazy="MOBILENO"/>
                             <div class="invalid-tooltip">Mobile Exists.</div>
                             <div class="valid-tooltip">New Commuter!</div>
                             <i v-show="LOADINGMOBILENO" class="fas fa-spinner fa-spin"></i>
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            First Name
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                v-model="COMMUTERADD.firstname"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                        <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Surname
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                v-model="COMMUTERADD.surname"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                          <!-- end form-group -->

                          <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Available Balance
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                disabled="disabled"
                                class="form-control"
                                :value="availblebalance | newcurrencywithSymbol"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                        

                      </div>
                        <!-- end form-group -->

                        <div class="col-lg-6">

                          <legend
                          class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                        >Enter Booking Details</legend>

                           <!-- end form-group -->
                           <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           TopUp Type
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  v-model="ADDBOOKING.topuptype"
                                >
                                <option key="CH" value="CH">Cash</option>
                                <option key="POS" value="POS">POS</option>
                                </select>
                          </div>
                            </div>
                        <!-- end form-group -->

                         <!-- end form-group -->
                         <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Enter Booking Date
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                              <div
                                class="input-group date"
                                id="bookingdate"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                            </div>
                        <!-- end form-group -->

                         <!-- end form-group -->
                         <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Rank Name
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  v-model="SELECTEDRANK"
                                  :disabled="!ADDBOOKING.bookingdate"
                                >
                                <option key="0" value="0" selected>Select Rank</option>
                                  <option
                                  v-for="(item) in ASSOCIATIONRANKS"
                                  :key="item.id"
                                  :value="item.id"
                                  >{{item.name}}</option>
                                </select>
                                <i v-show="LOADINGRANKS" class="fas fa-spinner fa-spin"></i>
                          </div>
                            </div>
                        <!-- end form-group -->

                         <!-- end form-group -->
                         <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Destination
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  v-model="SELECTEDDESTINATION"
                                  :disabled="!SELECTEDRANK"
                                >
                                <option key="0" value="0" selected>Select Destination</option>
                                  <option
                                  v-for="(item) in ALLROUTES"
                                  :key="item.id"
                                  :value="item"
                                  >{{ item.Name }} - {{ item.Price | newcurrencywithSymbol }}</option>
                                </select>
                                <i v-show="LOADINGROUTES" class="fas fa-spinner fa-spin"></i>
                          </div>
                            </div>
                        <!-- end form-group -->

                         <!-- end form-group -->
                         <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                           Departure Time
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <select
                                  class="form-control"
                                  id="selecttamsops"
                                  data-parsley-required="true"
                                  data-parsley-group="step-1"
                                  v-model="SELECTEDDEPARTURETIME"
                                  :disabled="!SELECTEDDESTINATION"
                                >
                                <option key="0" value="0" selected>Select Departure Time</option>
                                  <option
                                  v-for="(item) in ALLDEPARTURETIMES"
                                  :key="item.id"
                                  :value="item"
                                  >{{item.departuretime_desc}} Seats({{ item.available_seats }})</option>
                                </select>
                                <i v-show="LOADINGDEPARTURES" class="fas fa-spinner fa-spin"></i>
                          </div>
                            </div>


                          <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Booking Fee
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="text"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                disabled="disabled"
                                class="form-control"
                                :value="Math.round(BOOKINGFEE) | newcurrencywithSymbol"
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                            <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            No Of Passangers({{ ADDBOOKING.noofpassangers }})
                            <span class="text-danger"></span>
                          </label>
                          <div class="col-md-6">
                            <input type="range" v-model="ADDBOOKING.noofpassangers" class="form-control form-range"  min="1" max="22" id="customRange" />
                          </div>
                        </div>
                        <!-- end form-group -->
                      </div>

                    </div>

                      </div>

                 
                    </div>
                    <!-- end row -->
                  </fieldset>
                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->
              

                <!-- begin step-3 -->
                <div id="step-3">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Route Registered Successfully</h2>
                    <p class="m-b-30 f-s-16 ">
                     
                    </p>
                    <p>
                      <a href="#" class="btn btn-primary btn-lg hide">Proceed to User Profile</a>
                    </p>
                  </div>
                </div>
                <!-- end step-3 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       

      </div>
        <!-- end tab-pane -->

      

      <!-- end row -->

  

      <!-- begin #Main Body Template -->

      

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpay_systemuser");

export default {
  name: "AddSystemUser",
  data: function() {
    return {
      phoneInput: {},
      isMobileNoExists: true,
      isMobileNoValid: undefined,
      MOBILENO:"",
      availblebalance: 0,
      COMMUTERPROFILE: {},
      COMMUTERADD: {
        firstname: "",
        surname: "",
        mobilenumber: "",
        uid: this.$uuid.v4(),
      },
      ADDBOOKING: {
        noofpassangers: 1,
        bookingdate: "",
        topuptype: "CH",
        selectrank: 0,
        selectdestination: 0,
        selectdeparturetime: 0,
      },
      SELECTEDRANK: null,
      SELECTEDDESTINATION: null,
      SELECTEDDEPARTURETIME: null,
      BOOKINGFEE:0,
      BOOKINGPRICE:0,
      SELECTEDBOOKINGDATE: null,
      ASSOCIATIONRANKS:[],
      ALLROUTES: [],
      ALLDEPARTURETIMES:[],
      sMobileNoExists: true,
      LOADINGMOBILENO: false,
      BANKS:[],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED:false,
      GETDRIVERSBYASSID:[],
      GETALL:[],
      ASSOCIATIONROUTES:[],
      OPT:"",
      USERNAME: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      SELECTEDTAMSRANK: null,
      TAXIFARE: 0,
      LOADINGDEPARTURES: false,
      LOADINGROUTES: false,
      LOADINGRANKS: false,
      ADDSYSTEMUSER: {
        taxi_association_id:this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        rank_id:0,
        tams_route_id:0,
        rank_name: "",
        route_name : "",
        fareprice : 0,
        added_by: this.$store.getters["auth/TPAYPROFILE"].SystemUsersId,
        city_id:0,
        chargeinsurance: false,
        insurancecost: 0,
        bookingfee:0,
    },
    VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },

    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"])
  },
  watch: {
    MOBILENO: function (val) {
        if (val.toString().length >= 10) {
          this.LOADINGMOBILENO = true;
          const phoneNumber = this.phoneInput.getNumber().replace("+", "");
            this.isMobileValid = true;
            this.context.$store
              .dispatch("tpaycommuter/GetCommuterProfileByMobile", {
                  MobileNo: phoneNumber
              })
              .then((rb) => {
                debugger;
                this.LOADINGMOBILENO = false;
                //COMMUTERPROFILE
                const context = $(document).data("context");
                if(!rb.Status){
                  context.isMobileNoValid = false;
                  context.isMobileNoExists = true;
                  context.COMMUTERADD.mobilenumber = phoneNumber;
                  context.COMMUTERADD.firstname = "";
                  context.COMMUTERADD.surname = "";
                  context.availblebalance = 0;
                  context.COMMUTERPROFILE = null;
                } else {
                  
                  context.isMobileNoValid = true;
                  context.COMMUTERPROFILE = rb.Data;
                  context.COMMUTERADD.mobilenumber = phoneNumber;
                  context.COMMUTERADD.firstname = rb.Data.firstname;
                  context.COMMUTERADD.surname = rb.Data.surname;
                  context.availblebalance = rb.Data.account.available_balance;
                  context.isMobileNoExists = false;
                }
              });
        }
    },
    SELECTEDRANK: function (val) {
      debugger
      this.LOADINGROUTES = true;
      this.$store
      .dispatch("tpaycommuter/GetRoutesByRankId", {
          id: val,
        })
        .then((data) => {
          
          this.ALLROUTES = data.Data;
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
          this.LOADINGROUTES = false;
        })
    },
    SELECTEDDESTINATION: function (item) {
      debugger;
      this.LOADINGDEPARTURES = true;
      this.$store
      .dispatch("tpaycommuter/GetAvailableSeats", {
          RankId: item.FromRankID,
          RouteId: item.Id,
          BookingDate: this.ADDBOOKING.bookingdate
        })
        .then((data) => {
          debugger
          if(data.Data == null){
            this.$Notify.Error("No Departure Time Available for " + this.ADDBOOKING.bookingdate, "");
            return;
          }
          this.ALLDEPARTURETIMES = data.Data;
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
          this.LOADINGDEPARTURES = false;
        })
    },
    SELECTEDDEPARTURETIME: function (val) {
      const context = $(document).data("context");
      debugger
      var TotalFare = context.SELECTEDDESTINATION.Price * context.ADDBOOKING.noofpassangers;
        var TotalFee = ((TotalFare * context.ADDBOOKING.noofpassangers) / 100);
        var TotalFareAmount = Math.round(TotalFare + TotalFee);

        context.BOOKINGFEE = TotalFee;

        $(".sw-btn-next").text("Pay R" + TotalFareAmount);
    },
    
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    init: file => {},
    trimNumber: function(number) {
      return number.replace('+',"").trim();
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    GetRoutesByRankId: (context,id) => {
      context.LOADINGRANKS = true;
      context.$store
        .dispatch("tpaycommuter/GetRoutesByRankId", {
          id
        })
        .then((rb) => {

          context.GETALL = rb.Data
          
        })
        .finally((error)=>{
          context.LOADINGRANKS = false;
        })
    },
    getbyAssId: context => {
      context.LOADINGRANKS = true;
      context.$store
      .dispatch("tpaycommuter/GetRanksByTaxiAss", {
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data.Data;
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
          context.LOADINGRANKS = false;
        })
    },
    getRoutes: context => {
      context.$store
      .dispatch("TA/getTaxiAssRoutes", {
        router: context.$router,
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONROUTES = data;
          
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           $('#data-table-default').DataTable({
			responsive: true
		});
        })
    },
    BookASeat: (context,{
          SystemUserId,
          MobileNumber,
          RankRoutesId,
          DepartureTimeId,
          BookSeatsSessionId,
          NoOfSeats,
          BookingDate,
          FareAmount,
          AddedBy,
          BookingStatusCode,
          SendNotification,
          GeoLocation,
          FrequencyCode,
          TransSourceCode,
        }) => {
      context.$store
      .dispatch("tpaycommuter/BookASeat", {
          SystemUserId,
          MobileNumber,
          RankRoutesId,
          DepartureTimeId,
          BookSeatsSessionId,
          NoOfSeats,
          BookingDate,
          FareAmount,
          AddedBy,
          BookingStatusCode,
          SendNotification,
          GeoLocation,
          FrequencyCode,
          TransSourceCode,
        })
        .then((data) => {
          context.BOOKING = data.Data;
        }).catch((error)=>{
          console.log("these are the getter errors:",error);
        })
        .finally((error)=>{
           
        })
    },
  },
  mounted: function() {
    //this.get(this.context);
    $(document).data("context", this);

    App.init();

    $('[data-toggle="tooltip"]').tooltip();

 

    /*

    if(tPAYCommuterProfile.getAccount().getAvailableBalance() < bookASeatRequest.getFareAmount()){

            JSONObject loginBlocksjson = new JSONObject();
            loginBlocksjson.put("SystemUserId", topUpBarCode.getSystemUserId());
            loginBlocksjson.put("FullName", topUpBarCode.getFullName());
            loginBlocksjson.put("MobileNumber", topUpBarCode.getMobileNumber());
            loginBlocksjson.put("Amount", bookASeatRequest.getFareAmount());
            loginBlocksjson.put("AddedBy", bookASeatRequest.getAddedby());
            loginBlocksjson.put("TransReference", "Submit Booking Android PDA");
            loginBlocksjson.put("TransactionSourceCode",  bookASeatRequest.getTransactionsourcesode());
            loginBlocksjson.put("TransactionTypeCode", "TUP");
            loginBlocksjson.put("GeoLocation", bookASeatRequest.getGeolocation());

            RequestBody body = RequestBody.create(loginBlocksjson.toString(), JSON);

                response = tpayFINService.TopUp(body).execute();

            }

    */
    

    const phoneInputField = document.querySelector("#MobileNo");
   const phoneInput = window.intlTelInput(phoneInputField, {
    initialCountry: "auto",
    geoIpLookup: getIp,
     utilsScript:
       "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
   });

    this.phoneInput = phoneInput;

    var date = new Date();
    date.setDate(date.getDate() - 43000);
    console.log(date);

    $("#bookingdate").datepicker({
      todayHighlight: true,
      autoclose: true,
      startDate: date,
    }).on('changeDate', function(e) {
        // `e` here contains the extra attributes
        // console.log(e.date);
        debugger
        const context = $(document).data("context");
        context.ADDBOOKING.bookingdate = context.$Toolkit.ConvertToDebitDateDateD(
          e.date
          );
        context.SELECTEDBOOKINGDATE = context.$Toolkit.ConvertToDebitDateDateD(
          e.date
        );
    });

    this.getbyAssId(this.context);

    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "slide",
      transitionSpeed: 400,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function (
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      if (currentstep === 1 && stepDirection == "forward") {
        // context.hacksmartwizard = false;
        // $(".sw-btn-next").text("Next");
      }

      return res;
    });

    // $(".sw-btn-next")
    //         .attr("disabled", "disabled");

            // $(".sw-btn-next")
            // .text("")
            // .append('<i class="fas fa-spinner fa-spin"></i>')
            // .attr("disabled", "disabled");

    $("#wizard").data("context", this.context);

    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {

      

      (async () => {

        const context = $("#wizard").data("context");

        var customer;
      var SystemUserId;
      var FullName;
      var MobileNumber;
      var AddedBy  = context.$store.getters["auth/TPAYPROFILE"].SystemUsersId;
      
     
      
      const currentstep = stepNumber + 1;
      var res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

        debugger;

        if (currentstep === 2 && stepDirection == "backward") {
       
                context.hacksmartwizard = false;
                 $(".sw-btn-next").text("Next") 
        }


        if(context.hacksmartwizard)
        return true;


      //$(".sw-btn-next").text("Next");

      if (currentstep === 1 && stepDirection == "forward") {
        
        if (!res){
          return res;
        }

        $(".sw-btn-next")
        .text("")
        .append('<i class="fas fa-spinner fa-spin"></i>')
        .attr("disabled", "disabled");

        var TotalFare = context.SELECTEDDESTINATION.Price * context.ADDBOOKING.noofpassangers;
        var TotalFee = ((TotalFare * context.ADDBOOKING.noofpassangers) / 100);
        var TotalFareAmount = Math.round(TotalFare + TotalFee);

        if(context.COMMUTERPROFILE == null){
          $(".sw-btn-next").text("Creating Commuter...");

          const data = await context.$store.dispatch("tpaycommuter/RegisterCommuterInternal", context.COMMUTERADD);
          if(!data.Status){
            $(".sw-btn-next")
          .text("Next")
          .removeAttr("disabled", "disabled");
                context.$Notify.Error(data.Message);
                return;
          }

          SystemUserId = data.Data.SystemUsersId;
          FullName  = data.Data.first_name + " " + data.Data.lastname;
          MobileNumber = data.Data.mobile_number;

          } else {
            SystemUserId = context.COMMUTERPROFILE.systemuserid;
            FullName  = context.COMMUTERPROFILE.firstname + " " + context.COMMUTERPROFILE.surname;
            MobileNumber = context.COMMUTERPROFILE.mobilenumber;
          }

          if(context.availblebalance < TotalFareAmount){
         

            const TopRequest = {
              SystemUserId,
              FullName,
              MobileNumber,
              "Amount": TotalFareAmount,
              AddedBy,
              "TransactionSourceCode":  context.ADDBOOKING.topuptype,
            }
            const data = await context.$store.dispatch("tpaycommuter/TopUpAccount", TopRequest);
            if(!data.Status){
            $(".sw-btn-next")
          .text("Next")
          .removeAttr("disabled", "disabled");
                context.$Notify.Error(data.Message);
                return;
          }
          }

          const bookaseatrequest = {
              SystemUserId: SystemUserId,
              MobileNumber: MobileNumber,
              RankRoutesId: context.SELECTEDDESTINATION.RankRouteId,
              DepartureTimeId: context.SELECTEDDEPARTURETIME.departuretime_id,
              BookSeatsSessionId: context.SELECTEDDEPARTURETIME.bookingseats_sessionid,
              NoOfSeats: context.ADDBOOKING.noofpassangers,
              BookingDate: context.SELECTEDBOOKINGDATE,
              FareAmount: TotalFareAmount,
              AddedBy: AddedBy,
              BookingStatusCode: "ACT",
              SendNotification: true,
              GeoLocation: null,
              FrequencyCode: "ONF",
              TransSourceCode: context.ADDBOOKING.topuptype,
            }

            $(".sw-btn-next").text("Submting Booking...");
          const data = await context.$store.dispatch("tpaycommuter/BookASeat", bookaseatrequest);
          
          if(!data.Status){
            context.$Notify.Error(data.Message);
            $(".sw-btn-next")
          .text("Done")
          .removeAttr("disabled", "disabled");
                return;
          } else {
            context.$Notify.Success("Booking Successfull","New Balance Is R" + data.Data[0].AvailableBalance);
          }

          context.MOBILENO = "";
          context.COMMUTERADD.mobilenumber = "";
          context.COMMUTERADD.firstname = "";
          context.COMMUTERADD.surname = "";
          context.availblebalance = 0;
          context.COMMUTERPROFILE = null;
          
      
          $(".sw-btn-next")
          .text("Next")
          .removeAttr("disabled", "disabled");

          
      }

      return res;

    })();
    
    return false;
    });

  }
};
</script>

<style scoped>
</style>

