<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'VehicleList'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="All Vehicle"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book A Seat"
        breadcrumbitem3="Taxis"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Operators</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
             
            </div>

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Operator</th>
                    <th class="text-nowrap">License Plate</th>
                    <th class="text-nowrap">Model</th>
                    <th class="text-nowrap">Total Taxi Fares</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYASSID"
                    v-bind:key="item.tams_vehicle_id"
                    :class="{
                    'success': item.id,
                    }"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.o_firstname}} {{item.o_surname}}</td>
                    <td>
                      <router-link
                        v-if="item.tams_vehicle_id"
                        :to="{ name: 'taxivehicleprofile', params: { id: item.tams_vehicle_id }}"
                      >{{item.licence_number}}</router-link>
                      </td>
                    <td>{{item.make}} {{item.model}}</td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-info width-60 m-r-2"
                        v-if="!item.id"
                        @click="AddVehicle(context,item,USERID)"
                        :disabled="$wait.is('AddVehicle-' + item.tams_vehicle_id)"
                      >
                        <v-wait v-bind:for="'AddVehicle-' + item.tams_vehicle_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Add
                        </v-wait>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpaycommuter");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
    };
  },
  beforeMount: function () {
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "GetAllOperators",
      "GetAllVehicles",
    ]),

    getbyid: (context) => {

      const router = context.$router;

          var payload = {
            taxiassociationid: context.taxiassociationid
          };

          
      context.GetAllVehicles({ router, payload })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETOPERATORSBYASSID = data;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          // var options = {
          //           dom: "lBfrtip",
          //           buttons: [
          //             { extend: "copy", className: "btn-sm" },
          //             { extend: "csv", className: "btn-sm" },
          //             { extend: "excel", className: "btn-sm" },
          //             { extend: "pdf", className: "btn-sm" },
          //             { extend: "print", className: "btn-sm" }
          //           ],
          //           responsive: false,
          //           autoFill: true,
          //           colReorder: true,
          //           keys: true,
          //           rowReorder: true,
          //           select: true
          //         };

          if(!context.dataLoaded){
              context.dataLoaded = true;
              var options = {
                      responsive: false,
                      autoFill: false,
                      colReorder: false,
                      keys: false,
                      rowReorder: false,
                      select: false
                    };


            $("#data-table-default").DataTable(options);
          }
        });
    },
    AddVehicle: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, {
          context,
          item,
          restoredby
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("AddVehicle-" + value.item.tams_vehicle_id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("tpay_systemuser/RegisterVehicle", {
                  tams_association_id: value.context.taxiassociationid,
                  tams_operator_id: value.item.tams_operators_id,
                  tams_vehicle_id: value.item.tams_vehicle_id,
                  LicenceNumber: value.item.licence_number
              })
              .then(() => {
                value.context.$Notify.Success("Successfull!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.getbyid(value.context, value.context.taxiassociationid);
                value.context.$wait.end("AddVehicle-" + value.item.tams_vehicle_id);
              });
          }
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {
    this.getbyid(this.context, this.taxiassociationid);
    App.init();

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

