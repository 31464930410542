<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'PayOutDashboard'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="BookASeat - Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="BookASeat"
          breadcrumbitem3="PayOut Dashboard"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total PayOut</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalpayouts | newcurrencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Month's PayOut</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalpayoutsmonth | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Week's PayOut</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalpayoutsweek | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Today's PayOut</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalpayoutstoday | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Taxi Fares</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltaxifares | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Month's Taxi Fares</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltaxifaremonth | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Week's Taxi Fares</div>
                <div class="stats-number"> {{GETDASHBOARDCARDS.totaltaxifareweek | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Today's Taxi Fares</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totaltaxifaretoday | currencywithSymbol}}</div>

                <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
  
        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Payout Transactions</h4>
              </div>
              <div class="panel-body">
                <div v-show="ISLOADINGPAYOUTS" class="spinner-border spinner-border-lg text-info"  role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
               <table class="table table-striped table-bordered payouts" id="payouts">
                <thead>
                  <tr>
                    <th></th>
                    <th>PAYOUT DATE</th>
                    <th>SETTLEMENT DATE</th>
                    <th>FULL NAME</th>
                    <th>BANK NAME</th>
                    <th>ACCOUNT</th>
                    <th>FREQUENCY</th>
                    <th>AMOUNT</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,index) in PAYOUTS" :key="item.id" :value="item"
                  :class="{
                    
                    }"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.payout_date}} </td>
                    <td>{{item.payout_settlement_date}} </td>
                    <td>{{item.firstname}} {{item.surname}} </td>
                    <td>{{item.bank_name}} </td>
                    <td>{{item.account_number}} </td>
                    <td>{{item.payout_frequency}} </td>
                    <td>{{item.balance}} </td>
                    <td>{{item.payout_status}} </td>
                    
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <!-- end panel -->

                   <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Bookings
               
                </h4>
              </div>
              <div class="panel-body">
                <div v-show="ISLOADINGPBOOKINGS" class="spinner-border spinner-border-lg text-info"  role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
               <table class="table table-striped table-bordered bookingsslots" id="bookingsslots">
                <thead>
                  <tr>
                    <th></th>
                    <th>DATE</th>
                    <th>SEATS</th>
                    <th>SLOTS</th>
                    <th>WAITLIST</th>
                    <th>DESTINATION</th>
                    <th>CHECKINS</th>
                    <th>VEHICLE</th>
                    <th>TAXI FARE</th>
                    <th>ASSIGN DATE</th>
                    <th>CHECKOUT DATE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                   v-for="(item,index) in ALLBOOKING" :key="item.id" :value="item"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td>{{item.booking_date}} {{item.booking_time}}</td>
                    <td>{{item.booked_seats}}/{{item.available_seats+item.booked_seats}}</td>
                    <td>{{item.slotnumber}}/{{item.max_slotnumber}}</td>
                    <td class="valign-middle">{{item.waitinglist}}</td>
                    <td>
                      <router-link
                      :to="{ name: 'bookingsbysession', params: {id: item.id,desc: item.from_route_name + ' @ ' + item.booking_date + ' ' + item.booking_time } }"
                    > {{item.from_route_name}}
                  </router-link>
                    </td>
                    <td class="valign-middle">{{item.totalcheckins}}</td>
                    <td>
                      {{item.regnum}}
                    </td>
                    <td>{{item.totalfare | newcurrencywithSymbol }}</td>
                    <td>{{item.assignedby_date}}</td>
                    <td>{{item.timedeparted}}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->
      

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";


import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payout_dashboard");

export default {
  name: "DASHPAYOUT",
  data: function() {
    return {
      ALLBOOKING:[],
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      TAXIABBR: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].abbr,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETALLTRANSACTIONS: [],
      GETALLTAXIASSREVENUE: [],
      GETALLTAXIASSDEPOSITS: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      DATALOADED: false,
      ISLOADINGPAYOUTS: true,
      PAYOUTS:[],
      ISLOADINGPBOOKINGS: true,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
  },
  methods: {
    ...mapActions(["getallbookingslots","getdashboardcards","getallpayouts"]),
  },
  mounted: function() {
    $(document).data("context", this);
    App.init();

   

    const router = this.$router;

    var payload = {
      taxiassociationid: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload })
    .then((parsedResultOP) => {
        //const context = $(document).data("context");

        this.GETDASHBOARDCARDS = parsedResultOP;

      
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      this.ISLOADINGPAYOUTS = true;

      this.getallpayouts({ router, payload })
      .then((parsedResultOP) => {
        //const context = $(document).data("context");

        this.PAYOUTS = parsedResultOP;

      
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {

        this.ISLOADINGPAYOUTS = false;
      
      var options = {
              responsive: false,
              autoFill: false,
              colReorder: false,
              keys: false,
              rowReorder: false,
              select: false,
              order: [[0, 'asc']]
            };
            
          $(".payouts").DataTable(options);
            
    });

var payload1 = {
  taxiassociation_id: this.taxiassociationid,
  startdate:'2001/01/01',
  enddate:'2099/01/01',
  rankrouteid:null,
};

      this.ISLOADINGPBOOKINGS = true;


    this.getallbookingslots({ router, payload: payload1 })
    .then((parsedResultOP) => {
        //const context = $(document).data("context");

        this.ISLOADINGPBOOKINGS = false;

        this.ALLBOOKING = parsedResultOP;

      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
          
          var options = {
              responsive: false,
              autoFill: false,
              colReorder: false,
              keys: false,
              rowReorder: false,
              select: false,
              order: [[0, 'asc']]
            };
            
            //if(!this.DATALOADED){
              //this.DATALOADED =  true;
              $(".bookingsslots").DataTable(options);
            //}
        });

      
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>