<template>
  <!-- begin invoice -->
  <div class="invoice">
    <!-- begin invoice-Logo -->
    <span class="pull-right">
      <img :src="encodedImage" class="width-160 height-40" />
    </span>
    <!-- begin invoice-Logo -->
    <!-- begin invoice-company -->
    <div class="invoice-company text-inverse f-w-600">
      <span class="pull-right hidden-print">
        <a
          href="javascript:;"
          @click="$router.back()"
          class="btn btn-lg btn-primary m-r-20 m-b-10 p-l-5"
        >
          <i class="fa fa-arrow-left t-plus-1 text-white fa-fw fa-lg"></i> Back
        </a>
        <a
          href="javascript:;"
          :disabled="$wait.is('generatestatement')"
          @click="print(context)"
          :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('generatestatement'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('generatestatement')
            }"
        >
          <i
            class="fa fa-history t-plus-1 text-white fa-fw fa-lg"
            v-show="!$wait.is('generatestatement')"
          ></i>

          <i
            class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
            v-show="$wait.is('generatestatement')"
          ></i>

          <span>Print</span>
        </a>
        <a
          href="javascript:;"
          v-if="statementGenerated"
          @click="print(context)"
          class="btn btn-lg btn-white m-r-20 m-b-10 p-l-5"
        >
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print
        </a>
      </span>
      Passanger List - {{GENERATEDSTATEMENT.distination}} @ {{ GENERATEDSTATEMENT.bookingdate }}
    </div>

      <div v-if="!dataloaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

    <!-- end invoice-company -->
    <!-- begin invoice-header -->
    <div class="invoice-header">
      <div class="invoice-from" :class="{showduringprintCss: showDuringPrint}">
        <strong>{{SELECTEDTAXIASSOCIATION._abbriviated_name}}</strong>
        <address class="m-t-5 m-b-5">
          <span class="text-inverse">{{SELECTEDTAXIASSOCIATION._name}}.</span>
          <br />
          {{SELECTEDTAXIASSOCIATION._physicaladdress}}
          <br />
          {{SELECTEDTAXIASSOCIATION.physical_suburb}},
          {{SELECTEDTAXIASSOCIATION.physical_suburb_postalcode}}
          <br />
          Phone: {{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
          <br />
          Cel: {{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}}
        </address>
      </div>
      <div class="invoice-to" :class="{showduringprintCss: showDuringPrint}">
        <strong>Trip Details</strong>
        <address class="m-t-5 m-b-5">
          From: <span class="text-inverse">{{GENERATEDSTATEMENT.origin}}</span>
          <br />
          To:<span class="text-inverse"> {{GENERATEDSTATEMENT.distination}}</span>
          <br />
          Passangers: <span class="text-inverse">{{GENERATEDSTATEMENT.booked_seats}}</span>
          <br />
          Departure Date: <span class="text-inverse">{{GENERATEDSTATEMENT.bookingtimedate}}</span>
          <br />
        </address>
      </div>
      <div class="invoice-to" :class="{showduringprintCss: showDuringPrint}">
        <strong>Owner/Driver Details</strong>
        <address class="m-t-5 m-b-5">
          Operator Name: <span class="text-inverse">{{GENERATEDSTATEMENT.operatorname}}</span>
          <br />
          Operator Mobile: <span class="text-inverse">{{GENERATEDSTATEMENT.operatornumber}}</span>
          <br />
          <br />
          Driver Name: <span class="text-inverse">{{GENERATEDSTATEMENT.drivername}}</span>
          <br />
          Driver Mobile: <span class="text-inverse">{{GENERATEDSTATEMENT.drivernumber}}</span>
          <br />
        </address>
      </div>

      <div class="row hidden-print">
        <div class="col-lg-6">
          <!-- begin card -->
          <div class="card text-center">
            <div class="card-header">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#card-tab-1">Trip Details</a>
                </li>
              </ul>
            </div>
            <div class="card-block">
              <div class="tab-content p-0 m-0">
                <div
                  class="tab-pane fade active show"
                  id="card-tab-1"
                >
                  <div class="invoice-to">
                    <div class="row">
                <!-- begin col-6 -->

                <div class="col-lg-6">
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Origin</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.origin"
                      />
                    </div>
                  </div>

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Destination</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.distination"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Passangers</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.booked_seats"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Departure Date</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.bookingtimedate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                   
 
                </div>

                <div class="col-lg-6">
                     <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Owner Name</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.operatorname"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->

                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Owner Mobile No</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.operatornumber"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Driver Name</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.drivername"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                     <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Driver Mobile No</label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="GENERATEDSTATEMENT.drivernumber"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
 
                </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->
        </div>
        <div class="col-lg-6">
          <div class="invoice-date">
            <small></small>
            
            <div class="invoice-detail">
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end invoice-header -->
    <!-- begin invoice-content -->
    <div class="invoice-content">
      <!-- begin table-responsive -->
      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th width="10%">Booking No</th>
              <th width="10%">Commuter</th>
              <th width="10%">Mobile No</th>
              <th width="10%">Taxi Fare</th>
              <th width="10%">Emergency Contact Name</th>
              <th width="10%">Emergency Contact No</th>
              
              <!-- <th class="text-center" width="10%">CHARGE</th>
              <th class="text-center" width="10%">PAYMENT</th>
              <th class="text-right" width="15%">BALANCE</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in GENERATEDSTATEMENT.bookings" v-bind:key="item.id">
              <td class="text-left">
                {{item.bookingnumber}}
              </td>
              <td class="text-left">
                {{item.fullname}}
              </td>
              <td class="text-left">
                {{item.mobile_number}}
              </td>
              <td class="text-left">
                {{item.taxifareprice | newcurrencywithSymbol}}
              </td>
              <td class="text-left">
                {{item.emergencyfullname}}
              </td>
              <td class="text-left">
                {{item.emergencynumber}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end table-responsive -->
      <!-- begin invoice-price -->
      <div class="invoice-price">
        <div class="invoice-price-left">
          <div class="invoice-price-row">
            <div class="sub-price">
              <small></small>
              <span class="text-inverse"></span>
            </div>

            <div class="sub-price">
              <small></small>
              <span class="text-inverse"></span>
            </div>
          </div>
        </div>
        <div class="invoice-price-right" style="white-space: pre">
          <small class>
            <b>TOTAL FARE</b>
          </small>
          <span
            class="f-w-600"
          >{{ TOTALTAXIFAREBAL | newcurrencywithSymbol  }}</span>
        </div>
      </div>
      <!-- end invoice-price -->
    </div>
    <!-- end invoice-content -->
    <!-- begin invoice-note -->
    <div class="invoice-note">
      * Passanger List From {{SELECTEDTAXIASSOCIATION._name}}
      <br />* Contact {{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}} For More Information
      <br />
    </div>
    <!-- end invoice-note -->
    <!-- begin invoice-footer -->
    <div class="invoice-footer">
      <p class="text-center m-b-5 f-w-600">THANK YOU FOR YOUR BUSINESS</p>
      <p class="text-center">
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          T:{{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
        </span>
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          M:{{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}}
        </span>
      </p>
    </div>
    <!-- end invoice-footer -->
  </div>
  <!-- end invoice -->
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpaybookings_dashboard");

export default {
  name: "StatementOfAccount",
  data: function() {
    return {
      context: this,
      id: this.$route.params.id,
      GETALLTAXIS: [],
      GETALLOPERATORS: this.$store.getters["TO/GETOPERATORSBYACCOUNTNO"],
      GETAllTAXIDRIVERS: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSIDNOPIC"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      dataloaded: true,
      showDuringPrint: true,
      statementGenerated: false,
      encodedImage: undefined,
      startDate: "",
      endDate: "",
      statement: {
        name: "",
        name2: "",
        address: "",
        suburb_name: "",
        postalcode: "",
        mobilenumer: ""
      },
      statementInfo: [],
      GENERATEDSTATEMENT: {
        origin: "",
        distination: "",
        booked_seats: "",
        bookingtimedate: "",
        operatorname: "",
        operatornumber: "",
        drivername: "",
        drivernumber: "",
        bookings: []
      },
    };
  },
  beforeCreate: function() {},
  beforeMount: function() {
    this.getAllByTaxiAssId(this.context, this.id);
  },
  components: {},

  computed: {
    ...mapGetters([""]),
    TOTALTAXIFAREBAL: function(){
      let sum = 0;
      this.GENERATEDSTATEMENT.bookings.forEach(function(item) {
        if(item.taxifareprice)
         sum += (parseFloat(item.taxifareprice));
      });

     return sum;
   },
  },
  watch: {},
  methods: {
    ...mapActions([
      "getallpassangerlist",
    ]),
    print: context => {
      var randomnumber = Math.floor(Math.random() * 100 + 1);

      context.showDuringPrint = false;
      document.title = randomnumber;
      window.print();
      context.showDuringPrint = true;
      document.title = "PASSANGER LIST";
    },
    getAllByTaxiAssId: (context, id) => {
      context.dataloaded = false;
      context.$store
        .dispatch("tpaybookings_dashboard/getallpassangerlist", {
          router: context.$router,
          payload : {
            sessionid: id,
          }
        })
        .then((response) => {
          debugger;

          if(response.length > 0){
            context.GENERATEDSTATEMENT = response[0];
          }
        })
        .catch(error => {
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {
            context.dataloaded = true;
        });
    },
  },
  mounted: function() {
    App.init();


    this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {
      this.context.encodedImage = result;
    }).catch((err) => {
    });
  }
};
</script>

<style scoped>
.showduringprintCss {
  display: none;
}
</style>

