/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';

import {
  updateField
} from 'vuex-map-fields'; /* https://github.com/maoberlehner/vuex-map-fields#namespaced-vuex-modules */
import navigate from '../../Service/Navigate';

export default {
  namespaced: true,
  state: {
    loginmodel: {
      isfailedlogin: false,
      errorMessage: '',
    },
    webpushtoken: undefined,
    userprofile: {},
    userroles: [],
    loginprogress: {
      PROGRESS: 0,
      MESSAGE: '',
    },
    internalsystemuser: false,
    selected_taxi_association: undefined,
    istpayagent: false,
    tpaytoken: '',
    tpayprofile: '',
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    SHOWPROGRESS: state => state.loginprogress,
    TPAYPROFILE: state => state.tpayprofile,
    TPAYTOKEN: state => state.tpaytoken,
    ISTPAYAGENT: state => state.istpayagent,
    GETWEBTOKEN: state => state.webpushtoken,
    ISINTERNALSYSTEMUSER: state => state.internalsystemuser,
    LOGINFAILED: state => state.loginmodel.isfailedlogin,
    LOGINERRORMESSAGE: state => state.loginmodel.errorMessage,
    LOGINOBJECT: state => state.loginmodel,
    USERPROFILE: state => state.userprofile,
    GETUSERROLES: state => state.userroles,
    TOKEN: state => state.userprofile.token,
    ASSIGNEDTAXIASSOCIATION: state => ({
      id: state.userprofile.taxi_associations_id,
      name: state.userprofile.taxi_associations_name,
      abbr: state.userprofile.taxi_associations_abbr,
      code: state.userprofile.taxi_associations_code,
    }),
    SELECTEDTAXIASSOCIATION: state => state.userprofile.selected_taxi_association,
    GETTOTALUNREAD: (state, getters, rootState, rootGetters) => {
      return rootGetters['messageinbox/GETTOTALUNREAD']
    },

  },

  mutations: {
    UPDATEPROGRESS: (state, {
      PROGRESS,
      MESSAGE
    }) => {
      state.loginprogress.PROGRESS += PROGRESS;
      state.loginprogress.MESSAGE = MESSAGE;
    },
    LOGINSUCCESS: (state, _userprofile) => {
      if (_userprofile != undefined) {
        state.internalsystemuser = _userprofile.usergroups_code === 'ISA' //* Internal System User */;
        state.userprofile = _userprofile;
        state.loginmodel.errorMessage = undefined;
        state.loginmodel.isfailedlogin = false;
      }
    },
    LOGINFAILED: (state, _errorMessageObject) => {
      if (_errorMessageObject != undefined) {
        state.userprofile = undefined;
        state.loginmodel.errorMessage = _errorMessageObject;
        state.loginmodel.isfailedlogin = true;
      }
    },
    UPDATETAXIASSOCIATION: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        state.userprofile.taxi_associations_id = _UpdateObject.id;
        state.userprofile.taxi_associations_name = _UpdateObject.name;
        state.userprofile.taxi_associations_abbr = _UpdateObject.abbr;
        state.userprofile.taxi_associations_code = _UpdateObject.code;
        state.userprofile.selected_taxi_association = _UpdateObject.taxiass;
      }
    },
    SELECTEDTAXIASSOCIATION: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        state.userprofile.selected_taxi_association = _UpdateObject.taxiass;
      }
    },
    NEWSELECTEDTAXIASSOCIATION: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        state.userprofile.selected_taxi_association = _UpdateObject;
      }
    },
    UPDATETAXIASSOCIATIONPROFILE: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        state.userprofile.selected_taxi_association = _UpdateObject;
      }
    },
    CLEARPROGRESS: (state) => {
      state.loginprogress = {
        PROGRESS: 0,
        MESSAGE: '',
      }
    },
    UPDATEWEBTOKEN: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        state.webpushtoken = _UpdateObject;
      }
    },
    UPDATEUSERROLES: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        state.userroles = _UpdateObject;
      }
    },
    UPDATETPAYAGENT: (state, _UpdateObject) => {
      if (_UpdateObject != undefined) {
        
        state.istpayagent = true;
        state.tpaytoken = _UpdateObject.Token;
        state.tpayprofile = _UpdateObject;
      }
    },
    FAILEDTPAYAGENT: (state, _UpdateObject) => {
     
        state.istpayagent = false;
        state.tpaytoken = null
        state.tpayprofile = null
      
    },
    updateField,
  },

  actions: {

    clearprogress({
      commit
    }, _UpdateObject) {
      commit('CLEARPROGRESS');
    },
    updatetaxiassociation({
      commit
    }, _UpdateObject) {
      commit('UPDATETAXIASSOCIATION', _UpdateObject);
    },
    updatewebtoken({
      commit
    }, token) {
      commit('UPDATEWEBTOKEN', token);
    },
    siteverify({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      _loginmodel,
      router,
      context
    }) {
      return new Promise((resolve, reject) => {
      })
    },
    /* Async */
    Auth_login({
      commit,
      state,
      dispatch,
      getters,
      rootGetters
    }, {
      _loginmodel,
      router,
      context
    }) {
      return new Promise((resolve, reject) => {
        debugger
        this._vm.$TAMS_API.login(_loginmodel).then((parsedResult) => {
          debugger
          

          if (parsedResult.status) {
            console.log('login Browser Results: ', parsedResult.data[0])

            commit('LOGINSUCCESS', parsedResult.data[0]);
            commit('UPDATEWEBTOKEN', _loginmodel.webpushtoken);


            // if (!parsedResult.data[0].knownbrowser) {
            //   reject(parsedResult.data);
            // } else {
              if (parsedResult.data[0]) {
              // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

              // To set unique user id in your system when it is available
              
              //window.fcWidget.setExternalId(parsedResult.data[0].system_usersid);
              // // To set user name
              // window.fcWidget.user.setFirstName(parsedResult.data[0].first_name);
              // window.fcWidget.user.setLastName(parsedResult.data[0].surname);

              //var taxiass = parsedResult.data[0].taxi_associations_abbr ? parsedResult.data[0].taxi_associations_abbr.toUpperCase() : "";

              //var useremail = parsedResult.data[0].first_name + "." + parsedResult.data[0].surname + "@" + parsedResult.data[0].taxi_associations_abbr + ".co.za"
              // // To set user email
              // window.fcWidget.user.setEmail(useremail);
              // To set user properties
              // window.fcWidget.user.setProperties({
              //   idnumber: parsedResult.data[0].id_number,
              //   occupation: parsedResult.data[0].usergroups_name,
              //   taxiassociation: taxiass,
              //   firstName: parsedResult.data[0].first_name,
              //   lastName: parsedResult.data[0].surname,
              //   phone: parsedResult.data[0].mobile_number
              // });

              //Update progress bar by 10%
              commit('UPDATEPROGRESS', {
                PROGRESS: 5,
                MESSAGE: 'Loading Roles'
              });

              
              dispatch('getUserRoles', {
                router: router,
                id: parsedResult.data[0].system_usersid
              }).then((rb) => {

                //Update progress bar by 10%
                commit('UPDATEPROGRESS', {
                  PROGRESS: 5,
                  MESSAGE: 'Loading Countries'
                });

                  /* NON ADMIN USERS */
                  if (parsedResult.data[0].taxi_associations_id) {

                    commit('UPDATEPROGRESS', {
                      PROGRESS: 5,
                      MESSAGE: 'Loading Billing'
                    });

                    dispatch('messageinbox/gettotalunreadmessage', {
                      router,
                      id: parsedResult.data[0].system_usersid
                    }, {
                      root: true
                    }).then((_totalunread) => {

                      console.log("gettotalunreadmessage")


                      dispatch('TA/getByBillingId', {
                        router,
                        id: parsedResult.data[0].taxi_associations_id
                      }, {
                        root: true
                      }).then((billingDetails) => {

                        console.log("getByBillingId")

                        if (!billingDetails.status) {
                          commit('UPDATEPROGRESS', {
                            PROGRESS: 5,
                            MESSAGE: ''
                          });
                          resolve({
                            rb: parsedResult.data[0],
                            context
                          });
                          context.$Notify.BlockScreen(billingDetails.message, "Please call administrator", true);
                        } else {

                          commit('UPDATEPROGRESS', {
                            PROGRESS: 5,
                            MESSAGE: 'Loading Taxi Association'
                          });

                            console.log("getById")


                            if (billingDetails.message)
                              context.$Notify.BlockScreen(billingDetails.message, "");

                              

                            if(getters["SELECTEDTAXIASSOCIATION"] === null || getters["SELECTEDTAXIASSOCIATION"] === undefined){

                            context.$store
                              .dispatch("TA/getByAssId2",
                                {
                                  router: context.$router,
                                  id : parsedResult.data[0].taxi_associations_id
                                })
                              .then((_taxiass) => {


                                commit('UPDATETAXIASSOCIATION', {
        
                                  id: _taxiass._taxi_associations_id,
                                  name: _taxiass._name,
                                  abbr: _taxiass._abbriviated_name,
                                  code: _taxiass._code,
                                  taxiass: _taxiass,
                                });

                                resolve({
                                  rb: parsedResult.data[0],
                                  context
                                });
                                

                                navigate.To(router, parsedResult.data[0]);

                              });

                            } else {
                               navigate.To(router, parsedResult.data[0]);
                            }

                        }

                      });
                    });
                  } else {
                    /* ADMIN USERS */
                    commit('UPDATEPROGRESS', {
                      PROGRESS: 10,
                      MESSAGE: ''
                    });
                    resolve({
                      rb: parsedResult.data[0],
                      context
                    });
                    navigate.To(router, parsedResult.data[0]);

                  }

              });


            }

            this._vm.$TPAY_API.loginAgent({
              mobilenumber:parsedResult.data[0].mobile_number,
              idnumber:parsedResult.data[0].id_number,
              tamssystemUserid:parsedResult.data[0].system_usersid,
              userlocation:"",
              platformtype_code:"TAMSWEB",
              pushtoken:_loginmodel.webpushtoken,
            }).then((parsedResult) => {
            if (parsedResult.Status) {
              //commit('ADDED', item);
              
              commit('UPDATETPAYAGENT', parsedResult.Data);
              
              // navigateTo(router, parsed_result.data[0]);
            }
            }).catch((error) => {
              

              commit('FAILEDTPAYAGENT',error);
              
              //resolve(parsedResult.Data);
              // commit('LOGINFAILED', error.data);
              //reject(error);
            });

          } else {
            reject(parsedResult.data);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },

    systemUserVerifyPin({
      commit,
      state,
      dispatch,
      rootGetters
    }, {
      addModel,
      router,
      context
    }) {
      return new Promise((resolve, reject) => {
        
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.SYSTEMUSERVERIFYPIN_URL,
        };

        this._vm.$TAMS_API.add(requestheader, addModel).then((parsedResult) => {
          
          if (parsedResult.status) {
            resolve(parsedResult.data)
          } else {
            // commit('EXTERNALUPDATED', parsedResult.data);
            reject(parsedResult.data)
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          console.log('verification errors: ',error);
          reject(error);
        });
      });
    },
    /* Async */
    getUserRoles({
      state,
      rootState,
      rootGetters,
      commit,
      dispatch,
      getters,
    }, {
      router,
      id
    }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ROLEBYUSERID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
        ).then((parsedResultOP) => {
          if (parsedResultOP.status) {
            commit('UPDATEUSERROLES', parsedResultOP.data);
            resolve(parsedResultOP.data);
            // navigateTo(router, parsed_result.data[0]);
          }
        }).catch((error) => {
          // commit('LOGINFAILED', error.data);
          reject();
        });
      });
    },
  },

};