<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'DepartureTimes'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Departure Times"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Book-A-Seat"
        breadcrumbitem3="New Departure Times"
      />
      <!-- end page-header -->


             <!-- begin nav-pills -->
             <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active nav-pills-tab-1">
            <span class="d-sm-none">Add Rank</span>
            <span class="d-sm-block d-none">Add Rank</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link nav-pills-tab-2">
            <span class="d-sm-none">View Rank</span>
            <span class="d-sm-block d-none">View Rank</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

        <!-- begin tab-content -->
        <div class="tab-content">

      <div class="tab-pane fade active show" id="nav-pills-tab-1">
        <div class="panel-heading">
          <h4 class="panel-title"></h4>
        </div>
        <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
              <div class="">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                <!-- end form-group -->
                <div class="form-group row">
                  <label class="col-md-3 col-form-label text-md-right">
                    Select Route
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <select
                      class="form-control"
                      id="selectRoute"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      @change="ChangeRoute"
                    >
                    <option key="0" value="0">Select Route</option>
                      <option
                        v-for="item in GETALL"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.route_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- end form-group -->

                <div class="form-group row">
                  <label class="col-md-3 col-form-label text-md-right">
                    Description
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="text"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      class="form-control"
                      placeholder="05:00"
                      v-model="ADDSYSTEMUSER.description_"
                    />
                  </div>
                </div>
                <!-- end form-group -->

                <div class="form-group row">
                  <label class="col-md-3 col-form-label text-md-right">
                    Departure Time
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <select
                      class="form-control"
                      id="selecttamsopsfromdate"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      v-model="ADDSYSTEMUSER.fromdate"
                    >
                    <option key="0" value="0">Select Departure Time</option>
                      <option key="1" value="00:00">12 AM</option>
                      <option key="2" value="01:00">1 AM</option>
                      <option key="3" value="02:00">2 AM</option>
                      <option key="4" value="03:00">3 AM</option>
                      <option key="5" value="04:00">4 AM</option>
                      <option key="6" value="05:00">5 AM</option>
                      <option key="7" value="06:00">6 AM</option>
                      <option key="8" value="07:00">7 AM</option>
                      <option key="9" value="08:00">8 AM</option>
                      <option key="10" value="09:00">9 AM</option>
                      <option key="11" value="10:00">10 AM</option>
                      <option key="12" value="11:00">11 AM</option>
                      <option key="13" value="12:00">12 PM</option>
                      <option key="14" value="13:00">1 PM</option>
                      <option key="15" value="14:00">2 PM</option>
                      <option key="16" value="15:00">3 PM</option>
                      <option key="17" value="16:00">4 PM</option>
                      <option key="18" value="17:00">5 PM</option>
                      <option key="19" value="18:00">6 PM</option>
                      <option key="20" value="19:00">7 PM</option>
                      <option key="21" value="20:00">8 PM</option>
                      <option key="22" value="21:00">9 PM</option>
                      <option key="23" value="22:00">10 PM</option>
                      <option key="24" value="23:00">11 PM</option>
                    </select>
                  </div>
                </div>
                <!-- end form-group -->

                <div class="form-group row">
                  <label class="col-md-3 col-form-label text-md-right">
                    Seats
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="number"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      class="form-control"
                      placeholder="15"
                      v-model="ADDSYSTEMUSER.numberofseats_"
                    />
                  </div>
                </div>
                <!-- end form-group -->

               
              </div>
              <div class="col-lg-6 col-md-12">

                <div class="form-group row m-b-10">
                  <label class="col-md-3 col-form-label text-md-right">
                    Max Seats
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="number"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      class="form-control"
                      placeholder="15"
                      v-model="ADDSYSTEMUSER.maxnumberofseats_"
                    />
                  </div>
                </div>
                <!-- end form-group -->


                <div class="form-group row m-b-10">
                  <label class="col-md-3 col-form-label text-md-right">
                    Expiry Date
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <div
                      class="input-group date datepicker-disabled-past"
                      data-date-format="dd M yyyy"
                      id="ExpiryDate"
                    >
                      <input
                        type="text"
                        class="form-control"
                        id="ExpiryDate"
                        placeholder="Select Date"
                        v-model="ADDSYSTEMUSER.expiry_date"
                      />
                      <span class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end form-group -->

                <div class="form-group row m-b-10">
                  <label class="col-md-3 col-form-label text-md-right">
                    Max No Of Slots
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="number"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      class="form-control"
                      placeholder="05:00"
                      v-model="ADDSYSTEMUSER.maxslots"
                    />
                  </div>
                </div>
                <!-- end form-group -->

                <div class="form-group row m-b-10">
                  <label class="col-md-3 col-form-label text-md-right">
                    Order By
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-6">
                    <input
                      type="number"
                      data-parsley-required="true"
                      data-parsley-group="step-1"
                      class="form-control"
                      placeholder="Enter Order By Number"
                      v-model="ADDSYSTEMUSER.order_by"
                    />
                  </div>
                </div>
                <!-- end form-group -->

                <div class="form-group row m-b-10">
                          <label class="col-md-3 col-form-label text-md-right">
                            Booking Fee
                            <span class="text-danger">*</span>
                          </label>
                          <div class="col-md-6">
                            <input
                                type="number"
                                data-parsley-required="true"
                                  data-parsley-group="step-1"
                                class="form-control"
                                v-model="ADDSYSTEMUSER.bookingfee"
                
                              >
                          </div>
                        </div>
                        <!-- end form-group -->

                <div class="form-group row m-b-10">
                  <label class="col-md-3 col-form-label text-md-right">
                   
                    <span class="text-danger"></span>
                  </label>
                  <div class="col-md-6">
                    <a
                    href="javascript:;"
                   id="AddDepart"
                    :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('AddDepart'),
                  'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('AddDepart')
            }"
            >
              <i
                class="fa fa-clock t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('AddDepart')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('AddDepart')"
              ></i>

              <span>Submit</span>
            </a>
                  </div>
                </div>
                <!-- end form-group -->


            </div>
            </div>
            <h5>Day Of The Week</h5>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in GETDOW" v-bind:key="item.id" :class="item.deleted_by ? 'success':''">
                      <td>{{ item.name }}</td>
                      <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                      <button v-if="!item.deleted_by" type="button" @click="AddedDOW(context,item)" class="btn btn-success"
                        :disabled="$wait.is('AddedDOW-' +item.id)">
                 <v-wait v-bind:for="'AddedDOW-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Add
                  </v-wait>
                        
                    </button> 
                    <button v-if="item.deleted_by" type="button" @click="RemoveDOW(context,item)" class="btn btn-danger"
                        :disabled="$wait.is('RemoveDOW-' +item.id)">
                 <v-wait v-bind:for="'RemoveDOW-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Remove
                  </v-wait>
                        
                    </button> 
                  </div>
                    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

              </div>
            </form>
          <!-- end wizard-form -->
        </div>
        <!-- end col-12 -->
      </div>
      </div>

      <!-- begin row -->

       <!-- begin tab-pane -->
       <div class="tab-pane fade" id="nav-pills-tab-2">
        <div class="panel-heading">
          <div class="panel-heading-btn">
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-default"
              data-click="panel-expand"
            >
              <i class="fa fa-expand"></i>
            </a>
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-success"
              data-click="panel-reload"
            >
              <i class="fa fa-redo"></i>
            </a>
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-warning"
              data-click="panel-collapse"
            >
              <i class="fa fa-minus"></i>
            </a>
            <a
              href="javascript:;"
              class="btn btn-xs btn-icon btn-circle btn-danger"
              data-click="panel-remove"
            >
              <i class="fa fa-times"></i>
            </a>
          </div>
          <h4 class="panel-title">
            <select
              id="selallranks"
              class="ml-2 mr-2 pr-2 btn btn-light btn-xs"
              @change="onChange($event)"
            >
              <option value="0" selected>Select Rank</option>
              <option
                        v-for="item in GETALL"
                        :key="item.id"
                        :value="item.route_name"
                      >
                        {{ item.route_name }}
                      </option>
            </select>
            <div
              v-show="ISLOADING"
              class="spinner-border spinner-border-lg text-info"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </h4>
        </div>
        <div class="panel-body">
          <div class="row">
            <!-- begin col-12 -->
              <!-- begin wizard-form -->
                <table class="table table-striped table-bordered table-invoice">
                  <thead>
                    <tr>
                      <th>RANK NAME</th>
                      <th>ROUTE NAME</th>
                      <th>DESCRIPTION</th>
                      <th>FROM DATE</th>
                      <th>STATUS</th>
                      <th>NO OF SEATS</th>
                      <th>EXPIRY DATE</th>
                      <th>MAX SLOTS</th>
                      <th>FARE PRICE</th>
                      <th>BOOKING FEE</th>
                      <th>DAY OF THE WEEK</th>
                      <th>ORDER</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in ASSOCIATIONRANKS" v-bind:key="item.id">
                      <td>{{ item.rankname }}</td>
                      <td>{{ item.routename }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.from_date }}</td>
                      <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item.is_active, 'label-secondary':!item.is_active  }"
                      >{{item.is_active ? 'Active' : 'Disabled' }}</span>
                      </td>
                      <td>{{ item.numberofseats }}</td>
                      <td>{{ item.expirydate }}</td>
                      <td>{{ item.max_slots }}</td>
                      <td>{{ item.fare_price | newcurrencywithSymbol }}</td>
                      <td>{{ item.bookingfee }}%</td>
                      <td>{{ item.dayoftheweek }}</td>
                      <td>{{ item.orderby }}</td>
                      <td class="with-btn valign-middle" nowrap>
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button v-if="item.is_active" type="button" @click="Edit(context,item)" class="btn btn-white"
                        :disabled="$wait.is('Edit-' +item.id)">
                 <v-wait v-bind:for="'Edit-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Edit
                  </v-wait>
                        
                    </button> 
                      <button v-if="item.is_active" type="button" @click="EnableDisableAccount(context,item,false)" class="btn btn-warning"
                        :disabled="$wait.is('EnableDisableAccount-' +item.id)">
                 <v-wait v-bind:for="'EnableDisableAccount-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Disable
                  </v-wait>
                        
                    </button> 
                    <button v-if="!item.is_active" type="button" @click="EnableDisableAccount(context,item,true)" class="btn btn-success"
                      :disabled="$wait.is('EnableDisableAccount-' +item.id)"
                    >
                 <v-wait v-bind:for="'EnableDisableAccount-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Enable
                  </v-wait>
                        
                    </button>
                    <button v-if="!item.is_active && item.deleted_by===null" type="button" @click="DeleteUndelete(context,item,true)" class="btn btn-danger"
                        :disabled="$wait.is('DeleteUndelete-' +item.id)">
                 <v-wait v-bind:for="'DeleteUndelete-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Delete
                  </v-wait>
                        
                    </button> 
                    <button v-if="ISINTERNALSYSTEMUSER && item.deleted_by!=null" type="button" @click="DeleteUndelete(context,item,false)" class="btn btn-success"
                      :disabled="$wait.is('DeleteUndelete-' +item.id)"
                    >
                 <v-wait v-bind:for="'DeleteUndelete-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    Restore
                  </v-wait>
                        
                    </button> 
                      </div>
                    </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
      </div>

      </div>
        <!-- end tab-pane -->


    
      <!-- end row -->

  

      <!-- begin #Main Body Template -->


      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpay_systemuser");

export default {
  name: "AddSystemUser",
  data: function () {
    return {
      BANKS: [],
      TPAYPROFILE: this.$store.getters["auth/TPAYPROFILE"],
      isUserNameExists: true,
      ISMOBILEVERIFIED: false,
      GETDRIVERSBYASSID: [],
      GETALL: [],
      ASSOCIATIONRANKS: [],
      ASSOCIATIONRANKSALL: [],
      ASSOCIATIONRANKSFILTER: null,
      ASSOCIATIONROUTES: [],
      GETDOW: [],
      GETDOWSELECT: [],
      OPT: "",
      USERNAME: "",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      taxi_association_id:
        this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      context: this,
      selectedUserGroupId: 0,
      GETALLUSERGROUPS: [],
      resolutionWidth: undefined,
      resolutionHeight: undefined,
      hacksmartwizard: false,
      SELECTEDRANK: null,
      SELECTEDROUTE: null,
      SELECTEDTAMSRANK: null,
      TAXIFARE: 0,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      ADDSYSTEMUSER: {
        departuretimeid: null,
        rank_routes_id: "",
        description_: "",
        fromdate: "",
        addedby: this.$store.getters["auth/TPAYPROFILE"].SystemUsersId,
        numberofseats_: 15,
        maxnumberofseats_: 22,
        dayoftheweekdate_: null,
        expiry_date: "",
        maxslots: 2,
        order_by: 1,
        bookingfee: 0,
      },
      VERIFIY: {
        user_id: "",
        webpushtoken: "",
        one_time_pin: "",
      },
      ISLOADING: false,
      ISGRIDLOADING: false
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    CapturePhotosControl,
  },
  computed: {
    ...mapGetters(["GETALLTITLES", "GETALLGENDERS"]),
  },
  watch: {
    USERNAME: function (val) {
      if (val.toString().length > 3) {
        this.context.$store
          .dispatch("tpayauth/LicenseNoExists", {
            licencenumber: val,
          })
          .then((rb) => {
            ;
            this.isUserNameExists = rb.Status;
          });
      }
    },
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted",
      "EnableDisableDepartureTime",
      "DeleteUndeleteDepartureTime",
    ]),
    ChangeRoute: (event) => {
      const context = $(document).data("context");

      

      var item = context.GETALL.filter((x) => x.id === parseInt(event.target.value));
      
      context.ADDSYSTEMUSER.rank_routes_id = item[0].rank_routeid
      context.ADDSYSTEMUSER.bookingfee = item[0].bookingfee
    },
    onChange: (event) => {
        const context = $(document).data("context");
      //alert(event.target.value);

      if(event.target.value == "0"){
        context.ASSOCIATIONRANKSFILTER = null;
        context.ASSOCIATIONRANKS = context.ASSOCIATIONRANKSALL;
      } else {
        context.ASSOCIATIONRANKSFILTER = event.target.value;
        context.ASSOCIATIONRANKS = context.ASSOCIATIONRANKSALL.filter(
        (x) => x.routename == event.target.value
      );
      }
    },
    AddedDOW: (context,item) => {

      //var SelectItem = context.GETDOWS.filter((x) => x.id == item.id);
      
        //context.GETDOWSELECT.push(item);
        item.deleted_by = true;
        item.date_deleted = item.id;

    },
    RemoveDOW: (context,item) => {

        //context.GETDOWSELECT = context.GETDOWSELECT.filter((x) => x.id !== item.id);

        item.deleted_by = false;
        item.date_deleted = null
      
    },
    Edit: (context,item) => {


      debugger;
      var route = context.GETALL.filter((x) => x.rank_routeid === item.fk_rank_routes_id)[0];
      document.getElementById('selectRoute').value=route.id;
      document.getElementById('selecttamsopsfromdate').value=item.from_date;
      
      context.ADDSYSTEMUSER.rank_routes_id = item.fk_rank_routes_id
      context.ADDSYSTEMUSER.bookingfee = item.bookingfee

      if(item.dayoftheweek_data != null && item.dayoftheweek_data.length > 0 && item.dayoftheweek_data[0] != null){
          item.dayoftheweek_data.forEach((GETDOWitem, index)=>{
          var DOW = context.GETDOW.filter((x) => x.id === GETDOWitem.fk_dayoftheweek_id)[0];
          DOW.deleted_by = true;
          DOW.date_deleted = GETDOWitem.fk_dayoftheweek_id;
          //context.GETDOWSELECT.push(DOW);
        });
      }
      
      context.ADDSYSTEMUSER = {
        departuretimeid: item.id,
        rank_routes_id: item.fk_rank_routes_id,
        description_: item.description,
        fromdate: item.from_date,
        addedby: context.$store.getters["auth/TPAYPROFILE"].SystemUsersId,
        numberofseats_: item.numberofseats,
        maxnumberofseats_: item.maximum_seats,
        dayoftheweekdate_: null,
        expiry_date: item.expirydate,
        maxslots: item.max_slots,
        order_by: item.orderby,
        bookingfee: item.bookingfee,
      }

      $('.nav-pills-tab-1').click();
    },
    EnableDisableAccount: (context,item,_disable) => {

item._disable = _disable;

context.$Notify.YesNo(false, { context, item }).then(value => {
  if (value) {
    
  
    value.context.$wait.start('EnableDisableAccount-'+ item.id)

    value.context.EnableDisableDepartureTime({
      rank_routes_id: value.item.id,
      delete: value.item._disable,
      updateby: value.context.TPAYPROFILE.SystemUsersId,
    }).then((rb) => {
      value.context.$wait.end('EnableDisableAccount-'+ item.id)
      if(rb.Status){
        value.item.is_active = _disable
        //value.context.$Notify.Success("Successfull","");
      } else {
        value.context.$Notify.Error("Data Not Found", "");
      }
    
    })
  } else {
    value.context.$wait.end('EnableDisableAccount-'+ item.id)
  }
});
},
DeleteUndelete: (context,item,_disable) => {

item._disable = _disable;

context.$Notify.YesNo(false, { context, item }).then(value => {
  if (value) {
    
  
    value.context.$wait.start('DeleteUndelete-'+ item.id)

    value.context.DeleteUndeleteDepartureTime({
      rank_routes_id: value.item.id,
      delete: value.item._disable,
      updateby: value.context.TPAYPROFILE.SystemUsersId,
    }).then((rb) => {
      value.context.$wait.end('DeleteUndelete-'+ item.id)
      if(rb.Status){
        value.item.deleted_by = _disable
        //value.context.$Notify.Success("Successfull","");
      
      } else {
        value.context.$Notify.Error("Data Not Found", "");
      }
    
    })
  } else {
    value.context.$wait.end('DeleteUndelete-'+ item.id)
  }
});
},
    GetALLRoutesById: (context, id) => {
      context.ISLOADING = true;
      context.$store
        .dispatch("tpay_systemuser/GetAllRoutesByTaxiAssId", {
          id,
        })
        .then((rb) => {
          
          context.GETALL = rb.Data;

          context.ISLOADING = false;
        })
        .finally((error) => {
          if(!context.ISLOADING) {
            var options = {
            responsive: true,
            autoFill: true,
            colReorder: false,
            keys: false,
            rowReorder: false,
            select: false
          };

          if ($(window).width() <= 767) {
            options.rowReorder = false;
            options.colReorder = false;
            options.autoFill = false;
          }

          setTimeout(() => {
            //$(".table-invoice").DataTable(options);
          }, 250) //customize this

         
          }
        });
    },
    getbyAssId: (context) => {
      context.$store
        .dispatch("tpay_systemuser/GetDepartureTimeByTaxiAssId", {
          id: context.taxi_association_id,
        })
        .then((data) => {
          context.ASSOCIATIONRANKS = data.Data;
          context.ASSOCIATIONRANKSALL = data.Data;

          if(context.ASSOCIATIONRANKSFILTER !== null){
            context.ASSOCIATIONRANKS = context.ASSOCIATIONRANKSALL.filter(
              (x) => x.routename == context.ASSOCIATIONRANKSFILTER
            );
          }
        })
        .catch((error) => {
          console.log("these are the getter errors:", error);
        })
        .finally((error) => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: true,
                    colReorder: true,
                    keys: true,
                    rowReorder: true,
                    select: false,
                    order: [[1, 'asc']]
                  };

                  if ($(window).width() <= 767) {
                    options.rowReorder = false;
                    options.colReorder = false;
                    options.autoFill = false;
                  }

                  if(!context.ISGRIDLOADING){
                    $(".table-invoice").DataTable(options);
                    context.ISGRIDLOADING = true
                  }
 	              

        });
    },
    getDOW: (context) => {
      context.$store
      .dispatch("tpay_systemuser/GetDayOfWeek", {
        })
        .then((data) => {
          context.GETDOW = data.Data;
        })
        .catch((error) => {
          console.log("these are the getter errors:", error);
        })
        .finally((error) => {
          $("#data-table-default").DataTable({
            responsive: true,
          });
        });
    },
  },
  mounted: function () {
    //this.get(this.context);

    App.init();

    $(document).data("context", this);

    this.getbyAssId(this.context);
    this.getDOW(this.context);
    this.GetALLRoutesById(this.context, this.taxi_association_id);

    $("#ExpiryDate")
      .datepicker({
        todayHighlight: true,
        autoclose: true,
      })
      .on("changeDate", function (e) {
        // `e` here contains the extra attributes
        var context = $(document).data("context");
        context.ADDSYSTEMUSER.expiry_date = e.format("dd M yyyy");
      });

    // $("#ExpiryDate").val(moment().add(99, "years"))


    $("#AddDepart").click(() => {
      const context = $(document).data("context");
      
      var res = $('form[name="form-wizard"]').parsley().validate("step-1");

      if (!res) {
        context.$wait.end('AddDepart')
        context.$Notify.Error("Error", "");
        return;
      }

      context.GETDOWSELECT = context.GETDOW.filter((x) => x.deleted_by === true);

      if (context.GETDOWSELECT.length === 0) {
          context.$wait.end('AddDepart')
          context.$Notify.Error("Choose Day Of The Week", "");
          return;
      }

      context.ADDSYSTEMUSER.order_by = parseInt(context.ADDSYSTEMUSER.order_by)
      context.ADDSYSTEMUSER.maxslots = parseInt(context.ADDSYSTEMUSER.maxslots)
      context.ADDSYSTEMUSER.numberofseats_ = parseInt(context.ADDSYSTEMUSER.numberofseats_)
      context.ADDSYSTEMUSER.maxnumberofseats_ = parseInt(context.ADDSYSTEMUSER.maxnumberofseats_)
      context.ADDSYSTEMUSER.bookingfee = parseFloat(context.ADDSYSTEMUSER.bookingfee)
      context.ADDSYSTEMUSER.dayoftheweekdate_ = context.GETDOWSELECT


      context.$Notify
        .YesNo(false, { context: context, item: context.ADDSYSTEMUSER })
        .then((value) => {
          if (value) {
            value.context.$wait.start('AddDepart')
            value.router = value.context.$router;
            value.context.$store
              .dispatch("tpay_systemuser/AddDepartureTime", value.item)
              .then((parsedResult) => {
                if (!parsedResult.Status) {
                  value.context.$Notify.Error(parsedResult.Message, "");
                  return;
                }
                value.context.getbyAssId(value.context.context);
                value.context.$wait.end('AddDepart')

                value.context.$Notify.Success("Success", "");

               
              })
              .catch((error) => {
                value.context.$Notify.Error(parsedResult.Message, "");
              })
              .finally(() => {
                value.context.$wait.end('AddDepart')
              });
          }
        });
    });
  },
};
</script>

<style scoped>
</style>

