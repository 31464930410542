<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'BookASeat'"
      :ActiveMenu="'OperatorsList'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="All Operators"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Operators"
        breadcrumbitem3="All"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Operators</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link to="/Operators/New/:id" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Operator
                </router-link>
              </div>
            </div>

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">ID/Passport</th>
                    <th class="text-nowrap">Avail Bal</th>
                    <th class="text-nowrap">FICA</th>
                    <th class="text-nowrap">PayOut Frequency</th>
                    <th class="text-nowrap">Bank Name</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Bank Account</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYASSID"
                    v-bind:key="item.fk_tams_operator_id"
                    :class="{
                    'success': item.id,
                    }"
                  >
                    <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                    <td >{{item.firstname}} {{item.surname}}</td>
                    <td>{{item.mobilenumber}}</td>
                    <td><router-link
                        :to="{ name: 'taxioperatorprofile', params: { id: item.fk_tams_operator_id }}"
                      >{{item.id_number || item.passport_number}}</router-link></td>
                    <td>{{item.available_balance | newcurrencywithSymbol}}</td>
                    <td>{{item.is_ricad}}</td>
                    <td>{{item.payout_frequency_name}}</td>
                    <td>{{item.bank_name}}</td>
                    <td>{{item.account_type_name}}</td>
                    <td>{{item.account_number}}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <button
                        type="button"
                        class="btn btn-sm btn-info width-60 m-r-2"
                        v-if="!item.id"
                        @click="AddOperator(context,item,USERID)"
                        :disabled="$wait.is('AddOperator-' + item.fk_tams_operator_id)"
                      >
                        <v-wait v-bind:for="'AddOperator-' + item.fk_tams_operator_id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Add
                        </v-wait>
                      </button>
                      <router-link
                        v-if="!item.is_ricad"
                        :to="{ name: 'taxioperatorprofile', params: { id: item.fk_tams_operator_id }}"
                        :class="'btn btn-sm btn-primary width-60 m-r-2'"
                      >FICA</router-link>
                      <DialerControl v-if="item.id && item.is_ricad && USERPROFILE.dialeragentuserid && item.mobilenumber"
                            :AgentNo="USERPROFILE.dialeragentuserid"
                            :PrimaryNo="item.mobilenumber"
                            :Mini="true"
                            :Id="item.fk_tams_operator_id"
                          />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DialerControl from "@/components/common/DialerControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("tpaycommuter");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
    };
  },
  beforeMount: function () {
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DialerControl
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "GetAllOperators",
      "AddOperators",
    ]),

    getbyid: (context) => {

      const router = context.$router;

          var payload = {
            taxiassociationid: context.taxiassociationid,
          };

          
      context
        .context.GetAllOperators({ router, payload })
        .then((data) => {
          console.log("operators found: ", data);
          context.GETOPERATORSBYASSID = data;
          
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          // var options = {
          //           dom: "lBfrtip",
          //           buttons: [
          //             { extend: "copy", className: "btn-sm" },
          //             { extend: "csv", className: "btn-sm" },
          //             { extend: "excel", className: "btn-sm" },
          //             { extend: "pdf", className: "btn-sm" },
          //             { extend: "print", className: "btn-sm" }
          //           ],
          //           responsive: false,
          //           autoFill: true,
          //           colReorder: true,
          //           keys: true,
          //           rowReorder: true,
          //           select: true
          //         };

          if(!context.dataLoaded){
            context.dataLoaded = true;
            var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          $("#data-table-default").DataTable(options);
          }
        });
    },

    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        
        if (value) {
          value.context.$wait.is("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then((data) => {
              value.context.$wait.end("delete-" + data.id);
              value.context.$Notify.Success("Successfully Deleted!", data.id);
            })
            .catch((error) => {
              value.context.$wait.end("delete-", error.data);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          
          if (value) {
            value.context.$wait.is("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    AddOperator: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, {
          context,
          item,
          restoredby
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("AddOperator-" + value.item.fk_tams_operator_id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("tpay_systemuser/RegisterOperatorsId", {
                  tams_operator_id: value.item.fk_tams_operator_id,
                  mobilenumber: value.item.mobilenumber,
              })
              .then(() => {
                value.context.$Notify.Success("Successfull!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.getbyid(value.context, value.context.taxiassociationid);
                value.context.$wait.end("AddOperator-" + value.item.fk_tams_operator_id);
              });
          }
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {
    this.getbyid(this.context, this.taxiassociationid);
    App.init();

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

