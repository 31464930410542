<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Operators'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Edit Taxi Operator"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Operators"
        breadcrumbitem3="Update Operator"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin wizard-form -->
          <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
            <!-- begin wizard -->
            <div id="wizard">
              <!-- begin wizard-step -->
              <ul>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-1">
                    <span class="number">1</span>
                    <span class="info text-ellipsis">
                      Operator Information
                      <small class="text-ellipsis">Name, Address, ID No and DOB</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-2">
                    <span class="number">2</span>
                    <span class="info text-ellipsis">
                      Capture Photo
                      <small class="text-ellipsis">Driver Photo</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-3">
                    <span class="number">3</span>
                    <span class="info text-ellipsis">
                      Upload Supporting Documents
                      <small
                        class="text-ellipsis"
                      >ID document, Proof of Address,License ect.....</small>
                    </span>
                  </a>
                </li>
                <li class="col-md-3 col-sm-4 col-6">
                  <a href="#step-4">
                    <span class="number">4</span>
                    <span class="info text-ellipsis">
                      Completed
                      <small class="text-ellipsis">Complete Registration</small>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- end wizard-step -->
              <!-- begin wizard-content -->
              <div>
                <!-- begin step-1 -->
                <div id="step-1">
                  <!-- begin fieldset -->
                  <!-- begin row -->
                  <div class="row">
                    <!-- begin col-8 -->

                    <div class="col-md-12">
                      <div class="row panel panel-inverse">
                        <!-- begin col-8 -->
                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Title
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                v-model="UPDATEOPERATOR.title_id"
                              >
                                <option value="0" selected>Select a Title</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLTITLES"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Gender
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEOPERATOR.gender_id"
                              >
                                <option value="0" selected>Select a Gender</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLGENDERS"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              First Name
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                palceholder="Siya"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="UPDATEOPERATOR.firstname"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Surname
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="surname"
                                value="Kolisi"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="UPDATEOPERATOR.surname"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Country
                            </label>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                v-model="UPDATEOPERATOR.country_id"
                              >
                                <option value="0" selected>Select a Country</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETALLCOUNTRY"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Id Number
                              <span class="text-danger">*</span>
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="ID Number"
                                data-parsley-group="step-1"
                                data-parsley-required="true"
                                class="form-control"
                                v-model="UPDATEOPERATOR.id_number"
                              />
                            </div>
                          </div>

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Passport Number</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                placeholder="Passport Number"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATEOPERATOR.passport_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <!-- end col-8 -->

                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Existing Membership Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="membershipnumber"
                                value="INT-3201476"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATEOPERATOR.existing_membership_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Mobile Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="mobilenumber"
                                id="masked-input-phone"
                                value="0819399999"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATEOPERATOR.mobilenumber"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Telephone Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                value="0819399999"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATEOPERATOR.telephone_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Email</label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                name="lastname"
                                value="siyakolisis@gmail.com"
                                data-parsley-group="step-1"
                                class="form-control"
                                v-model="UPDATEOPERATOR.email_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Fax Number
                            </label>
                            <div class="col-md-6">
                              <input
                                type="number"
                                name="faxnumber"
                                id="masked-input-phone"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEOPERATOR.fax_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              RAS Registration No.
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEOPERATOR.ras_registration_number"
                              />
                            </div>
                          </div>
                          <!-- begin form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label
                              class="col-md-4 col-form-label text-md-right"
                            >Operator Joined Date</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-joineddate"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  :value="UPDATEOPERATOR.taxi_operator_joined_date  | formatToShortDate"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Date Of Birth</label>
                            <div class="col-md-6">
                              <div
                                class="input-group date datepicker-dateofbirth"
                                data-date-format="dd M yyyy"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Select Date"
                                  :value="UPDATEOPERATOR.date_of_birth  | formatToShortDate"
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-calendar"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                        <br />
                        

                        <div class="col-lg-6">
                          <!-- begin col-6 -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="Quantum"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEOPERATOR.physical_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Physical Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="Quantum"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEOPERATOR.physical_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Suburb
                            </label>
                            <div class="col-md-6">
                              <div class="input-group">
                                <span class="input-group-append">
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                <select
                                  class="form-control"
                                  data-size="10"
                                  data-live-search="true"
                                  data-parsley-group="step-1"
                                  id="selectPhysicalSuburb"
                                >
                                  <option value="0">Select A Suburb</option>
                                  <option
                                    v-bind:key="item._id"
                                    v-for="item in GETALLSUBURBS"
                                    :value="item._id"
                                  >{{item._name}}</option>
                                </select>
                                
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                          
                        </div>

                        
                        


                        <div class="col-lg-6">
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="Quantum"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEOPERATOR.postal_address"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Postal Address 2
                            </label>
                            <div class="col-md-6">
                              <input
                                type="text"
                                value="Quantum"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                class="form-control"
                                v-model="UPDATEOPERATOR.postal_address2"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">
                              Suburb
                            </label>
                            <div class="col-md-6">
                              <div class="input-group">
                                <span class="input-group-append">
                                  <a
                                    href="javascript:;"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    class="btn btn-sm btn-grey"
                                  >
                                    <i class="fa fa-plus fa-md m-t-5"></i>
                                  </a>
                                <select
                                  class="form-control"
                                  data-size="10"
                                  data-live-search="true"
                                  data-parsley-group="step-1"
                                  id="selectPostalSuburb"
                                >
                                  <option value="0">Select A Suburb</option>
                                  <option
                                    v-bind:key="item._id"
                                    v-for="item in GETALLSUBURBS"
                                    :value="item._id"
                                  >{{item._name}}</option>
                                </select>
                                
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>

                        

                      </div>
                      <div class="col-lg-6">
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Bank Name</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEOPERATOR.bankname_id"
                              >
                                <option value="0" selected>Select Bank Name</option>
                                <option
                                  v-bind:key="item.id"
                                  v-for="item in GETBANKS"
                                  :value="item.id"
                                >{{item.name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- end form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Account Type</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <select
                                class="form-control"
                                data-size="10"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEOPERATOR.accounttype_id"
                              >
                                <option value="0" selected>Select Account Type</option>
                                <option
                                  v-bind:key="item._id"
                                  v-for="item in GETACCOUNTTYPE"
                                  :value="item._id"
                                >{{item._name}}</option>
                              </select>
                            </div>
                          </div>
                          <!-- end form-group -->
                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Bank Account Name</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEOPERATOR.account_name"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->

                          <!-- begin form-group -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-4 col-form-label text-md-right">Bank Account Number</label>
                            <span class="text-danger"></span>
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                data-live-search="true"
                                data-parsley-group="step-1"
                                data-parsley-required="false"
                                v-model="UPDATEOPERATOR.account_number"
                              />
                            </div>
                          </div>
                          <!-- end form-group -->
                        </div>
                    </div>
                    <!-- end col-8 -->
                  </div>
                  <!-- end row -->
                  <!-- Add Modal HTML START-->
                <div id="AddModal" class="modal fade">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <form>
                        <div class="modal-header">
                          <h4 class="modal-title">Add Suburb</h4>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >&times;</button>
                        </div>
                        <div class="modal-body">
                          <div class="col-md-12">
                            <!-- begin form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">City</label>
                              <div class="col-md-9">
                                <select
                                  class="form-control"
                                  data-size="10"
                                  data-live-search="true"
                                  data-parsley-group="step-1"
                                  data-parsley-required="false"
                                  v-model="ADDSUBURB._city_id"
                                >
                                  <option value="0" selected>Select City</option>
                                  <option
                                    v-bind:key="item._id"
                                    v-for="item in CITIES"
                                    :value="item._id"
                                  >{{item._name}}</option>
                                </select>
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Name</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Name"
                                  class="form-control"
                                  v-model="ADDSUBURB._cityname"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->

                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Description</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Description"
                                  class="form-control"
                                  v-model="ADDSUBURB._description"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Code</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Code"
                                  class="form-control"
                                  v-model="ADDSUBURB._code"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                            <!-- begin form-group row -->
                            <div class="form-group row m-b-10">
                              <label class="col-md-3 text-md-right col-form-label">Postal Code</label>
                              <div class="col-md-9">
                                <input
                                  type="text"
                                  placeholder="Enter Postal Code"
                                  class="form-control"
                                  v-model="ADDSUBURB._postalcode"
                                />
                              </div>
                            </div>
                            <!-- end form-group row -->
                          </div>
                        </div>
                        <div class="modal-footer">
                          <input
                            type="button"
                            class="btn btn-default"
                            data-dismiss="modal"
                            value="Cancel"
                          />
                          <button
                            type="button"
                            class="btn btn-sm btn-success width-80"
                            @click="addNewSuburb(context,ADDSUBURB)"
                            :disabled="$wait.is('add+')"
                          >
                            <v-wait v-bind:for="'add+'">
                              <template slot="waiting">
                                <i class="fas fa-spinner fa-spin"></i>
                              </template>
                              Add
                            </v-wait>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- Add Modal HTML START-->

                  <!-- end fieldset -->
                </div>
                <!-- end step-1 -->

                <!-- begin step-2 -->
                <div id="step-2">
                  <CapturePhotosControl
                    :SessionID="CapturedPhotosSessionID"
                    :ShowStopStartWebCamControl="false"
                    :CameraWidth="'40%'"
                    :ShowPreviewControl="true"
                    :CameraHeight="'40%'"
                    :ClearData="true"
                  />
                  <!-- begin table-responsive -->
                  <!-- <div class="table-responsive">
                    <table class="table table-striped m-b-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Filename</th>
                          <th>Format</th>
                          <th>Size</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="'Images: ' + images" v-for="images in OPERATORIMAGES">
                          <td width="1%" class="with-img">
                            <img :src="images.data" class="img-rounded height-40" />
                          </td>
                          <td>{{images.filename}}</td>
                          <td>{{images.mimetype}}</td>
                          <td>{{images.length}}</td>
                          <td class="with-btn" nowrap>
                            <a
                              href="#"
                              @click="deletefile(context,images.fileId, USERPROFILE.system_usersid)"
                              class="btn btn-sm btn-danger width-60"
                            >Delete</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>-->
                  <!-- end table-responsive -->
                </div>

                <!-- end step-2 -->

                <!-- begin step-3 -->
                <div id="step-3">
                  <!-- begin fieldset -->
                  <fieldset>
                    <!-- begin row -->

                    <p class="text-center h4 mb-4">Upload Supporting Documents</p>
                    <div class="panel panel-inverse" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
                      </div>
                    </div>
                    <!--End dropzone!-->

                    <br />
                    <!--Start Table!-->
                    <div class="panel panel-inverse" data-sortable-id="table-basic-7">
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th width="35%">Filename</th>
                                <th>Format</th>
                                <th width="40%">Document Type</th>
                                <th width="40%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="item.drivers_supportingdocs_id"
                                v-for="item in GETOPERATORSUPPORTINGDOCS"
                              >
                                <td>{{item._filename}}</td>
                                <td>{{item._mimetype}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-3"
                                    data-parsley-required="true"
                                    v-model="item.documenttype_id"
                                    disabled
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn">
                                  <a
                                    href="#"
                                    @click.prevent="downloadItem(item._filename,item._document,item._mimetype)"
                                    class="btn btn-sm btn-yellow"
                                  >Download</a>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div
                      v-if="UPDATEDOCUMENTS.length != 0"
                      class="panel panel-inverse"
                      data-sortable-id="table-basic-7"
                    >
                      <!-- begin panel-heading -->
                      <div class="panel-heading ui-sortable-handle">
                        <h4 class="panel-title">New Uploaded Files</h4>
                      </div>
                      <!-- end panel-heading -->
                      <!-- begin panel-body -->
                      <div class="panel-body">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                              <tr>
                                <th width="35%">Filename</th>
                                <th>Format</th>
                                <th width="40%">Document Type</th>
                                <th width="40%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                :key="item.drivers_supportingdocs_id"
                                v-for="item in UPDATEDOCUMENTS"
                              >
                                <td>{{item._filename}}</td>
                                <td>{{item._mimetype}}</td>
                                <td>
                                  <select
                                    class="form-control col-sm-4"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-3"
                                    data-parsley-required="true"
                                    v-model="item.customType"
                                  >
                                    <option value="0" selected>Select Document Type</option>
                                    <option
                                      v-bind:key="item._id"
                                      v-for="item in GETALLDOCUMENTTYPE"
                                      :value="item._id"
                                    >{{item._name}}</option>
                                  </select>
                                </td>
                                <td class="with-btn">
                                  <a
                                    href="#"
                                    @click.prevent="downloadItem(item._filename,item._document,item._mimetype)"
                                    class="btn btn-sm btn-yellow"
                                  >Download</a>
                                  <a
                                    href="#"
                                    @click="deletefile(context,item.fileId, USERPROFILE.system_usersid)"
                                    class="btn btn-sm btn-danger"
                                  >Delete</a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                      <!-- end panel-body -->
                      <!-- begin hljs-wrapper -->
                    </div>
                    <div class="alert alert-secondary fade show">
                      <div class="text-center">
                        <!-- <button type="submit" class="btn btn-outline-secondary">Save To Drafts</button> -->
                      </div>
                    </div>
                    <!-- end row -->
                  </fieldset>
                </div>
                <!-- end step-3 -->
                <!-- begin step-3 -->
                <div id="step-4">
                  <div class="jumbotron m-b-0 text-center">
                    <h2 class="text-inverse">Taxi Operator Update Requested</h2>
                    <p
                      class="m-b-30 f-s-16 hide"
                    >Password has been sent to the New User via SMS, Please check your phone</p>
                    <p>
                      <router-link to="/Operators/List" class="btn btn-primary btn-lg">View Operators</router-link>
                    </p>
                  </div>
                </div>
                <!-- end step-4 -->
              </div>
              <!-- end wizard-content -->
            </div>
            <!-- end wizard -->
          </form>
          <!-- end wizard-form -->
        </div>

        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

	<!-- The template to display files available for upload -->

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import CapturePhotosControl from "@/components/common/CapturePhotosControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TO");
// value.context.$emit("init");

export default {
  name: "UPDATEOPERATOR",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      GETUPLOADEDFILES: this.$store.getters["fileupload/GETALL"],
      GETALLTITLES: [],
      GETALLGENDERS: [],
      GETALLSUBURBS: [],
      status: true,
      skip: 0,
      len: 1000,
      GETALLCOUNTRY: [],
      GETALLCITY: [],
      GETALLDOCUMENTTYPE: [],
      GETPHYSICALADDRESS: [],
      GETPOSTALADDRESS: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      OPERATORDOCS: this.$store.getters["TO/GETOPERATORSUPPORTINGDOCS"],
      context: this,
      CITIES: [],
      ADDSUBURB: {
        _city_id: null,
        _cityname: "",
        _description: "",
        _code: "",
        _postalcode: "",
        _added_by: this.$store.getters["auth/USERPROFILE"].system_usersid
      },
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      DropZoneSessionID: this.$uuid.v4(),
      CapturedPhotosSessionID: this.$uuid.v4(),
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      id: this.$route.params.id,
      UPDATEOPERATOR: {
        taxiassociationid: null,
        taxioperatorid: null,
        title_id: null,
        firstname: "",
        surname: "",
        id_number: null,
        date_of_birth: "",
        passport_number: "",
        physical_address: "",
        physical_address2: "",
        physical_suburb_id: null,
        postal_address: "",
        postal_address2: "",
        postal_suburb_id: null,
        country_id: null,
        mobilenumber: "",
        telephone_number: "",
        email_address: "",
        fax_number: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
        gender_id: null,
        existing_membership_number: "",
        taxi_operator_joined_date: "",
        capturephotosessionid: "",
        documentsessionid: "",
        document_files: [
          {
            customType: null,
            fileId: ""
          }
        ],
        bankname_id: null,
        accounttype_id: null,
        account_name: "",
        account_number: "",
        ras_registration_number: ""
      },
      // OPERATORIMAGES: [],
      UPDATEDOCUMENTS: [],
      operatorupdaterequested: false,
      GETBANKS: [], 
      GETACCOUNTTYPE: [],
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    CapturePhotosControl
  },
  beforeMount() {
    this.getById(this.context);
  },
  computed: {
    ...mapGetters({
      GETOPERATORSUPPORTINGDOCS: "GETOPERATORSUPPORTINGDOCS",
    }),
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions([
      "getall",
      "getById",
      "getByTaxiAssId",
      "delete",
      "edit",
      "add",
      "restoreDeleted"
    ]),
    loadBanks(context) {
      context.$store
        .dispatch("TO/getAllBanks", {
          router: context.$router,
        })
        .then((data) => {
          console.log("banks found: ", data);
          context.GETBANKS = data; 
        })
        .catch((error) => {
          console.log("load banks error:", error);
        })
        .finally(() => {});
    },
    loadAccountType(context) {
      context.$store
        .dispatch("TO/getAccountTypes", {
          router: context.$router,
        })
        .then((data) => {
          console.log("account type found: ", data);
          context.GETACCOUNTTYPE = data;
        })
        .catch((error) => {
          console.log("load account types error:", error);
          
        })
        .finally(() => {});
    },
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
          context.GETALLSUBURBS = data.LOADEDSUBURBS;
          context.GETALLCOUNTRY = data.LOADEDCOUNTRIES;
          context.GETALLCITY = data.LOADEDCITY;
          context.GETALLDOCUMENTTYPE = data.LOADEDDOCUMENTTYPE;
          context.GETPHYSICALADDRESS = data.LOADEDPHYSICALADDRESS;
          context.GETPOSTALADDRESS = data.LOADEDPOSTALADDRESS;


        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    getById: context => {
      context.$store
        .dispatch("TO/getOperatorByIdNopic", {
          id: context.id,
          router: context.$router
        })
        .then(_rb => {
          console.log("this is the operator:", _rb);

          context.UPDATEOPERATOR.taxiassociationid =
            _rb[0].taxi_associations_id;
          context.UPDATEOPERATOR.taxioperatorid = _rb[0].taxi_operator_id;
          context.UPDATEOPERATOR.title_id = _rb[0].fk_title_id;
          context.UPDATEOPERATOR.firstname = _rb[0].firstname;
          context.UPDATEOPERATOR.surname = _rb[0].surname;
          context.UPDATEOPERATOR.id_number = _rb[0].id_number;
          context.UPDATEOPERATOR.date_of_birth = _rb[0].date_of_birth;
          context.UPDATEOPERATOR.passport_number = _rb[0].passport_number;
          context.UPDATEOPERATOR.physical_address = _rb[0].physicaladdress;
          context.UPDATEOPERATOR.physical_address2 = _rb[0].physicaladdress2;
          context.UPDATEOPERATOR.physical_suburb_id = _rb[0].physical_suburb_id;
          context.UPDATEOPERATOR.postal_address = _rb[0].postaladdress;
          context.UPDATEOPERATOR.postal_address2 = _rb[0].postaladdress2;
          context.UPDATEOPERATOR.postal_suburb_id = _rb[0].postal_suburb_id;
          context.UPDATEOPERATOR.country_id = _rb[0].country_id;
          context.UPDATEOPERATOR.mobilenumber = _rb[0].mobilenumber;
          context.UPDATEOPERATOR.telephone_number = _rb[0].telephone_number;
          context.UPDATEOPERATOR.email_address = _rb[0].email_address;
          context.UPDATEOPERATOR.fax_number = _rb[0].fax_number;
          context.UPDATEOPERATOR.gender_id = _rb[0].fk_gender_id;
          context.UPDATEOPERATOR.existing_membership_number =
            _rb[0].membership_number;
          context.UPDATEOPERATOR.taxi_operator_joined_date = _rb[0].joined_date;

          // context.OPERATORIMAGES = _rb[0].images;

          if(_rb[0].bankname_id != null){
            context.UPDATEOPERATOR.bankname_id = _rb[0].bankname_id
          }
          else {
              context.UPDATEOPERATOR.bankname_id = null;
          }

          if(_rb[0].accounttype_id != null){
            context.UPDATEOPERATOR.accounttype_id = _rb[0].accounttype_id;
          }
          else {
              context.UPDATEOPERATOR.accounttype_id = null;
          }
          
          context.UPDATEOPERATOR.account_number = _rb[0].bank_account_number;

          if(_rb[0].bank_account_name == null || _rb[0].bank_account_name == undefined){
            context.UPDATEOPERATOR.account_name = _rb[0].firstname + ' ' + _rb[0].surname;
          }
          else{
            context.UPDATEOPERATOR.account_name = _rb[0].bank_account_name;
          }

          // $('#selectPostalSuburb').select2('data', {id: '123', text: 'res_data.primary_email'});

          $("#selectPostalSuburb").select2("val", context.UPDATEOPERATOR.postal_suburb_id);
          $("#selectPhysicalSuburb").select2("val", context.UPDATEOPERATOR.physical_suburb_id);

          context.UPDATEOPERATOR.ras_registration_number = _rb[0].ras_registration_number;

          context.operatorSupportingDocs(context);

          
        });
    },
    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    fileadded: function(data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')
      const context = $("#step-3").data("context");

      context.UPDATEDOCUMENTS.push({
        drivers_supportingdocs_id: "",
        _filename: data.name,
        _mimetype: data.type,
        customType: data.customType,
        fileId: data.fileId
      });
    },
    deletefile: (context, fileId, userID) => {
      context.$Notify.YesNo(false, { context, fileId, userID }).then(value => {
        if (value) {
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("fileupload/delete", {
              id: value.fileId,
              deletedby: value.userID
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$wait.end("update-");
              value.context.$Notify.Success("Successfully Deleted!", "");
            })
            .catch(error => {
              value.context.$wait.end("add");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    operatorSupportingDocs: context => {
      
      context.$store
        .dispatch("TO/getOperatorSupportingDocsById", {
          _dataObj: {
            id: context.UPDATEOPERATOR.taxioperatorid,
            router: context.$router
          }
        })
        .then(data => {
          console.log("supporting Docs: ", data);
        })
        .catch(err => {
          console.log("supporting Docs errors -> ", err);
        });
      //this.$emit('init')
    },

    updateRequest: (context, _updatemodel) => {
      context.$Notify.YesNo(false, { context, _updatemodel }).then(value => {
        
        const dropzone_files = context.UPDATEDOCUMENTS;
        const fileuploaded = [];

        if (dropzone_files.length > 0) {
          var failedvalidation = false;
          dropzone_files.forEach(element => {
            if (element.customType === 0) {
              failedvalidation = true;
              return;
            }
            fileuploaded.push({
              customType: element.customType,
              fileId: element.fileId
            });
          });
          if (failedvalidation) {
            value.context.$Notify.Error("Please Select Document Type.", "");
            return;
          }
        }

        value._updatemodel.date_of_birth = context.$Toolkit.ConvertToServerDate(
          context.UPDATEOPERATOR.date_of_birth
        );

        value._updatemodel.taxi_operator_joined_date = context.$Toolkit.ConvertToServerDate(
          context.UPDATEOPERATOR.taxi_operator_joined_date
        );

        value._updatemodel.postal_suburb_id = parseInt($("#selectPostalSuburb").val());
      value._updatemodel.physical_suburb_id = parseInt($("#selectPhysicalSuburb").val());

        value._updatemodel.document_files = fileuploaded;
        value._updatemodel.documentsessionid = context.DropZoneSessionID;
        value._updatemodel.capturephotosessionid =
          context.CapturedPhotosSessionID;
        if (value) {  
          value.router = value.context.$router;
          value.operatorupdaterequested = value.context.operatorupdaterequested;
          value.context.$store
            .dispatch("TO/updateOperatorProfileRequest", {
              updateModel: value._updatemodel,
              router: value.router
            })
            .then((result) => {
              // $("#AddModal").modal("hide");

              console.log('update results: ', result);

              $(".sw-btn-next")
                .text("Done")
                .attr("disabled", "disabled");
              $(".sw-btn-prev").attr("disabled", "disabled");
              $("#wizard").data("taxioperatorupdated", true);
              $("#wizard").smartWizard("next");
                value.context.$Notify.Success("Update Request Sent!",result);
            })
            .catch(error => {

              $(".sw-btn-next")
                  .text("Save")
                  .removeAttr("disabled");
                $("#wizard").data("operatorupdaterequested", false);
                value.context.$Notify.Error(
                  error.data[0].message || error.data[0].data.message,
                  ""
                );
              // value.context.$wait.end("update-");
              // value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    addNewSuburb:(context,item) =>{
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          value.context.$wait.start("add+");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("suburb/add", {
              _dataObj: {
                item: value.item,
                router: value.router
              }
            })
            .then((data) => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add+");
              console.log('suburb added: ', data);
              value.context.$Notify.Success("Successfully Added!", "");
              context.loadSuburbs(context);
            })
            .catch(error => {
              value.context.$wait.end("add+");

              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },


    loadSuburbs(context) {
      context.$store
        .dispatch("TO/getSuburbs", {
          router: context.$router,
          status: context.status,
          skip: context.skip,
          len: context.len
        })
        .then(data => {
          console.log("suburbs found: ", data);

          context.GETALLSUBURBS = data;
        })
        .catch(error => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
    loadCities(context) {
      context.$store
        .dispatch("city/getall", {
          _dataObj: {
            router: context.$router
          }
        })
        .then(data => {
          console.log("cities found:", data);
          context.CITIES = data;
        })
        .catch(error => {
          console.log("cities errors:", error);
        })
        .finally(() => {});
    },
  },
  mounted: function() {
    //this.get(this.context)

    App.init();
    this.loadLookUps(this.context);
    this.getById(this.context);
    this.loadBanks(this.context);
    this.loadAccountType(this.context);
    this.loadSuburbs(this.context);
    this.loadCities(this.context);
    
    

    $("#step-3").data("context", this.context);

    $(".datepicker-dateofbirth").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $(".datepicker-joineddate").datepicker({
      todayHighlight: true,
      autoclose: true
    });

    $("#selectPhysicalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: true
    });

    $("#selectPostalSuburb").select2({
      multiple: false,
      placeholder: "Select a suburb",
      allowClear: true
    });



    //$('.selectpicker1').selectpicker('render');
    $("#wizard").data("taxioperatorupdated", false);

    //$('.selectpicker1').selectpicker('render');
    $("#wizard").smartWizard({
      selected: 0,
      theme: "default",
      transitionEffect: "",
      keyNavigation: false,
      transitionSpeed: 0,
      cycleSteps: true,
      useURLhash: false,
      showStepURLhash: false,
      toolbarSettings: {
        toolbarPosition: "bottom"
      }
    });

    $("#wizard").data("context", this.context);
    $("#wizard").on("leaveStep", function(
      e,
      anchorObject,
      stepNumber,
      stepDirection
    ) {
      const context = $("#wizard").data("context");
      const currentstep = stepNumber + 1;
      const res = $('form[name="form-wizard"]')
        .parsley()
        .validate("step-" + currentstep);

      if (context.hacksmartwizard) return true;

      $(".sw-btn-next").text("Next");

      if (stepDirection == "backward") return true;

      if (currentstep === 1 && stepDirection == "forward") {
        
      }

      if (currentstep === 2 && stepDirection == "forward") {
        if (context.$store.getters["capturephotos/GETALL"].length > 0) {
          if (context.$store.getters["capturephotos/GETALL"].length < 2) {
          context.$Notify.Error("Please Add More Photos", "");
          return false;
        }

        }

        $(".sw-btn-next").text("Submit");
      }

      if (currentstep === 3 && stepDirection == "forward") {
        const taxioperatorupdated = $("#wizard").data("taxioperatorupdated");

        if (!taxioperatorupdated) {
          context.updateRequest(context,context.UPDATEOPERATOR);
        }
        return taxioperatorupdated;
      }

      if (currentstep === 4 && stepDirection == "forward") {
        if (!res) return res;

        console.log("we are in step 4");
        return false;
      }

      return res;
    });

    // $("#masked-input-phone").mask("(082) 686-9833");
    // $("#masked-input-tid").mask("99999999999999");

    //FormPlugins.init();
    //Highlight.init();

    //     $(function(){
    //    $(".dropdown-menu").on('click', 'a', function(){
    //        $(this).parents('.dropdown').find('button').text($(this).text());
    //    })
    // });
  }
};
</script>

	

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>