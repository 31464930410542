/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import format from 'string-format';
import config from '../configurations/config';
import verify from './verify';

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    Vue.prototype.$TAMS_API = class TAMS_API {
      construct() {
      }

      static login(_loginmodel) {
        return new Promise((resolve, reject) => {
          // 
          // const env = runtimeEnv()
          // console.log(penv);
          // console.log(process.env);
          // console.log(process.env.prod);
          // console.log(process.env.WEBSERVICEBASEURL);
          // console.log(process.env.prod.WEBSERVICEBASEURL);

          debugger

          fetch(config.LOGIN_URL(), {
            method: 'post',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(_loginmodel),
          }).then(response => response.json()).then((parsedResult) => {

            debugger
            
            if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }
      
       static getCustomURL({ router, token, apiName }, _getModel) {
        const url = format(config.URL_CUSTOM(apiName), _getModel);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static getCustomURL2({ router, token, apiName }, _getModel) {
        const url = format(config.URL_CUSTOM2(apiName), _getModel);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      



      static getAll({ router, token, apiName }) {
        const url = config.URL(apiName);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static getById({ router, token, apiName }, id) {
        const url = format(config.URLBYID(apiName), { id });
        return new Promise((resolve, reject) => {
           
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static getByTaxiAssId({ router, token, apiName }, id) {
        
        const url = format(config.URLBYTAXIID(apiName), { id });
        return new Promise((resolve, reject) => {
          
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static getByPaging({ router, token, apiName }, _getPagingModel) {
     
        const url = format(config.URL_PAGING(apiName), _getPagingModel);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            cache: _getPagingModel.cache ? 'force-cache' : 'no-cache',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }


      static getByPaging2({ router, token, apiName }, _getPagingModel) {
        const url = format(config.URL_PAGING2(apiName), _getPagingModel);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }


      static add({ router, token, apiName }, addModel) {
        
        const url = config.URL(apiName);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
            body: JSON.stringify(addModel),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static update({ router, token, apiName }, id, updateModel) {
        const url = format(config.URLBYID(apiName), { id });
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'PUT',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
            body: JSON.stringify(updateModel),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static delete({ router, token, apiName }, id, deleteModel) {
        const url = format(config.URLBYID(apiName), { id });
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'DELETE',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
            body: JSON.stringify(deleteModel),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }

      static restoreDeleted({ router, token, apiName }, deletedModel) {
        const url = format(config.URL_RESTOREDELETED(apiName), deletedModel);
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'PUT',
            headers: new Headers({
              'Content-Type': 'application/json',
              'x-access-token': token,
            }),
          }).then(response => response.json()).then((parsedResult) => {
            if (verify.verifytoken(router, parsedResult)) {
              if (parsedResult.status) { resolve(parsedResult); } else { reject(parsedResult); }
            }
          }).catch((error) => {
            reject(error);
          })
            .finally(() => {

            });
        });
      }
    };
  },
};
