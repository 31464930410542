/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../../../configurations/configbasetpay';


export default {
  namespaced: true,
  state: {
    dashboard_cards: [],
    dashboardfinancestats: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter
    GETDASHBOARDCARDS: state => state.dashboard_cards,
    GETDASHBOARDFINSTATS: state => state.dashboardfinancestats,
  },
  mutations: {
    LOADEDDASHBOARDFINSTATS: (state, _data) => {
      if (_data !== undefined) {
        state.dashboardfinancestats = _data;
      }
    },
    LOADEDDASHBOARDCARDS: (state, _data) => {
        if (_data !== undefined) {
          state.dashboard_cards = _data;
        }
      },
      LOADEDFINANCE: (state, _data) => {
        if (_data !== undefined) {
          state.dashboardfinance = _data;
        }
      },
  },
  actions: {
    getdashboardcards({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router, payload }) {
      
      const url = config.REPORTS_URL("payoutsbookingscards");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'post',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(payload),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult.Data);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    getallbookingslots({
          state, rootState, rootGetters, commit, dispatch, getters,
        }, { router, payload }) {
          
          const url = config.REPORTS_URL("GetAllBookingSlots");
            return new Promise((resolve, reject) => {
              fetch(url, {
                method: 'post',
                headers: new Headers({
                  'Content-Type': 'application/json',
                  'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
                }),
                body: JSON.stringify(payload),
              }).then(response => response.json()).then((parsedResult) => {
                resolve(parsedResult.Data);
              }).catch((error) => {
                reject(error);
              }).finally(() => {
    
              });
            });
        },
        getallpayouts({
          state, rootState, rootGetters, commit, dispatch, getters,
        }, { router, payload }) {
          
          const url = config.REPORTS_URL("GetAllPayOuts");
            return new Promise((resolve, reject) => {
              fetch(url, {
                method: 'post',
                headers: new Headers({
                  'Content-Type': 'application/json',
                  'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
                }),
                body: JSON.stringify(payload),
              }).then(response => response.json()).then((parsedResult) => {
                resolve(parsedResult.Data);
              }).catch((error) => {
                reject(error);
              }).finally(() => {
    
              });
            });
        },
}
};
