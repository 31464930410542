<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'SanralServices'"
      :ActiveMenu="'Commuters'"
    />

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Customers"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Sanral"
        breadcrumbitem3="Customers"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin row -->

      <div class="row">
        <!--Begin col-lg-12-->
        <div class="col-lg-12">
          <!-- begin panel -->
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">Commuters</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  data-toggle="modal"
                  data-target="#AddModal"
                  class="btn btn-white"
                  href="javascript:;"
                >
                  <i class="fa fa-plus"></i>
                  Add Customer
                </a>
              </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table
                id="data-table-titles"
                class="table table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th width="1%" data-orderable="false"></th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Id No/Passport</th>
                    <th class="text-nowrap">Balance</th>
                    <th class="text-nowrap">No Of Vehicles</th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Date Added</th>
                    <th class="text-nowrap">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETALLCOMMUTERS"
                    v-bind:key="item.commuters_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <td width="1%" class="f-s-600 text-inverse">
                      {{ item.commuters_id }}
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'sanralcommuterprofile',
                          params: { id: item.commuters_id },
                        }"
                        class=""
                        >{{ item.firstname }} {{ item.surname }}</router-link>
                      </td>
                    <td>{{ item.mobile_number }}</td>
                    <td>{{ item.id_number }}</td>
                    <td :class="item.balance < 0 ? 'text-danger' : 'even'">{{ item.balance | currencywithSymbol }}</td>
                    <td>{{ item.numberofvehicles }}</td>
                    <td>
                      <span
                        v-bind:class="{
                          'label-primary': !item.isprepaid,
                          'label-secondary': item.isprepaid,
                        }"
                        >{{
                          item.isprepaid == true ? "Prepaid" : "Post Paid"
                        }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{
                          'badge-danger': item.is_suspendaccount,
                          'badge-success': !item.is_suspendaccount,
                        }"
                        >{{
                          item.is_suspendaccount == true
                            ? "Suspended"
                            : "Active"
                        }}</span
                      >
                    </td>
                    <td>{{ item.date_added | formatToShortDateWithTime }}</td>
                    <td class="with-btn valign-middle" nowrap>
                      <DialerControl v-if="USERPROFILE.dialeragentuserid && item.mobile_number"
                            :AgentNo="USERPROFILE.dialeragentuserid"
                            :PrimaryNo="item.mobile_number"
                            :Mini="true"
                            :Id="item.commuters_id"
                          />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
          <!--End col-lg-12-->
        </div>


  <!-- Add Modal HTML START-->
  <div id="AddModal" class="modal fade" v-if="GETSELECTED">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Add Customer</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="col-md-12">
                          <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">First Name</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter First Name"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED.first_name"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                            <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Surname</label>
                            <div class="col-md-9">
                              <input
                                type="text"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Surname"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED.surname"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                            <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">ID No/Passport</label>
                            <div class="col-md-9">
                              <input
                                type="number"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter ID No/Passport"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED.id_number"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->
                            <!-- begin form-group row -->
                          <div class="form-group row m-b-10">
                            <label class="col-md-3 text-md-right col-form-label">Mobile No</label>
                            <div class="col-md-9">
                              <input
                                type="number"
                                name="billingTypeName"
                                id="biiling_type_name"
                                placeholder="Enter Mobile No"
                                class="form-control"
                                minlength="1"
                                v-model="GETSELECTED.mobile_number"
                              >
                            </div>
                          </div>
                          <!-- end form-group row -->

                        </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="addCustomer(context,GETSELECTED,USERPROFILE.system_usersid)"
                          :disabled="$wait.is('add')"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Add
                          </v-wait>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->


        <!-- end row -->
      </div>
      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>


<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import DialerControl from "@/components/common/DialerControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("sanraletag");

export default {
  name: "ManageCommuters",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      GETALLCOMMUTERS: [],
      LOADED: false,
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      GETSELECTED: {
        first_name: "",
        surname: "", 
        mobile_number: "",
        id_number: "",
      },
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DialerControl
  },
  computed: {
    ...mapGetters(["GETALLCOMMUTERS"]),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([]),
    addCustomer: (context, item, addedby) => {
      item.addedby = addedby;
      context.$Notify.YesNo(false, { context, item }).then(value => {
        if (value) {
          
          value.context.$wait.start("add");
          value.router = value.context.$router;
          value.context.$store
            .dispatch("sanraletag/AddCustomer", {
              router: value.context.$router,
              payload: {
              firstnanme: value.item.first_name,
              surname: value.item.surname,
              mobileno: value.item.mobile_number,
              idnumber: value.item.id_number,
              addedby: value.item.addedby,
              }
            })
            .then(() => {
              $("#AddModal").modal("hide");
              value.context.$wait.end("add");
              value.context.$Notify.Success("Successfully Added!", "");
              value.context.getall(value.context);
            })
            .catch(error => {
              value.context.$wait.end("add");
              
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    CALL: (context,item) => {
      context.$Notify
        .YesNo(false, {
          context,item
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("Call" + value.item.commuters_id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("TO/callOperator", {
                router: context.$router,
                agentno: value.context.USERPROFILE.dialeragentuserid,
                mobileno: value.item.mobile_number,
              })
              .then(() => {
                //value.context.$wait.end("Call" + value.item.commuters_id);
                value.context.$Notify.Success("Successfull", "");
              })
              .catch((error) => {
                //value.context.$wait.end("Call");
                //value.context.$Notify.Error(error.data[0].message, "");
                //value.context.$wait.end("Call");
                value.context.$Notify.Success("Successfull", "");
              })
              .finally(() => {
                value.context.$wait.end("Call" + value.item.commuters_id);
              });
          }
        });
    },
    getall: (context) => {
      context.$store
        .dispatch("sanraletag/getSanralCustomersByTaxiAssId", {
          router: context.$router,
          id: context.taxiassociationid,
        })
        .then((data) => {
          context.GETALLCOMMUTERS = data;
        })
        .finally(() => {
          if(!context.LOADED){
            context.LOADED = true;
          $("#data-table-titles").DataTable({
            responsive: false,
            "order": [[ 4, "asc" ]]
          });
          }
        });
    },
    edited: (context, item, updatedby) => {
      //item = Object.assign({}, item);
      context.$Notify
        .YesNo(false, { context, item, updatedby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("edited");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("suburb/update", {
                _dataObj: value,
              })
              .then(() => {
                $("#EditModal").modal("hide");
                value.context.$wait.end("edited");
                value.context.$Notify.Success("Successfully Updated!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {
                value.context.$wait.end("edited");
              });
          }
        });
    },
    deletetitle: (context, item, deletedby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, deletedby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("delete-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("suburb/markAsdeleted", {
                _dataObj: value,
              })
              .then((id) => {
                value.context.$wait.end("delete-" + id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
    restoredelete: (context, item, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id: item._id, restoredby })
        .then((value) => {
          if (value) {
            value.context.$wait.start("restoreDeleted-" + value.id);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("suburb/restoreDeleted", {
                _dataObj: value,
              })
              .then((id) => {
                value.context.$wait.end("restoreDeleted-" + id);
                value.context.$Notify.Success("Successfully Restored!", "");
              })
              .catch((error) => {
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {
    App.init();

    this.getall(this.context);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

