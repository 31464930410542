<template>
<div class="dialer-container">
    <div class="dialer-buttons">
        <div class="dialer-button dial" @click="MAKECALL(context)" :class="{ 'disabled': !isDialButton }" title="Dial">
            <v-wait v-bind:for="'Call'+ Id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <i class="fas fa-phone" ></i>
                  </v-wait>
        </div>
        <div class="dialer-button dial-plan" :class="{ 'disabled': !isManualDialButton, 'hide': Mini  }" title="Dial Plan" data-toggle="modal" data-target="#dialer_modal">
            <i class="fas fa-keyboard"></i>
        </div>
        <div class="dialer-button pause" @click="PAUSECALL(context)" :class="{ 'disabled': !isHoldButton, 'hide': Mini }" title="Pause">
            <v-wait v-bind:for="'Pause'+ Id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <i class="fas fa-pause"></i>
                  </v-wait>
        </div>
        <div class="dialer-button resume" @click="RESUMECALL(context)" :class="{ 'disabled': !isResumeCallButton, 'hide': Mini }" title="Resume">
            <v-wait v-bind:for="'Resume'+ Id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <i class="fas fa-play"></i>
                  </v-wait>
        </div>
        <!-- <div class="dialer-button conference" title="Conference">
            <i class="fas fa-users"></i>
        </div> -->
        <div class="dialer-button hang-up" @click="HANGUPCALL(context)" :class="{ 'disabled': !isHangUpButton }" title="Hang Up">
            <v-wait  v-bind:for="'HangUp'+ Id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <i class="fas fa-phone-slash"></i>
                  </v-wait>
        </div>
    </div>

<!-- Modal -->
<div class="modal fade" id="dialer_modal" tabindex="-1" aria-labelledby="dialer_modal_label" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="dialer_modal_label">Dialer</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table id="dialer_table">
                    <tr>
                        <td id="dialer_input_td" colspan="3"><input type="number" v-model="ManualNumber" placeholder="0826869834"></td>
                    </tr>
                    <tr class="dialer_num_tr">
                        <td class="dialer_num" @click="dialerClick('dial', 1)">1</td>
                        <td class="dialer_num" @click="dialerClick('dial', 2)">2</td>
                        <td class="dialer_num" @click="dialerClick('dial', 3)">3</td>
                    </tr>
                    <tr class="dialer_num_tr">
                        <td class="dialer_num" @click="dialerClick('dial', 4)">4</td>
                        <td class="dialer_num" @click="dialerClick('dial', 5)">5</td>
                        <td class="dialer_num" @click="dialerClick('dial', 6)">6</td>
                    </tr>
                    <tr class="dialer_num_tr">
                        <td class="dialer_num" @click="dialerClick('dial', 7)">7</td>
                        <td class="dialer_num" @click="dialerClick('dial', 8)">8</td>
                        <td class="dialer_num" @click="dialerClick('dial', 9)">9</td>
                    </tr>
                    <tr class="dialer_num_tr">
                        <td class="dialer_del_td">
                            <img alt="clear" @click="dialerClick('clear', 'clear')" src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJlcmFzZXIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWVyYXNlciBmYS13LTE2IGZhLTd4Ij48cGF0aCBmaWxsPSIjYjFiMWIxIiBkPSJNNDk3Ljk0MSAyNzMuOTQxYzE4Ljc0NS0xOC43NDUgMTguNzQ1LTQ5LjEzNyAwLTY3Ljg4MmwtMTYwLTE2MGMtMTguNzQ1LTE4Ljc0NS00OS4xMzYtMTguNzQ2LTY3Ljg4MyAwbC0yNTYgMjU2Yy0xOC43NDUgMTguNzQ1LTE4Ljc0NSA0OS4xMzcgMCA2Ny44ODJsOTYgOTZBNDguMDA0IDQ4LjAwNCAwIDAgMCAxNDQgNDgwaDM1NmM2LjYyNyAwIDEyLTUuMzczIDEyLTEydi00MGMwLTYuNjI3LTUuMzczLTEyLTEyLTEySDM1NS44ODNsMTQyLjA1OC0xNDIuMDU5em0tMzAyLjYyNy02Mi42MjdsMTM3LjM3MyAxMzcuMzczTDI2NS4zNzMgNDE2SDE1MC42MjhsLTgwLTgwIDEyNC42ODYtMTI0LjY4NnoiIGNsYXNzPSIiPjwvcGF0aD48L3N2Zz4=" width="22px" title="Clear" />
                        </td>
                        <td class="dialer_num" @click="dialerClick('dial', 0)">0</td>
                        <td class="dialer_del_td">
                            <img alt="delete" @click="dialerClick('delete', 'delete')" src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJiYWNrc3BhY2UiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjQwIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWJhY2tzcGFjZSBmYS13LTIwIGZhLTd4Ij48cGF0aCBmaWxsPSIjREMxQTU5IiBkPSJNNDY5LjY1IDE4MS42NWwtMTEuMzEtMTEuMzFjLTYuMjUtNi4yNS0xNi4zOC02LjI1LTIyLjYzIDBMMzg0IDIyMi4wNmwtNTEuNzItNTEuNzJjLTYuMjUtNi4yNS0xNi4zOC02LjI1LTIyLjYzIDBsLTExLjMxIDExLjMxYy02LjI1IDYuMjUtNi4yNSAxNi4zOCAwIDIyLjYzTDM1MC4wNiAyNTZsLTUxLjcyIDUxLjcyYy02LjI1IDYuMjUtNi4yNSAxNi4zOCAwIDIyLjYzbDExLjMxIDExLjMxYzYuMjUgNi4yNSAxNi4zOCA2LjI1IDIyLjYzIDBMMzg0IDI4OS45NGw1MS43MiA1MS43MmM2LjI1IDYuMjUgMTYuMzggNi4yNSAyMi42MyAwbDExLjMxLTExLjMxYzYuMjUtNi4yNSA2LjI1LTE2LjM4IDAtMjIuNjNMNDE3Ljk0IDI1Nmw1MS43Mi01MS43MmM2LjI0LTYuMjUgNi4yNC0xNi4zOC0uMDEtMjIuNjN6TTU3NiA2NEgyMDUuMjZDMTg4LjI4IDY0IDE3MiA3MC43NCAxNjAgODIuNzRMOS4zNyAyMzMuMzdjLTEyLjUgMTIuNS0xMi41IDMyLjc2IDAgNDUuMjVMMTYwIDQyOS4yNWMxMiAxMiAyOC4yOCAxOC43NSA0NS4yNSAxOC43NUg1NzZjMzUuMzUgMCA2NC0yOC42NSA2NC02NFYxMjhjMC0zNS4zNS0yOC42NS02NC02NC02NHptMTYgMzIwYzAgOC44Mi03LjE4IDE2LTE2IDE2SDIwNS4yNmMtNC4yNyAwLTguMjktMS42Ni0xMS4zMS00LjY5TDU0LjYzIDI1NmwxMzkuMzEtMTM5LjMxYzMuMDItMy4wMiA3LjA0LTQuNjkgMTEuMzEtNC42OUg1NzZjOC44MiAwIDE2IDcuMTggMTYgMTZ2MjU2eiIgY2xhc3M9IiI+PC9wYXRoPjwvc3ZnPg==" width="25px" title="Delete" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3"><a href="#" @click="MAKEMANUALCALL(context)" type="button" id="dialer_call_btn_td">
                          <v-wait v-bind:for="'ManualCall'+ Id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Call
                        </v-wait>
                        </a>
                      </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("dialer");

export default {
  name: "DialerControl",
  props: {
     Id: {
      type: String,
      required: true,
      default: "1"
    },
    AgentNo: {
      type: String,
      required: true
    },
    PrimaryNo: {
      type: String,
      required: true
    },
    SecondaryNo: {
      type: String,
      required: false
    },
    Mini: {
      type: Boolean,
      required: false
    },
  },
  beforeMount() {
    if (this.ClearData) {
      this.$store
        .dispatch("fileupload/clearall", {
          router: this.$router
        })
        .finally(() => {});
    }
  },
  data: function() {
    return {
      ManualNumber: "",
      isDialButton: true,
      isHoldButton: false,
      isManualDialButton: true,
      isHangUpButton: false,
      isResumeCallButton: false,
      DisplayVideo: false,
      VideoTaken: false,
      AudioTaken: false,
      VideoTakenId: 0,
      AudioTakenId: 0,
      context: this,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      context: this,
      CAPTUREPHOT0S: [],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      addedBy: this.$store.getters["auth/USERPROFILE"].system_usersid,
      croppedphoto: ""
    };
  },
  components: {},
  computed: {
    ...mapGetters(["GETALL"])
  },
  methods: {
    ...mapActions(["upload", "delete", "clearall"]),
    OnCall: context => {
      context.isDialButton = false;
      context.isHoldButton = true;
      context.isManualDialButton = false;
      context.isHangUpButton = true;
      context.isResumeCallButton = false;
    },
    OnCallEnded: context => {
      context.isDialButton = true;
      context.isHoldButton = false;
      context.isManualDialButton = true;
      context.isHangUpButton = false;
      context.isResumeCallButton = false;
    },
    OnPauseEnded: context => {
      context.isDialButton = false;
      context.isHoldButton = false;
      context.isManualDialButton = false;
      context.isHangUpButton = true;
      context.isResumeCallButton = true;
    },
    OnResumeEnded: context => {
      context.isDialButton = false;
      context.isHoldButton = true;
      context.isManualDialButton = false;
      context.isHangUpButton = true;
      context.isResumeCallButton = false;
    },
    dialerClick: (type, value) => {
        let input = $('#dialer_input_td input');
        let input_val = $('#dialer_input_td input').val();
        if (type == 'dial') {
            input.val(input_val + value);
        } else if (type == 'delete') {
            input.val(input_val.substring(0, input_val.length - 1));
        } else if (type == 'clear') {
            input.val("");
        }
    },
    MAKEMANUALCALL: context => {
      
      context.$store
            .dispatch("dialer/PAUSECALL", {
              agentno: context.AgentNo,
            })
              .then(() => {
              })
              .catch((error) => {
              })
              .finally(() => {
             
            context.$Notify
              .YesNo(false, {
                context
              })
              .then((value) => {
                if (value) {
                  value.context.$wait.start("ManualCall" + value.context.Id);
                  value.context.$store
                  .dispatch("dialer/CALL", {
                    agentno: value.context.AgentNo,
                    mobileno: value.context.ManualNumber,
                  })
                    .then(() => {
                      //value.context.$Notify.Success("Successfull", "");
                    })
                    .catch((error) => {
                      //value.context.$wait.end("Call");
                      //value.context.$Notify.Error(error.data[0].message, "");]
                      //value.context.$Notify.Success("Successfull", "");
                    })
                    .finally(() => {
                      value.context.$wait.end("ManualCall" + value.context.Id);
                      value.context.OnCall(value.context);
                      $('#dialer_modal').modal('hide');
                    });
                }
              });
          });
    },
    MAKECALL: context => {
      context.$store
            .dispatch("dialer/PAUSECALL", {
              agentno: context.AgentNo,
            })
              .then(() => {
              })
              .catch((error) => {
              })
              .finally(() => {

                context.$Notify
        .YesNo(false, {
          context
        })
        .then((value) => {
          if (value) {
            value.context.$wait.start("Call" + value.context.Id);
            value.context.$store
            .dispatch("dialer/CALL", {
              agentno: value.context.AgentNo,
              mobileno: value.context.PrimaryNo,
            })
              .then(() => {

              })
              .catch((error) => {
                //value.context.$wait.end("Call");
                //value.context.$Notify.Error(error.data[0].message, "");
                //value.context.$Notify.Success("Error", "");
                
              })
              .finally(() => {
                value.context.$wait.end("Call" + value.context.Id);
                value.context.OnCall(value.context);
              });
          }
        });

              });

      
    },
    PAUSECALL: context => {
      
            context.$wait.start("Pause" + context.Id);
            context.$store
            .dispatch("dialer/PARKCALL", {
              agentno: context.AgentNo,
            })
              .then(() => {
              })
              .catch((error) => {
                //value.context.$wait.end("Call");
                //value.context.$Notify.Error(error.data[0].message, "");
                
              })
              .finally(() => {
                context.$wait.end("Pause" + context.Id);
                context.OnPauseEnded(context);
              });
    },
    RESUMECALL: context => {
            context.$wait.start("Resume" + context.Id);
            context.$store
            .dispatch("dialer/GRABCALL", {
              agentno: context.AgentNo,
            })
              .then(() => {
              })
              .catch((error) => {
                
              })
              .finally(() => {
                context.$wait.end("Resume" + context.Id);
                context.OnResumeEnded(context);
              });
    },
    HANGUPCALL: context => {
            context.$wait.start("HangUp" + context.Id);
            context.$store
            .dispatch("dialer/ENDCALL", {
              agentno: context.AgentNo,
            })
              .then(() => {
              })
              .catch((error) => {
                //value.context.$wait.end("Call");
                //value.context.$Notify.Error(error.data[0].message, "");
                //value.context.OnCallEnded(value.context);
              })
              .finally(() => {
                
                context.$store
              .dispatch("dialer/DISPOCALL", {
                agentno: context.AgentNo,
              })
              .then(() => {
              })
              .catch((error) => {
              })
              .finally(() => {
                context.OnCallEnded(context);
                context.$wait.end("HangUp" + context.Id);
              });
               
              });

           
    },
  },
  mounted() {
    $(".panel-body").data("context", this);
  }
};
</script>

<style scoped>
       .dialer-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-color: transparent;
        }
        .dialer-buttons {
            display: flex;
            gap: 10px;
        }
        .dialer-button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: white;
            cursor: pointer;
            transition: 0.3s;
        }
        .dialer-button:hover {
            opacity: 0.8;
        }
        .disabled {
            background-color: lightgray !important;
            cursor: not-allowed;
        }
        .dial {
            background-color: green;
        }
        .resume {
            background-color: green;
        }
        .pause {
            background-color: orange;
        }
        .conference {
            background-color: blue;
        }
        .dial-plan {
            background-color: purple;
        }
        .hang-up {
            background-color: red;
        }
        #dialer_table {
            width: 100%;
            font-size: 1.5em;
        }

        #dialer_table tr td {
            text-align: center;
            height: 50px;
            width: 33%;
        }

        #dialer_table #dialer_input_td {
            border-bottom: 1px solid #fafafa;
        }

        #dialer_table #dialer_input_td input {
            width: 100%;
            border: none;
            font-size: 1.6em;
        }

        /* Remove arrows from type number input : Chrome, Safari, Edge, Opera */
        #dialer_table #dialer_input_td input::-webkit-outer-spin-button,
        #dialer_table #dialer_input_td input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Remove arrows from type number input : Firefox */
        #dialer_table #dialer_input_td input[type=number] {
            -moz-appearance: textfield;
        }

        #dialer_table #dialer_input_td input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #cccccc;
            opacity: 1; /* Firefox */
        }

        #dialer_table #dialer_input_td input:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #cccccc;
        }

        #dialer_table #dialer_input_td input::-ms-input-placeholder { /* Microsoft Edge */
            color: #cccccc;
        }

        #dialer_table #dialer_call_btn_td {
            color: #ffffff;
            background-color: green;
            border: none;
            cursor: pointer;
            width: 100%;
            text-decoration: none;
            padding: 5px 32px;
            text-align: center;
            display: inline-block;
            margin: 10px 2px 4px 2px;
            transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            --webkit-transition: all 300ms ease;
        }

        #dialer_table #dialer_call_btn_td:hover {
            background-color: #009d00;
        }

        #dialer_table .dialer_num_tr td {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        }

        #dialer_table .dialer_num_tr td:nth-child(1) {
            border-right: 1px solid #fafafa;
        }

        #dialer_table .dialer_num_tr td:nth-child(3) {
            border-left: 1px solid #fafafa;
        }

        #dialer_table .dialer_num_tr:nth-child(1) td,
        #dialer_table .dialer_num_tr:nth-child(2) td,
        #dialer_table .dialer_num_tr:nth-child(3) td,
        #dialer_table .dialer_num_tr:nth-child(4) td {
            border-bottom: 1px solid #fafafa;
        }

        #dialer_table .dialer_num_tr .dialer_num {
            color: #0B559F;
            cursor: pointer;
        }

        #dialer_table .dialer_num_tr .dialer_num:hover {
            background-color: #fafafa;
        }

        #dialer_table .dialer_num_tr:nth-child(0) td {
            border-top: 1px solid #fafafa;
        }

        #dialer_table .dialer_del_td img {
            cursor: pointer;
        }
</style>

